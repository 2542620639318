import React from 'react';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardHeader,
  makeStyles,
  Paper,
  Grid,
  Typography
} from '@material-ui/core';
import 'remixicon/fonts/remixicon.css';
import buildings from './Data/building';
import business from './Data/business';
import communication from './Data/communication';
import design from './Data/design';
import development from './Data/development';
import device from './Data/device';
import document from './Data/document';
import editor from './Data/editor';
import finance from './Data/finance';
import logos from './Data/logos';
import map from './Data/map';
import media from './Data/media';
import system from './Data/system';
import userFaces from './Data/userFaces';
import weather from './Data/weather';
import other from './Data/other';

const useStyles = makeStyles(theme => ({
  root: {},
  gridRoot: { flexGrow: 1 },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    fontSize: 24
  }
}));

const IconComponent = () => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root)}>
      <Card>
        <CardHeader
          title="Buildings"
          subheader="<i class='ri-home-fill'> </i> or <i class='ri-home-fill'> </i>"
        />
      </Card>
      <Box mt={3} mb={3}>
        <Grid container spacing={3}>
          {buildings.map((val, index) => {
            return (
              <Grid item xs={3} key={index}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Paper className={classes.paper}>
                      <i className={val}></i>
                    </Paper>
                  </Grid>
                  <Grid item>
                    <Box pl={1}>
                      <Typography>{val}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Box>

      <Card>
        <CardHeader
          title="Business"
          subheader="<i class='ri-home-fill'> </i> or <i class='ri-home-fill'> </i>"
        />
      </Card>
      <Box mt={3} mb={3}>
        <Grid container spacing={3}>
          {business.map((val, index) => {
            return (
              <Grid item xs={3} key={index}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Paper className={classes.paper}>
                      <i className={val}></i>
                    </Paper>
                  </Grid>
                  <Grid item>
                    <Box pl={1}>
                      <Typography>{val}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Box>

      <Card>
        <CardHeader
          title="Communication"
          subheader="<i class='ri-home-fill'> </i> or <i class='ri-home-fill'> </i>"
        />
      </Card>
      <Box mt={3} mb={3}>
        <Grid container spacing={3}>
          {communication.map((val, index) => {
            return (
              <Grid item xs={3} key={index}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Paper className={classes.paper}>
                      <i className={val}></i>
                    </Paper>
                  </Grid>
                  <Grid item>
                    <Box pl={1}>
                      <Typography>{val}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Box>

      <Card>
        <CardHeader
          title="Design"
          subheader="<i class='ri-home-fill'> </i> or <i class='ri-home-fill'> </i>"
        />
      </Card>
      <Box mt={3} mb={3}>
        <Grid container spacing={3}>
          {design.map((val, index) => {
            return (
              <Grid item xs={3} key={index}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Paper className={classes.paper}>
                      <i className={val}></i>
                    </Paper>
                  </Grid>
                  <Grid item>
                    <Box pl={1}>
                      <Typography>{val}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Box>

      <Card>
        <CardHeader
          title="Development"
          subheader="<i class='ri-home-fill'> </i> or <i class='ri-home-fill'> </i>"
        />
      </Card>
      <Box mt={3} mb={3}>
        <Grid container spacing={3}>
          {development.map((val, index) => {
            return (
              <Grid item xs={3} key={index}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Paper className={classes.paper}>
                      <i className={val}></i>
                    </Paper>
                  </Grid>
                  <Grid item>
                    <Box pl={1}>
                      <Typography>{val}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Box>

      <Card>
        <CardHeader
          title="Device"
          subheader="<i class='ri-home-fill'> </i> or <i class='ri-home-fill'> </i>"
        />
      </Card>
      <Box mt={3} mb={3}>
        <Grid container spacing={3}>
          {device.map((val, index) => {
            return (
              <Grid item xs={3} key={index}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Paper className={classes.paper}>
                      <i className={val}></i>
                    </Paper>
                  </Grid>
                  <Grid item>
                    <Box pl={1}>
                      <Typography>{val}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Box>

      <Card>
        <CardHeader
          title="Document"
          subheader="<i class='ri-home-fill'> </i> or <i class='ri-home-fill'> </i>"
        />
      </Card>
      <Box mt={3} mb={3}>
        <Grid container spacing={3}>
          {document.map((val, index) => {
            return (
              <Grid item xs={3} key={index}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Paper className={classes.paper}>
                      <i className={val}></i>
                    </Paper>
                  </Grid>
                  <Grid item>
                    <Box pl={1}>
                      <Typography>{val}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Box>

      <Card>
        <CardHeader
          title="Editor"
          subheader="<i class='ri-home-fill'> </i> or <i class='ri-home-fill'> </i>"
        />
      </Card>
      <Box mt={3} mb={3}>
        <Grid container spacing={3}>
          {editor.map((val, index) => {
            return (
              <Grid item xs={3} key={index}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Paper className={classes.paper}>
                      <i className={val}></i>
                    </Paper>
                  </Grid>
                  <Grid item>
                    <Box pl={1}>
                      <Typography>{val}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Box>

      <Card>
        <CardHeader
          title="Finance"
          subheader="<i class='ri-home-fill'> </i> or <i class='ri-home-fill'> </i>"
        />
      </Card>
      <Box mt={3} mb={3}>
        <Grid container spacing={3}>
          {finance.map((val, index) => {
            return (
              <Grid item xs={3} key={index}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Paper className={classes.paper}>
                      <i className={val}></i>
                    </Paper>
                  </Grid>
                  <Grid item>
                    <Box pl={1}>
                      <Typography>{val}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Box>

      <Card>
        <CardHeader
          title="Logos"
          subheader="<i class='ri-home-fill'> </i> or <i class='ri-home-fill'> </i>"
        />
      </Card>
      <Box mt={3} mb={3}>
        <Grid container spacing={3}>
          {logos.map((val, index) => {
            return (
              <Grid item xs={3} key={index}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Paper className={classes.paper}>
                      <i className={val}></i>
                    </Paper>
                  </Grid>
                  <Grid item>
                    <Box pl={1}>
                      <Typography>{val}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Box>

      <Card>
        <CardHeader
          title="Map"
          subheader="<i class='ri-home-fill'> </i> or <i class='ri-home-fill'> </i>"
        />
      </Card>
      <Box mt={3} mb={3}>
        <Grid container spacing={3}>
          {map.map((val, index) => {
            return (
              <Grid item xs={3} key={index}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Paper className={classes.paper}>
                      <i className={val}></i>
                    </Paper>
                  </Grid>
                  <Grid item>
                    <Box pl={1}>
                      <Typography>{val}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Box>

      <Card>
        <CardHeader
          title="Media"
          subheader="<i class='ri-home-fill'> </i> or <i class='ri-home-fill'> </i>"
        />
      </Card>
      <Box mt={3} mb={3}>
        <Grid container spacing={3}>
          {media.map((val, index) => {
            return (
              <Grid item xs={3} key={index}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Paper className={classes.paper}>
                      <i className={val}></i>
                    </Paper>
                  </Grid>
                  <Grid item>
                    <Box pl={1}>
                      <Typography>{val}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Box>

      <Card>
        <CardHeader
          title="System"
          subheader="<i class='ri-home-fill'> </i> or <i class='ri-home-fill'> </i>"
        />
      </Card>
      <Box mt={3} mb={3}>
        <Grid container spacing={3}>
          {system.map((val, index) => {
            return (
              <Grid item xs={3} key={index}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Paper className={classes.paper}>
                      <i className={val}></i>
                    </Paper>
                  </Grid>
                  <Grid item>
                    <Box pl={1}>
                      <Typography>{val}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Box>

      <Card>
        <CardHeader
          title="User & Faces"
          subheader="<i class='ri-home-fill'> </i> or <i class='ri-home-fill'> </i>"
        />
      </Card>
      <Box mt={3} mb={3}>
        <Grid container spacing={3}>
          {userFaces.map((val, index) => {
            return (
              <Grid item xs={3} key={index}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Paper className={classes.paper}>
                      <i className={val}></i>
                    </Paper>
                  </Grid>
                  <Grid item>
                    <Box pl={1}>
                      <Typography>{val}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Box>

      <Card>
        <CardHeader
          title="Weather"
          subheader="<i class='ri-home-fill'> </i> or <i class='ri-home-fill'> </i>"
        />
      </Card>
      <Box mt={3} mb={3}>
        <Grid container spacing={3}>
          {weather.map((val, index) => {
            return (
              <Grid item xs={3} key={index}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Paper className={classes.paper}>
                      <i className={val}></i>
                    </Paper>
                  </Grid>
                  <Grid item>
                    <Box pl={1}>
                      <Typography>{val}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Box>

      <Card>
        <CardHeader
          title="Others"
          subheader="<i class='ri-home-fill'> </i> or <i class='ri-home-fill'> </i>"
        />
      </Card>
      <Box mt={3} mb={3}>
        <Grid container spacing={3}>
          {other.map((val, index) => {
            return (
              <Grid item xs={3} key={index}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Paper className={classes.paper}>
                      <i className={val}></i>
                    </Paper>
                  </Grid>
                  <Grid item>
                    <Box pl={1}>
                      <Typography>{val}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </div>
  );
};

export default IconComponent;
