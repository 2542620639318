export default [
  'ri-user-line',
  'ri-user-fill',
  'ri-user-2-line',
  'ri-user-2-fill',
  'ri-user-3-line',
  'ri-user-3-fill',
  'ri-user-4-line',
  'ri-user-4-fill',
  'ri-user-5-line',
  'ri-user-5-fill',
  'ri-user-6-line',
  'ri-user-6-fill',
  'ri-user-smile-line',
  'ri-user-smile-fill',
  'ri-account-box-line',
  'ri-account-box-fill',
  'ri-account-circle-line',
  'ri-account-circle-fill',
  'ri-account-pin-box-line',
  'ri-account-pin-box-fill',
  'ri-account-pin-circle-line',
  'ri-account-pin-circle-fill',
  'ri-user-add-line',
  'ri-user-add-fill',
  'ri-user-follow-line',
  'ri-user-follow-fill',
  'ri-user-unfollow-line',
  'ri-user-unfollow-fill',
  'ri-user-shared-line',
  'ri-user-shared-fill',
  'ri-user-shared-2-line',
  'ri-user-shared-2-fill',
  'ri-user-received-line',
  'ri-user-received-fill',
  'ri-user-received-2-line',
  'ri-user-received-2-fill',
  'ri-user-location-line',
  'ri-user-location-fill',
  'ri-user-search-line',
  'ri-user-search-fill',
  'ri-user-settings-line',
  'ri-user-settings-fill',
  'ri-user-star-line',
  'ri-user-star-fill',
  'ri-user-heart-line',
  'ri-user-heart-fill',
  'ri-admin-line',
  'ri-admin-fill',
  'ri-contacts-line',
  'ri-contacts-fill',
  'ri-group-line',
  'ri-group-fill',
  'ri-group-2-line',
  'ri-group-2-fill',
  'ri-team-line',
  'ri-team-fill',
  'ri-user-voice-line',
  'ri-user-voice-fill',
  'ri-emotion-line',
  'ri-emotion-fill',
  'ri-emotion-2-line',
  'ri-emotion-2-fill',
  'ri-emotion-happy-line',
  'ri-emotion-happy-fill',
  'ri-emotion-normal-line',
  'ri-emotion-normal-fill',
  'ri-emotion-unhappy-line',
  'ri-emotion-unhappy-fill',
  'ri-emotion-laugh-line',
  'ri-emotion-laugh-fill',
  'ri-emotion-sad-line',
  'ri-emotion-sad-fill',
  'ri-skull-line',
  'ri-skull-fill',
  'ri-skull-2-line',
  'ri-skull-2-fill',
  'ri-men-line',
  'ri-men-fill',
  'ri-women-line',
  'ri-women-fill',
  'ri-travesti-line',
  'ri-travesti-fill',
  'ri-genderless-line',
  'ri-genderless-fill',
  'ri-open-arm-line',
  'ri-open-arm-fill',
  'ri-body-scan-line',
  'ri-body-scan-fill',
  'ri-parent-line',
  'ri-parent-fill',
  'ri-robot-line',
  'ri-robot-fill',
  'ri-aliens-line',
  'ri-aliens-fill',
  'ri-bear-smile-line',
  'ri-bear-smile-fill',
  'ri-mickey-line',
  'ri-mickey-fill',
  'ri-criminal-line',
  'ri-criminal-fill',
  'ri-ghost-line',
  'ri-ghost-fill',
  'ri-ghost-2-line',
  'ri-ghost-2-fill',
  'ri-ghost-smile-line',
  'ri-ghost-smile-fill',
  'ri-star-smile-line',
  'ri-star-smile-fill',
  'ri-spy-line',
  'ri-spy-fill'
];
