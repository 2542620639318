import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Divider,
  Typography,
  makeStyles,
  Grid
} from '@material-ui/core';
import data from './Data/data';

const useStyles = makeStyles(theme => ({
  root: {},
  cardRoot: {
    minWidth: 275
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  cardbackground: {
    background: theme.palette.background.dark
  }
}));

const OutlinedButtons = ({ className, ...rest }) => {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card className={classes.cardbackground}>
        <CardHeader title={<Typography variant="h2">Simple Card</Typography>} />
        <Divider />
        <CardContent className={classes.cardbackground}>
          <Box mt={1} mb={3}>
            <Grid container justify="center" spacing={3}>
              {data.map(val => {
                return (
                  <Grid item xs={12} sm={3} key={val.id}>
                    <Card className={classes.root}>
                      <CardContent>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                          gutterBottom
                        >
                          Word of the Day
                        </Typography>
                        <Typography variant="h5" component="h2">
                          be{bull}nev{bull}o{bull}lent
                        </Typography>
                        <Typography
                          className={classes.pos}
                          color="textSecondary"
                        >
                          adjective
                        </Typography>
                        <Typography variant="body2" component="p">
                          well meaning and kindly.
                          <br />
                          {'"a benevolent smile"'}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button size="small">Learn More</Button>
                      </CardActions>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Box>

          <Box mt={1}>
            <Typography variant="subtitle1">
              <b>Outlined buttons</b> are medium-emphasis buttons. They contain
              actions that are important, but aren’t the primary action in an
              app.
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

OutlinedButtons.propTypes = {
  className: PropTypes.string
};

export default OutlinedButtons;
