import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
  Button
} from '@material-ui/core';
import { GoogleMap, OverlayView, withGoogleMap } from 'react-google-maps';

const useStyles = makeStyles(theme => ({
  root: {}
}));

const STYLES = {
  mapContainer: {
    height: 500
  },
  overlayView: {
    background: '#fff',
    border: '1px solid #ccc',
    padding: 15
  }
};

function getPixelPositionOffset(width, height) {
  return { x: -(width / 2), y: -(height / 2) };
}

const OverlayViewExampleGoogleMap = withGoogleMap(props => (
  <GoogleMap
    defaultZoom={15}
    defaultCenter={{ lat: 47.646935, lng: -122.303763 }}
  >
    <OverlayView
      position={{ lat: 47.646935, lng: -122.303763 }}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      getPixelPositionOffset={getPixelPositionOffset}
    >
      <Box style={STYLES.overlayView}>
        <h1 style={{ textAlign: 'center', padding: 10 }}>OverlayView</h1>
        <Button variant="contained" color="primary" onClick={props.onClick}>
          I have been clicked {props.count} time{props.count === 1 ? `` : `s`}
        </Button>
      </Box>
    </OverlayView>
  </GoogleMap>
));

const MapOverlayComponent = ({ className, ...rest }) => {
  const classes = useStyles();
  const [count, setCount] = useState(0);

  const handleClick = () => {
    setCount(count + 1);
  };
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader title="Map Overlay" />
        <Divider />
        <CardContent>
          <OverlayViewExampleGoogleMap
            loadingElement={<Box height={1} />}
            containerElement={<Box height={{ xs: 300, sm: 400 }} />}
            mapElement={<Box height={1} />}
            count={count}
            onClick={handleClick}
          />
        </CardContent>
      </Card>
    </div>
  );
};

MapOverlayComponent.propTypes = {
  className: PropTypes.string
};

export default MapOverlayComponent;
