import React from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import MaterialUI from './material-ui';
import NativeDatePicker from './nativeDate';
import NativeDateAndTime from './nativeDateNTime';
import NativeTimePicker from './nativeTime';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ButtonsIndex = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Date & Time">
      <Container maxWidth="lg">
        <Box>
          <MaterialUI />
        </Box>
        <Box mt={3}>
          <NativeDatePicker />
        </Box>
        <Box mt={3}>
          <NativeDateAndTime />
        </Box>
        <Box mt={3}>
          <NativeTimePicker />
        </Box>
      </Container>
    </Page>
  );
};

export default ButtonsIndex;
