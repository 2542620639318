import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles
} from '@material-ui/core';
import { GoogleMap, withGoogleMap } from 'react-google-maps';

const useStyles = makeStyles(theme => ({
  root: {}
}));

const SimpleMapExampleGoogleMap = withGoogleMap(() => (
  <GoogleMap
    defaultZoom={15}
    defaultCenter={{ lat: 47.646935, lng: -122.303763 }}
  />
));

const BasicMap = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader title="Basic Map" />
        <Divider />
        <CardContent>
          <SimpleMapExampleGoogleMap
            loadingElement={<Box height={1} />}
            containerElement={<Box height={{ xs: 300, sm: 400 }} />}
            mapElement={<Box height={1} />}
          />
        </CardContent>
      </Card>
    </div>
  );
};

BasicMap.propTypes = {
  className: PropTypes.string
};

export default BasicMap;
