import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  makeStyles,
  Slider
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  slider: {
    height: 300
  }
}));

function valuetext(value) {
  return `${value}°C`;
}

const marks = [
  {
    value: 0,
    label: '0°C'
  },
  {
    value: 20,
    label: '20°C'
  },
  {
    value: 37,
    label: '37°C'
  },
  {
    value: 100,
    label: '100°C'
  }
];

const OutlinedButtons = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader
          title={<Typography variant="h2">Vertical sliders</Typography>}
        />
        <Divider />
        <CardContent>
          <Box mt={1} style={{ display: 'flex', justifyContent: 'center' }}>
            <div className={classes.slider}>
              <Slider
                orientation="vertical"
                getAriaValueText={valuetext}
                defaultValue={30}
                aria-labelledby="vertical-slider"
              />
              <Slider
                disabled
                orientation="vertical"
                getAriaValueText={valuetext}
                defaultValue={30}
                aria-labelledby="vertical-slider"
              />
              <Slider
                orientation="vertical"
                defaultValue={[20, 37]}
                aria-labelledby="vertical-slider"
                getAriaValueText={valuetext}
                marks={marks}
              />
            </div>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

OutlinedButtons.propTypes = {
  className: PropTypes.string
};

export default OutlinedButtons;
