import React from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import BasicButtonGroup from './basic';
import SizeAndColors from './sizeNcolor';
import Verical from './vertical';
import SplitButton from './split';
import DisableElevation from './disableElevation';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ButtonGroupIndex = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Button Group">
      <Container maxWidth="lg">
        <Box>
          <BasicButtonGroup />
        </Box>
        <Box mt={3}>
          <SizeAndColors />
        </Box>
        <Box mt={3}>
          <Verical />
        </Box>
        <Box mt={3}>
          <SplitButton />
        </Box>
        <Box mt={3}>
          <DisableElevation />
        </Box>
      </Container>
    </Page>
  );
};

export default ButtonGroupIndex;
