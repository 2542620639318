import React from 'react';
import { Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Calendar from '@ericz1803/react-google-calendar';
import { css } from '@emotion/react';

const API_KEY = 'AIzaSyD906WZ5j57QG6yr3_flIBFA39bAA6OHPA';
let calendars = [
  {
    calendarId: '09opmkrjova8h5k5k46fedmo88@group.calendar.google.com',
    color: '#B241D1'
  },
  { calendarId: 'hkr1dj9k6v6pa79gvpv03eapeg@group.calendar.google.com' },
  {
    calendarId: 'rg4m0k607609r2jmdr97sjvjus@group.calendar.google.com',
    color: 'rgb(63, 191, 63)'
  }
];

let styles = {
  calendar: {
    borderWidth: '3px'
  },

  today: css`
    color: red;
    border: 1px solid red;
  `
};
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CalanderIndexComponent = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Calender">
      <Container>
        <Calendar apiKey={API_KEY} calendars={calendars} styles={styles} />
      </Container>
    </Page>
  );
};

export default CalanderIndexComponent;
