import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  makeStyles,
  Slider
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  slider: {
    width: 250
  },
  margin: {
    height: theme.spacing(3)
  }
}));

const marks = [
  {
    value: 0,
    label: '0°C'
  },
  {
    value: 20,
    label: '20°C'
  },
  {
    value: 37,
    label: '37°C'
  },
  {
    value: 100,
    label: '100°C'
  }
];

function valuetext(value) {
  return `${value}°C`;
}

const OutlinedButtons = ({ className, ...rest }) => {
  const classes = useStyles();
  const falseTrack = `track={false}`;
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader
          title={<Typography variant="h2">Removed track</Typography>}
        />
        <Divider />
        <CardContent>
          <Box mt={1} style={{ display: 'flex', justifyContent: 'center' }}>
            <div className={classes.slider}>
              <Typography id="track-false-slider" gutterBottom>
                Removed track
              </Typography>
              <Slider
                track={false}
                aria-labelledby="track-false-slider"
                getAriaValueText={valuetext}
                defaultValue={30}
                marks={marks}
              />
              <div className={classes.margin} />
              <Typography id="track-false-range-slider" gutterBottom>
                Removed track range slider
              </Typography>
              <Slider
                track={false}
                aria-labelledby="track-false-range-slider"
                getAriaValueText={valuetext}
                defaultValue={[20, 37, 50]}
                marks={marks}
              />
            </div>
          </Box>
          <Box mt={1}>
            <Typography variant="subtitle1">
              The track can be turned off with <b>{falseTrack}</b>.
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

OutlinedButtons.propTypes = {
  className: PropTypes.string
};

export default OutlinedButtons;
