import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles
} from '@material-ui/core';
import {
  GoogleMap,
  OverlayView,
  StreetViewPanorama,
  withGoogleMap
} from 'react-google-maps';

const coordinates = { lat: 49.2853171, lng: -123.1119202 };
const STYLES = {
  overlayView: {
    background: `red`,
    color: `white`,
    padding: 5,
    borderRadius: `50%`
  }
};

function getPixelPositionOffset(width, height) {
  return { x: -(width / 2), y: -(height / 2) };
}

const StreetViewPanoramaExampleGoogleMap = withGoogleMap(() => (
  <GoogleMap defaultZoom={8} defaultCenter={coordinates}>
    <StreetViewPanorama defaultPosition={coordinates} visible>
      <OverlayView
        position={{ lat: 49.28590291211115, lng: -123.11248166065218 }}
        mapPaneName={OverlayView.OVERLAY_LAYER}
        getPixelPositionOffset={getPixelPositionOffset}
      >
        <div style={STYLES.overlayView}>OverlayView</div>
      </OverlayView>
    </StreetViewPanorama>
  </GoogleMap>
));

const useStyles = makeStyles(theme => ({
  root: {}
}));

const StreetViewMapComponent = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader title="Street View" />
        <Divider />
        <CardContent>
          <StreetViewPanoramaExampleGoogleMap
            loadingElement={<Box height={1} />}
            containerElement={<Box height={{ xs: 300, sm: 400 }} />}
            mapElement={<Box height={1} />}
          />
        </CardContent>
      </Card>
    </div>
  );
};

StreetViewMapComponent.propTypes = {
  className: PropTypes.string
};

export default StreetViewMapComponent;
