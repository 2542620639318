import React from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import SimpleAvatar from './avatar';
import LetterAvatar from './letter';
import SizesAvatar from './sizes';
import IconAvatar from './icon';
import VariantAvatar from './variants';
import FallbacksAvatar from './fallback';
import GroupedAvatar from './grouped';
import WithBadgeAvatar from './withBadge';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ImageAvatarIndex = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Images">
      <Container maxWidth="lg">
        <Box>
          <SimpleAvatar />
        </Box>
        <Box mt={3}>
          <LetterAvatar />
        </Box>
        <Box mt={3}>
          <SizesAvatar />
        </Box>
        <Box mt={3}>
          <IconAvatar />
        </Box>
        <Box mt={3}>
          <VariantAvatar />
        </Box>
        <Box mt={3}>
          <FallbacksAvatar />
        </Box>
        <Box mt={3}>
          <GroupedAvatar />
        </Box>
        <Box mt={3}>
          <WithBadgeAvatar />
        </Box>
      </Container>
    </Page>
  );
};

export default ImageAvatarIndex;
