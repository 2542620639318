import React from 'react';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardHeader,
  makeStyles,
  Paper,
  Grid,
  Typography
} from '@material-ui/core';
import '@icon/dripicons/dripicons.css';
import exaples from './Data/examples';

const useStyles = makeStyles(theme => ({
  root: {},
  gridRoot: { flexGrow: 1 },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    fontSize: 24
  }
}));

const IconComponent = () => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root)}>
      <Card>
        <CardHeader
          title="Examples"
          subheader="<i class='dripicons-alarm'></i>"
        />
      </Card>
      <Box mt={3} mb={3}>
        <Grid container spacing={3}>
          {exaples.map((val, index) => {
            return (
              <Grid item xs={3} key={index}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Paper className={classes.paper}>
                      <i className={'dripicons' + ' ' + val}></i>
                    </Paper>
                  </Grid>
                  <Grid item>
                    <Box pl={1}>
                      <Typography>{val}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </div>
  );
};

export default IconComponent;
