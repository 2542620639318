import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles
} from '@material-ui/core';
import { Polar } from 'react-chartjs-2';

const useStyles = makeStyles(theme => ({
  root: {}
}));

const PolarChart = ({ className, ...rest }) => {
  const classes = useStyles();
  const data = {
    datasets: [
      {
        data: [11, 16, 7, 3, 14],
        backgroundColor: [
          '#FF6384',
          '#4BC0C0',
          '#FFCE56',
          '#E7E9ED',
          '#36A2EB'
        ],
        label: 'My dataset' // for legend
      }
    ],
    labels: ['Red', 'Green', 'Yellow', 'Grey', 'Blue']
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader title="Polar Chart" />
        <Divider />
        <CardContent>
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Polar data={data} />
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

PolarChart.propTypes = {
  className: PropTypes.string
};

export default PolarChart;
