import React from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Profile from './Profile';
import ProfileDetails from './ProfileDetails';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Account = () => {
  const classes = useStyles();
  const [success, SetSuccessOpen] = React.useState(false);
  const [error, SetErrorOpen] = React.useState(false);

  const SuccessClick = () => {
    SetSuccessOpen(true);
  };

  const SuccessClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    SetSuccessOpen(false);
  };

  const ErrorClick = () => {
    SetErrorOpen(true);
  };

  const ErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    SetErrorOpen(false);
  };

  return (
    <Page className={classes.root} title="Account">
      <Snackbar open={success} autoHideDuration={2000} onClose={SuccessClose}>
        <Alert onClose={SuccessClose} severity="success">
          Updated Successfully
        </Alert>
      </Snackbar>
      <Snackbar open={error} autoHideDuration={2000} onClose={ErrorClose}>
        <Alert onClose={ErrorClose} severity="error">
          Something Went Wrong
        </Alert>
      </Snackbar>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item lg={4} md={6} xs={12}>
            <Profile SuccessClick={SuccessClick} ErrorClick={ErrorClick} />
          </Grid>
          <Grid item lg={8} md={6} xs={12}>
            <ProfileDetails
              SuccessClick={SuccessClick}
              ErrorClick={ErrorClick}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Account;
