import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  ButtonBase,
  Paper,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  makeStyles,
  Grid
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  cardbackground: {
    background: theme.palette.background.dark
  },
  grid: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 500
  },
  image: {
    width: 128,
    height: 128
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%'
  }
}));

const ComplexGrid = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card className={classes.cardbackground}>
        <CardHeader
          title={<Typography variant="h2">Complex Grid</Typography>}
        />
        <Divider />
        <CardContent className={classes.cardbackground}>
          <Box mt={1}>
            <div className={classes.grid}>
              <Paper className={classes.paper}>
                <Grid container spacing={2}>
                  <Grid item>
                    <ButtonBase className={classes.image}>
                      <img
                        className={classes.img}
                        alt="complex"
                        src="https://material-ui.com/static/images/grid/complex.jpg"
                      />
                    </ButtonBase>
                  </Grid>
                  <Grid item xs={12} sm container>
                    <Grid item xs container direction="column" spacing={2}>
                      <Grid item xs>
                        <Typography gutterBottom variant="subtitle1">
                          Standard license
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          Full resolution 1920x1080 • JPEG
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          ID: 1030114
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="body2"
                          style={{ cursor: 'pointer' }}
                        >
                          Remove
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1">$19.00</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </div>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

ComplexGrid.propTypes = {
  className: PropTypes.string
};

export default ComplexGrid;
