import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  button: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  margin: {
    margin: theme.spacing(1)
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  }
}));

const ButtonSizes = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader title={<Typography variant="h2">Sizes</Typography>} />
        <Divider />
        <CardContent>
          <Box>
            <div style={{ textAlign: 'center' }}>
              <div>
                <Button size="small" className={classes.margin}>
                  Small
                </Button>
                <Button size="medium" className={classes.margin}>
                  Medium
                </Button>
                <Button size="large" className={classes.margin}>
                  Large
                </Button>
              </div>
              <div>
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  className={classes.margin}
                >
                  Small
                </Button>
                <Button
                  variant="outlined"
                  size="medium"
                  color="primary"
                  className={classes.margin}
                >
                  Medium
                </Button>
                <Button
                  variant="outlined"
                  size="large"
                  color="primary"
                  className={classes.margin}
                >
                  Large
                </Button>
              </div>
              <div>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  className={classes.margin}
                >
                  Small
                </Button>
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  className={classes.margin}
                >
                  Medium
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  className={classes.margin}
                >
                  Large
                </Button>
              </div>
              <div>
                <IconButton
                  aria-label="delete"
                  className={classes.margin}
                  size="small"
                >
                  <ArrowDownwardIcon fontSize="inherit" />
                </IconButton>
                <IconButton aria-label="delete" className={classes.margin}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
                <IconButton aria-label="delete" className={classes.margin}>
                  <DeleteIcon />
                </IconButton>
                <IconButton aria-label="delete" className={classes.margin}>
                  <DeleteIcon fontSize="large" />
                </IconButton>
              </div>
            </div>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

ButtonSizes.propTypes = {
  className: PropTypes.string
};

export default ButtonSizes;
