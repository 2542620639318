import React from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import SimpleTextField from './simple';
import FormProps from './formProps';
import Validation from './validation';
import Select from './select';
import Icons from './icons';
import InputAdornment from './inputAndorment';
import Sizes from './sizes';
import Layout from './layout';
import Inputs from './inputs';
import Colors from './color';
import CustomizedInput from './customized';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const TextFieldIndex = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Text Field">
      <Container maxWidth="lg">
        <Box>
          <SimpleTextField />
        </Box>
        <Box mt={3}>
          <FormProps />
        </Box>
        <Box mt={3}>
          <Validation />
        </Box>
        <Box mt={3}>
          <Select />
        </Box>
        <Box mt={3}>
          <Icons />
        </Box>
        <Box mt={3}>
          <InputAdornment />
        </Box>
        <Box mt={3}>
          <Sizes />
        </Box>
        <Box mt={3}>
          <Layout />
        </Box>
        <Box mt={3}>
          <Inputs />
        </Box>
        <Box mt={3}>
          <Colors />
        </Box>
        <Box mt={3}>
          <CustomizedInput />
        </Box>
      </Container>
    </Page>
  );
};

export default TextFieldIndex;
