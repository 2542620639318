import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  makeStyles,
  Slider
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  slider: {
    width: 300
  }
}));

function valuetext(value) {
  return `${value}°C`;
}

const OutlinedButtons = ({ className, ...rest }) => {
  const classes = useStyles();
  const mark = `{true}`;

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader
          title={<Typography variant="h2">Discrete sliders</Typography>}
        />
        <Divider />
        <CardContent>
          <Box mt={1} style={{ display: 'flex', justifyContent: 'center' }}>
            <div className={classes.slider}>
              <Typography id="discrete-slider" gutterBottom>
                Temperature
              </Typography>
              <Slider
                defaultValue={30}
                getAriaValueText={valuetext}
                aria-labelledby="discrete-slider"
                valueLabelDisplay="auto"
                step={10}
                marks
                min={10}
                max={110}
              />
              <Typography id="discrete-slider" gutterBottom>
                Disabled
              </Typography>
              <Slider
                defaultValue={30}
                getAriaValueText={valuetext}
                aria-labelledby="discrete-slider"
                valueLabelDisplay="auto"
                step={10}
                marks
                min={10}
                max={110}
                disabled
              />
            </div>
          </Box>

          <Box mt={1}>
            <Typography variant="subtitle1">
              Discrete sliders can be adjusted to a specific value by
              referencing its value indicator. By order of demos:
            </Typography>
            <Typography variant="subtitle1">
              You can generate a mark for each step with <b>marks={mark}</b>.
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

OutlinedButtons.propTypes = {
  className: PropTypes.string
};

export default OutlinedButtons;
