import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Cards from 'react-credit-cards';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Divider,
  TextField,
  makeStyles,
  Typography
} from '@material-ui/core';

import 'react-credit-cards/lib/styles.scss';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%'
  }
}));

export default function PaymentForm() {
  const classes = useStyles();
  const [focus, setFocus] = React.useState('');

  const handleInputFocus = e => {
    setFocus(e.target.name);
  };

  return (
    <Formik
      initialValues={{
        number: '',
        name: '',
        venue: '',
        emonth: '',
        eyear: '',
        cvc: ''
      }}
      validationSchema={Yup.object().shape({
        number: Yup.string()
          .max(16, 'max 16 characters are allowed')
          .required('Total Table is Required'),
        name: Yup.string().required('Please Enter the Name'),
        emonth: Yup.string()
          .max(2)
          .required('Expiry Month is Required'),
        eyear: Yup.string()
          .max(2)
          .required('Expiry Year is required'),
        cvc: Yup.string()
          .max(3)
          .required('CVC is required')
      })}
      onSubmit={data => {
        console.log('data', data);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardHeader
              title={
                <Typography color="textPrimary" variant="h2">
                  Add Credit Card
                </Typography>
              }
            />
            <Divider />
            <Box pt={2} pb={1}>
              <Cards
                cvc={values.cvc}
                expiry={values.emonth + values.eyear}
                focused={focus}
                name={values.name}
                number={values.number}
              />
            </Box>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.number && errors.number)}
                    fullWidth
                    helperText={touched.number && errors.number}
                    label="Card Number"
                    onBlur={handleBlur}
                    onFocus={handleInputFocus}
                    onChange={e => {
                      const re = /^[0-9\b]+$/;
                      if (e.target.value === '' || re.test(e.target.value)) {
                        handleChange(e);
                      }
                    }}
                    name="number"
                    id="time"
                    type="text"
                    variant="outlined"
                    value={values.number}
                    inputProps={{ maxLength: 16 }}
                    className={classes.textField}
                    autoComplete="new-password"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    label="Card Holder Name"
                    onBlur={handleBlur}
                    onFocus={handleInputFocus}
                    onChange={handleChange}
                    name="name"
                    id="time"
                    type="text"
                    variant="outlined"
                    value={values.name}
                    className={classes.textField}
                    autoComplete="new-password"
                  />
                </Grid>

                <Grid item md={4} xs={12}>
                  <TextField
                    error={Boolean(touched.emonth && errors.emonth)}
                    fullWidth
                    helperText={touched.emonth && errors.emonth}
                    label="Expiry Month"
                    name="emonth"
                    onBlur={handleBlur}
                    onFocus={handleInputFocus}
                    className={classes.textField}
                    onChange={e => {
                      const re = /^[0-9\b]+$/;
                      if (e.target.value === '' || re.test(e.target.value)) {
                        handleChange(e);
                      }
                    }}
                    type="text"
                    value={values.emonth}
                    inputProps={{ maxLength: 2 }}
                    variant="outlined"
                    autoComplete="new-password"
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                    error={Boolean(touched.eyear && errors.eyear)}
                    fullWidth
                    helperText={touched.eyear && errors.eyear}
                    label="Expiry Year"
                    name="eyear"
                    onBlur={handleBlur}
                    onChange={e => {
                      const re = /^[0-9\b]+$/;
                      if (e.target.value === '' || re.test(e.target.value)) {
                        handleChange(e);
                      }
                    }}
                    onFocus={handleInputFocus}
                    type="text"
                    value={values.eyear}
                    className={classes.textField}
                    inputProps={{ maxLength: 2 }}
                    variant="outlined"
                    autoComplete="new-password"
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                    error={Boolean(touched.cvc && errors.cvc)}
                    fullWidth
                    helperText={touched.cvc && errors.cvc}
                    label=" CVC  /  CVV"
                    name="cvc"
                    onBlur={handleBlur}
                    onChange={e => {
                      const re = /^[0-9\b]+$/;
                      if (e.target.value === '' || re.test(e.target.value)) {
                        handleChange(e);
                      }
                    }}
                    onFocus={handleInputFocus}
                    type="text"
                    value={values.cvc}
                    className={classes.textField}
                    variant="outlined"
                    inputProps={{ maxLength: 3 }}
                    autoComplete="new-password"
                  />
                </Grid>
                <Box
                  p={1}
                  style={{ width: '100%' }}
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Button
                    color="primary"
                    type="submit"
                    variant="contained"
                    style={{ color: '#fff' }}
                  >
                    Add
                  </Button>
                </Box>
              </Grid>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
}
