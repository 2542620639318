import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  makeStyles,
  Input,
  Grid,
  Slider
} from '@material-ui/core';
import VolumeUp from '@material-ui/icons/VolumeUp';

const useStyles = makeStyles(theme => ({
  root: {},
  slider: {
    width: 250
  },
  input: {
    width: 42
  }
}));

const OutlinedButtons = ({ className, ...rest }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(30);

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleInputChange = event => {
    setValue(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleBlur = () => {
    if (value < 0) {
      setValue(0);
    } else if (value > 100) {
      setValue(100);
    }
  };
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader
          title={<Typography variant="h2">Slider with input field</Typography>}
        />
        <Divider />
        <CardContent>
          <Box mt={1} style={{ display: 'flex', justifyContent: 'center' }}>
            <div className={classes.slider}>
              <Typography id="input-slider" gutterBottom>
                Volume
              </Typography>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <VolumeUp />
                </Grid>
                <Grid item xs>
                  <Slider
                    value={typeof value === 'number' ? value : 0}
                    onChange={handleSliderChange}
                    aria-labelledby="input-slider"
                  />
                </Grid>
                <Grid item>
                  <Input
                    className={classes.input}
                    value={value}
                    margin="dense"
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    inputProps={{
                      step: 10,
                      min: 0,
                      max: 100,
                      type: 'number',
                      'aria-labelledby': 'input-slider'
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          </Box>

          <Box mt={1}>
            <Typography variant="subtitle1">
              In this example an input allows a discrete value to be set.
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

OutlinedButtons.propTypes = {
  className: PropTypes.string
};

export default OutlinedButtons;
