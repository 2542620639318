import React, { useState } from 'react';
import { convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import {
  Box,
  Container,
  makeStyles,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography
} from '@material-ui/core';
import clsx from 'clsx';
import Page from 'src/components/Page';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  cardRoot: {}
}));

const WYSISWYGComponent = () => {
  const classes = useStyles();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  return (
    <Page className={classes.root} title="WYSISWYG Editor">
      <Container maxWidth="lg">
        <Box>
          <div className={clsx(classes.cardRoot)}>
            <Card>
              <CardHeader
                title={<Typography variant="h2">WYSISWYG EDITORS</Typography>}
              />
              <Divider />
              <CardContent>
                <Box mb={5}>
                  <Editor
                    editorStyle={{
                      width: '100%',
                      minHeight: 100,
                      borderWidth: 1,
                      borderStyle: 'solid',
                      borderColor: 'lightgray'
                    }}
                    editorState={editorState}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    onEditorStateChange={editorState =>
                      setEditorState(editorState)
                    }
                  />
                  <textarea
                    style={{ width: '100%', height: 200 }}
                    disabled
                    value={draftToHtml(
                      convertToRaw(editorState.getCurrentContent())
                    )}
                  />
                </Box>
              </CardContent>
            </Card>
          </div>
        </Box>
      </Container>
    </Page>
  );
};

export default WYSISWYGComponent;
