import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Button,
  Container,
  TextField,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {}
}));

const FormValidationComponent = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader
          title={<Typography variant="h3">Basic Formik Validation</Typography>}
        />
        <Divider />
        <CardContent>
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justify="center"
              style={{ minHeight: '100%' }}
            >
              <Container maxWidth="sm">
                <Formik
                  initialValues={{
                    email: '',
                    firstName: '',
                    lastName: '',
                    password: ''
                  }}
                  validationSchema={Yup.object().shape({
                    email: Yup.string()
                      .email('Must be a valid email')
                      .max(255)
                      .required('Email is required'),
                    firstName: Yup.string()
                      .max(255)
                      .required('First name is required'),
                    lastName: Yup.string()
                      .max(255)
                      .required('Last name is required'),
                    password: Yup.string()
                      .max(255)
                      .required('password is required')
                  })}
                  onSubmit={() => {}}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <TextField
                        error={Boolean(touched.firstName && errors.firstName)}
                        fullWidth
                        helperText={touched.firstName && errors.firstName}
                        label="First name"
                        margin="normal"
                        name="firstName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.firstName}
                        variant="outlined"
                      />
                      <TextField
                        error={Boolean(touched.lastName && errors.lastName)}
                        fullWidth
                        helperText={touched.lastName && errors.lastName}
                        label="Last name"
                        margin="normal"
                        name="lastName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.lastName}
                        variant="outlined"
                      />
                      <TextField
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        label="Email Address"
                        margin="normal"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="email"
                        value={values.email}
                        variant="outlined"
                      />
                      <TextField
                        error={Boolean(touched.password && errors.password)}
                        fullWidth
                        helperText={touched.password && errors.password}
                        label="Password"
                        margin="normal"
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="password"
                        value={values.password}
                        variant="outlined"
                      />
                      <Box my={2}>
                        <Button
                          color="primary"
                          disabled={isSubmitting}
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                        >
                          Submit
                        </Button>
                      </Box>
                    </form>
                  )}
                </Formik>
              </Container>
            </Grid>
          </Box>

          <Box mt={1}>
            <Typography variant="subtitle1">
              Parsley is a availity <b>Formik Yup</b> validation. It helps you
              provide your users with feedback on their form submission before
              sending it to your server.
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

FormValidationComponent.propTypes = {
  className: PropTypes.string
};

export default FormValidationComponent;
