export default [
  'ri-bug-line',
  'ri-bug-fill',
  'ri-bug-2-line',
  'ri-bug-2-fill',
  'ri-code-line',
  'ri-code-fill',
  'ri-code-s-line',
  'ri-code-s-fill',
  'ri-code-s-slash-line',
  'ri-code-s-slash-fill',
  'ri-code-box-line',
  'ri-code-box-fill',
  'ri-terminal-box-line',
  'ri-terminal-box-fill',
  'ri-terminal-line',
  'ri-terminal-fill',
  'ri-terminal-window-line',
  'ri-terminal-window-fill',
  'ri-parentheses-line',
  'ri-parentheses-fill',
  'ri-brackets-line',
  'ri-brackets-fill',
  'ri-braces-line',
  'ri-braces-fill',
  'ri-command-line',
  'ri-command-fill',
  'ri-cursor-line',
  'ri-cursor-fill',
  'ri-git-commit-line',
  'ri-git-commit-fill',
  'ri-git-pull-request-line',
  'ri-git-pull-request-fill',
  'ri-git-merge-line',
  'ri-git-merge-fill',
  'ri-git-branch-line',
  'ri-git-branch-fill',
  'ri-git-repository-line',
  'ri-git-repository-fill',
  'ri-git-repository-commits-line',
  'ri-git-repository-commits-fill',
  'ri-git-repository-private-line',
  'ri-git-repository-private-fill',
  'ri-html5-line',
  'ri-html5-fill',
  'ri-css3-line',
  'ri-css3-fill'
];
