import React from 'react';
import {
  Box,
  Container,
  makeStyles,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography
} from '@material-ui/core';
import clsx from 'clsx';
import Page from 'src/components/Page';
import CKEditor from 'react-ckeditor-component';

let config = {
  toolbarGroups: [
    { name: 'document', groups: ['mode', 'document', 'doctools'] },
    {
      name: 'editing',
      groups: ['find', 'selection', 'spellchecker', 'editing']
    },
    { name: 'forms', groups: ['forms'] },
    { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
    {
      name: 'paragraph',
      groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph']
    },
    '/',
    { name: 'links', groups: ['links'] },
    { name: 'insert', groups: ['insert'] },
    { name: 'styles', groups: ['styles'] },
    { name: 'colors', groups: ['colors'] },
    { name: 'tools', groups: ['tools'] },
    '/',
    { name: 'clipboard', groups: ['clipboard', 'undo'] },
    { name: 'others', groups: ['others'] },
    { name: 'about', groups: ['about'] }
  ],
  removeButtons:
    'Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Find,SelectAll,Scayt,Replace,Form,Checkbox,Textarea,Select,Button,ImageButton,HiddenField,CreateDiv,BidiLtr,BidiRtl,Language,Flash,Smiley,SpecialChar,PageBreak,Iframe,Anchor,ShowBlocks,About,CopyFormatting,Undo,Redo',
  fontSize_sizes: '16/16px;24/24px;48/48px;',
  font_names:
    'Arial/Arial, Helvetica, sans-serif;' +
    'Times New Roman/Times New Roman, Times, serif;' +
    'Verdana',
  allowedContent: true
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  cardRoot: {}
}));

const CKEDitorComponent = props => {
  const classes = useStyles();
  const [html, setHtml] = React.useState({});

  const onChange = e => {
    var thtml = e.editor.getData();
    console.log('this', this);
    setHtml(thtml);
  };

  const onBlur = e => {
    console.log('onBlur', e);
  };

  const AfterPaste = e => {
    console.log('afterPaste', e);
  };

  return (
    <Page className={classes.root} title="CKEditor">
      <Container maxWidth="lg">
        <Box>
          <div className={clsx(classes.cardRoot)}>
            <Card>
              <CardHeader
                title={<Typography variant="h2">CKEditor</Typography>}
              />
              <Divider />
              <CardContent>
                <Box mb={5}>
                  <CKEditor
                    activeClass="p10"
                    config={config}
                    content={html}
                    // onChange={this.onChange}
                    events={{
                      blur: onBlur,
                      afterPaste: AfterPaste,
                      change: onChange
                    }}
                  />
                </Box>
              </CardContent>
            </Card>
          </div>
        </Box>
      </Container>
    </Page>
  );
};

export default CKEDitorComponent;
