import React from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import CircularProgress from './circular';
import Determinate from './determinate';
import InteractiveIntegration from './interactive';
import CircularWithLabel from './circularWithLabel';
import LinearProgress from './linear';
import LinearDeterminate from './lineardeterminate';
import LinearBuffer from './linearBuffer';
import LinearWithLabel from './linearWithLabel';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ProgressBarIndex = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Progress Bars">
      <Container maxWidth="lg">
        <Box>
          <CircularProgress />
        </Box>
        <Box mt={3}>
          <Determinate />
        </Box>
        <Box mt={3}>
          <InteractiveIntegration />
        </Box>
        <Box mt={3}>
          <CircularWithLabel />
        </Box>
        <Box mt={3}>
          <LinearProgress />
        </Box>
        <Box mt={3}>
          <LinearDeterminate />
        </Box>
        <Box mt={3}>
          <LinearBuffer />
        </Box>
        <Box mt={3}>
          <LinearWithLabel />
        </Box>
      </Container>
    </Page>
  );
};

export default ProgressBarIndex;
