import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  TextField,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  sizes: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 200
    }
  }
}));

const TextFieldSizes = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader title={<Typography variant="h2">Sizes</Typography>} />
        <Divider />
        <CardContent>
          <Box mt={1} style={{ display: 'flex', justifyContent: 'center' }}>
            <form className={classes.sizes} noValidate autoComplete="off">
              <div>
                <TextField
                  label="Size"
                  id="standard-size-small"
                  defaultValue="Small"
                  size="small"
                />
                <TextField
                  label="Size"
                  id="standard-size-normal"
                  defaultValue="Normal"
                />
              </div>
              <div>
                <TextField
                  label="Size"
                  id="filled-size-small"
                  defaultValue="Small"
                  variant="filled"
                  size="small"
                />
                <TextField
                  label="Size"
                  id="filled-size-normal"
                  defaultValue="Normal"
                  variant="filled"
                />
              </div>
              <div>
                <TextField
                  label="Size"
                  id="outlined-size-small"
                  defaultValue="Small"
                  variant="outlined"
                  size="small"
                />
                <TextField
                  label="Size"
                  id="outlined-size-normal"
                  defaultValue="Normal"
                  variant="outlined"
                />
              </div>
            </form>
          </Box>

          <Box mt={1}>
            <Typography variant="subtitle1">
              Fancy smaller inputs? Use the <b>size</b> prop.
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

TextFieldSizes.propTypes = {
  className: PropTypes.string
};

export default TextFieldSizes;
