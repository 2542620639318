import React from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import LineChart from './line';
import BarChart from './bar';
import PieChart from './PieChart';
import DoughnutChart from './donutChart';
import PolarChart from './polarChart';
import RadarChart from './radarChart';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ChartJSIndex = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="ChartJs Charts">
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item lg={6} sm={6} xl={6} xs={12}>
            <LineChart />
          </Grid>
          <Grid item lg={6} sm={6} xl={6} xs={12}>
            <BarChart />
          </Grid>
          <Grid item lg={6} sm={6} xl={6} xs={12}>
            <PieChart />
          </Grid>
          <Grid item lg={6} sm={6} xl={6} xs={12}>
            <DoughnutChart />
          </Grid>
          <Grid item lg={6} sm={6} xl={6} xs={12}>
            <PolarChart />
          </Grid>
          <Grid item lg={6} sm={6} xl={6} xs={12}>
            <RadarChart />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default ChartJSIndex;
