import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Button,
  Container,
  TextField,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {}
}));

const FormValidationComponent = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader
          title={<Typography variant="h3">Date Validation</Typography>}
        />
        <Divider />
        <CardContent>
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justify="center"
              style={{ minHeight: '100%' }}
            >
              <Container maxWidth="sm">
                <Formik
                  initialValues={{
                    start_time: '',
                    end_time: ''
                  }}
                  validationSchema={Yup.object().shape({
                    start_time: Yup.string()
                      .required('Start Time is Required')
                      .test(
                        'start time',
                        'start time not greater than current time',
                        function(starttime) {
                          return new Date(starttime) > new Date();
                        }
                      ),
                    end_time: Yup.string()
                      .required('End Time is required')
                      .test('end time', 'End time is not valid', function(
                        endtime
                      ) {
                        return (
                          new Date(endtime) > new Date(this.parent.start_time)
                        );
                      })
                  })}
                  onSubmit={() => {}}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(
                              touched.start_time && errors.start_time
                            )}
                            fullWidth
                            helperText={touched.start_time && errors.start_time}
                            label="Start Time"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="start_time"
                            id="datetime-local"
                            type="datetime-local"
                            variant="outlined"
                            value={values.start_time}
                            className={classes.textField}
                            InputLabelProps={{
                              shrink: true
                            }}
                            inputProps={{
                              step: 300 // 5 min
                            }}
                          />
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.end_time && errors.end_time)}
                            fullWidth
                            helperText={touched.end_time && errors.end_time}
                            label="End Time"
                            name="end_time"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="datetime-local"
                            value={values.end_time}
                            variant="outlined"
                            className={classes.textField}
                            InputLabelProps={{
                              shrink: true
                            }}
                            inputProps={{
                              step: 300 // 5 min
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Box my={2}>
                        <Button
                          color="primary"
                          disabled={isSubmitting}
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                        >
                          Submit
                        </Button>
                      </Box>
                    </form>
                  )}
                </Formik>
              </Container>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

FormValidationComponent.propTypes = {
  className: PropTypes.string
};

export default FormValidationComponent;
