import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Container,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  Slide,
  Snackbar,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  code: {
    width: '100%',
    paddingTop: '15px',
    background: '#272C34',
    color: '#fff',
    marginTop: '10px',
    borderRadius: '4px',
    overflowX: 'scroll'
  }
}));

function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

function TransitionRight(props) {
  return <Slide {...props} direction="right" />;
}

function TransitionDown(props) {
  return <Slide {...props} direction="down" />;
}

const OutlinedButtons = ({ className, ...rest }) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [transition, setTransition] = React.useState(undefined);

  const handleClick = Transition => () => {
    setTransition(() => Transition);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  var code = `<Button onClick={handleClick(TransitionLeft)}>Right</Button>
<Button onClick={handleClick(TransitionUp)}>Up</Button>
<Button onClick={handleClick(TransitionRight)}>Left</Button>
<Button onClick={handleClick(TransitionDown)}>Down</Button>
<Snackbar
  open={open}
  onClose={handleClose}
  TransitionComponent={transition}
  message="I love snacks"
  key={transition ? transition.name : ''}
/>`;
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader
          title={<Typography variant="h2">Control Slide direction</Typography>}
        />
        <Divider />
        <CardContent>
          <Box mt={1} style={{ display: 'flex', justifyContent: 'center' }}>
            <div>
              <Button onClick={handleClick(TransitionLeft)}>Right</Button>
              <Button onClick={handleClick(TransitionUp)}>Up</Button>
              <Button onClick={handleClick(TransitionRight)}>Left</Button>
              <Button onClick={handleClick(TransitionDown)}>Down</Button>
              <Snackbar
                open={open}
                onClose={handleClose}
                TransitionComponent={transition}
                message="I love snacks"
                key={transition ? transition.name : ''}
              />
            </div>
          </Box>
          <Box className={classes.code}>
            <Container>
              <pre>
                <Typography
                  variant="subtitle2"
                  style={{ fontFamily: 'monospace', fontSize: 14 }}
                >
                  {code}
                </Typography>
              </pre>
            </Container>
          </Box>
          <Box mt={1}>
            <Typography variant="subtitle1">
              You can change the direction of the <b>Slide</b> transition.
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

OutlinedButtons.propTypes = {
  className: PropTypes.string
};

export default OutlinedButtons;
