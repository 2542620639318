export default [
  'ri-file-line',
  'ri-file-fill',
  'ri-file-2-line',
  'ri-file-2-fill',
  'ri-file-3-line',
  'ri-file-3-fill',
  'ri-file-4-line',
  'ri-file-4-fill',
  'ri-sticky-note-line',
  'ri-sticky-note-fill',
  'ri-sticky-note-2-line',
  'ri-sticky-note-2-fill',
  'ri-file-edit-line',
  'ri-file-edit-fill',
  'ri-file-paper-line',
  'ri-file-paper-fill',
  'ri-file-paper-2-line',
  'ri-file-paper-2-fill',
  'ri-file-text-line',
  'ri-file-text-fill',
  'ri-file-list-line',
  'ri-file-list-fill',
  'ri-file-list-2-line',
  'ri-file-list-2-fill',
  'ri-file-list-3-line',
  'ri-file-list-3-fill',
  'ri-bill-line',
  'ri-bill-fill',
  'ri-file-copy-line',
  'ri-file-copy-fill',
  'ri-file-copy-2-line',
  'ri-file-copy-2-fill',
  'ri-clipboard-line',
  'ri-clipboard-fill',
  'ri-survey-line',
  'ri-survey-fill',
  'ri-article-line',
  'ri-article-fill',
  'ri-newspaper-line',
  'ri-newspaper-fill',
  'ri-file-zip-line',
  'ri-file-zip-fill',
  'ri-file-mark-line',
  'ri-file-mark-fill',
  'ri-task-line',
  'ri-task-fill',
  'ri-todo-line',
  'ri-todo-fill',
  'ri-book-line',
  'ri-book-fill',
  'ri-book-mark-line',
  'ri-book-mark-fill',
  'ri-book-2-line',
  'ri-book-2-fill',
  'ri-book-3-line',
  'ri-book-3-fill',
  'ri-book-open-line',
  'ri-book-open-fill',
  'ri-book-read-line',
  'ri-book-read-fill',
  'ri-contacts-book-line',
  'ri-contacts-book-fill',
  'ri-contacts-book-2-line',
  'ri-contacts-book-2-fill',
  'ri-contacts-book-upload-line',
  'ri-contacts-book-upload-fill',
  'ri-booklet-line',
  'ri-booklet-fill',
  'ri-file-code-line',
  'ri-file-code-fill',
  'ri-file-pdf-line',
  'ri-file-pdf-fill',
  'ri-file-word-line',
  'ri-file-word-fill',
  'ri-file-ppt-line',
  'ri-file-ppt-fill',
  'ri-file-excel-line',
  'ri-file-excel-fill',
  'ri-file-word-2-line',
  'ri-file-word-2-fill',
  'ri-file-ppt-2-line',
  'ri-file-ppt-2-fill',
  'ri-file-excel-2-line',
  'ri-file-excel-2-fill',
  'ri-file-hwp-line',
  'ri-file-hwp-fill',
  'ri-keynote-line',
  'ri-keynote-fill',
  'ri-numbers-line',
  'ri-numbers-fill',
  'ri-pages-line',
  'ri-pages-fill',
  'ri-file-search-line',
  'ri-file-search-fill',
  'ri-file-add-line',
  'ri-file-add-fill',
  'ri-file-reduce-line',
  'ri-file-reduce-fill',
  'ri-file-settings-line',
  'ri-file-settings-fill',
  'ri-file-upload-line',
  'ri-file-upload-fill',
  'ri-file-transfer-line',
  'ri-file-transfer-fill',
  'ri-file-download-line',
  'ri-file-download-fill',
  'ri-file-lock-line',
  'ri-file-lock-fill',
  'ri-file-chart-line',
  'ri-file-chart-fill',
  'ri-file-chart-2-line',
  'ri-file-chart-2-fill',
  'ri-file-music-line',
  'ri-file-music-fill',
  'ri-file-gif-line',
  'ri-file-gif-fill',
  'ri-file-forbid-line',
  'ri-file-forbid-fill',
  'ri-file-info-line',
  'ri-file-info-fill',
  'ri-file-warning-line',
  'ri-file-warning-fill',
  'ri-file-unknow-line',
  'ri-file-unknow-fill',
  'ri-file-user-line',
  'ri-file-user-fill',
  'ri-file-shield-line',
  'ri-file-shield-fill',
  'ri-file-shield-2-line',
  'ri-file-shield-2-fill',
  'ri-file-damage-line',
  'ri-file-damage-fill',
  'ri-file-history-line',
  'ri-file-history-fill',
  'ri-file-shred-line',
  'ri-file-shred-fill',
  'ri-file-cloud-line',
  'ri-file-cloud-fill',
  'ri-folder-line',
  'ri-folder-fill',
  'ri-folder-2-line',
  'ri-folder-2-fill',
  'ri-folder-3-line',
  'ri-folder-3-fill',
  'ri-folder-4-line',
  'ri-folder-4-fill',
  'ri-folder-5-line',
  'ri-folder-5-fill',
  'ri-folders-line',
  'ri-folders-fill',
  'ri-folder-add-line',
  'ri-folder-add-fill',
  'ri-folder-reduce-line',
  'ri-folder-reduce-fill',
  'ri-folder-settings-line',
  'ri-folder-settings-fill',
  'ri-folder-upload-line',
  'ri-folder-upload-fill',
  'ri-folder-transfer-line',
  'ri-folder-transfer-fill',
  'ri-folder-download-line',
  'ri-folder-download-fill',
  'ri-folder-lock-line',
  'ri-folder-lock-fill',
  'ri-folder-chart-line',
  'ri-folder-chart-fill',
  'ri-folder-chart-2-line',
  'ri-folder-chart-2-fill',
  'ri-folder-music-line',
  'ri-folder-music-fill',
  'ri-folder-forbid-line',
  'ri-folder-forbid-fill',
  'ri-folder-info-line',
  'ri-folder-info-fill',
  'ri-folder-warning-line',
  'ri-folder-warning-fill',
  'ri-folder-unknow-line',
  'ri-folder-unknow-fill',
  'ri-folder-user-line',
  'ri-folder-user-fill',
  'ri-folder-shield-line',
  'ri-folder-shield-fill',
  'ri-folder-shield-2-line',
  'ri-folder-shield-2-fill',
  'ri-folder-shared-line',
  'ri-folder-shared-fill',
  'ri-folder-received-line',
  'ri-folder-received-fill',
  'ri-folder-open-line',
  'ri-folder-open-fill',
  'ri-folder-keyhole-line',
  'ri-folder-keyhole-fill',
  'ri-folder-zip-line',
  'ri-folder-zip-fill',
  'ri-folder-history-line',
  'ri-folder-history-fill',
  'ri-markdown-line',
  'ri-markdown-fill'
];
