import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import KeyboardVoiceIcon from '@material-ui/icons/KeyboardVoice';
import SaveIcon from '@material-ui/icons/Save';

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  button: {
    margin: theme.spacing(1)
  }
}));

const ButtonWithIconAndLabel = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader
          title={
            <Typography variant="h2">Buttons with icons and label</Typography>
          }
          subheader=""
        />
        <Divider />
        <CardContent>
          <Box mt={1}>
            <div style={{ textAlign: 'center' }}>
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                startIcon={<DeleteIcon />}
              >
                Delete
              </Button>
              {/* This Button uses a Font Icon, see the installation instructions in the Icon component docs. */}
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                endIcon={<SendIcon />}
              >
                Send
              </Button>
              <Button
                variant="contained"
                color="default"
                className={classes.button}
                startIcon={<CloudUploadIcon />}
              >
                Upload
              </Button>
              <Button
                variant="contained"
                disabled
                color="secondary"
                className={classes.button}
                startIcon={<KeyboardVoiceIcon />}
              >
                Talk
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="large"
                className={classes.button}
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
            </div>
          </Box>
          <Box mt={1}>
            <Typography variant="subtitle1">
              Sometimes you might want to have icons for certain button to
              enhance the UX of the application as we recognize logos more
              easily than plain text. For example, if you have a delete button
              you can label it with a dustbin icon.
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

ButtonWithIconAndLabel.propTypes = {
  className: PropTypes.string
};

export default ButtonWithIconAndLabel;
