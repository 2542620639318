import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  makeStyles,
  withStyles,
  createMuiTheme,
  ThemeProvider
} from '@material-ui/core';
import { green, purple } from '@material-ui/core/colors';

const BootstrapButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: '#0063cc',
    borderColor: '#0063cc',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:hover': {
      backgroundColor: '#0069d9',
      borderColor: '#0062cc',
      boxShadow: 'none'
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#0062cc',
      borderColor: '#005cbf'
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)'
    }
  }
})(Button);

const ColorButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: purple[500],
    '&:hover': {
      backgroundColor: purple[700]
    }
  }
}))(Button);

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  button: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  margin: {
    margin: theme.spacing(1)
  }
}));

const theme = createMuiTheme({
  palette: {
    primary: green
  }
});

const CustomeButton = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader
          title={<Typography variant="h2">Customized buttons</Typography>}
        />
        <Divider />
        <CardContent>
          <Box>
            <div style={{ textAlign: 'center' }}>
              <ColorButton
                variant="contained"
                color="primary"
                className={classes.margin}
              >
                Custom CSS
              </ColorButton>
              <ThemeProvider theme={theme}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.margin}
                >
                  Theme Provider
                </Button>
              </ThemeProvider>
              <BootstrapButton
                variant="contained"
                color="primary"
                disableRipple
                className={classes.margin}
              >
                Bootstrap
              </BootstrapButton>
            </div>
          </Box>

          <Box mt={1}>
            <Typography variant="subtitle1">
              Here are some examples of customizing the component.
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

CustomeButton.propTypes = {
  className: PropTypes.string
};

export default CustomeButton;
