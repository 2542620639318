export default [
  'ri-apps-line',
  'ri-apps-fill',
  'ri-apps-2-line',
  'ri-apps-2-fill',
  'ri-function-line',
  'ri-function-fill',
  'ri-dashboard-line',
  'ri-dashboard-fill',
  'ri-menu-line',
  'ri-menu-fill',
  'ri-menu-2-line',
  'ri-menu-2-fill',
  'ri-menu-3-line',
  'ri-menu-3-fill',
  'ri-menu-4-line',
  'ri-menu-4-fill',
  'ri-menu-5-line',
  'ri-menu-5-fill',
  'ri-menu-add-line',
  'ri-menu-add-fill',
  'ri-more-line',
  'ri-more-fill',
  'ri-more-2-line',
  'ri-more-2-fill',
  'ri-heart-line',
  'ri-heart-fill',
  'ri-heart-2-line',
  'ri-heart-2-fill',
  'ri-heart-add-line',
  'ri-heart-add-fill',
  'ri-star-line',
  'ri-star-fill',
  'ri-star-s-line',
  'ri-star-s-fill',
  'ri-star-half-line',
  'ri-star-half-fill',
  'ri-star-half-s-line',
  'ri-star-half-s-fill',
  'ri-settings-line',
  'ri-settings-fill',
  'ri-settings-2-line',
  'ri-settings-2-fill',
  'ri-settings-3-line',
  'ri-settings-3-fill',
  'ri-settings-4-line',
  'ri-settings-4-fill',
  'ri-settings-5-line',
  'ri-settings-5-fill',
  'ri-settings-6-line',
  'ri-settings-6-fill',
  'ri-list-settings-line',
  'ri-list-settings-fill',
  'ri-forbid-line',
  'ri-forbid-fill',
  'ri-forbid-2-line',
  'ri-forbid-2-fill',
  'ri-information-line',
  'ri-information-fill',
  'ri-error-warning-line',
  'ri-error-warning-fill',
  'ri-question-line',
  'ri-question-fill',
  'ri-alert-line',
  'ri-alert-fill',
  'ri-spam-line',
  'ri-spam-fill',
  'ri-spam-2-line',
  'ri-spam-2-fill',
  'ri-spam-3-line',
  'ri-spam-3-fill',
  'ri-checkbox-blank-line',
  'ri-checkbox-blank-fill',
  'ri-checkbox-line',
  'ri-checkbox-fill',
  'ri-checkbox-indeterminate-line',
  'ri-checkbox-indeterminate-fill',
  'ri-add-box-line',
  'ri-add-box-fill',
  'ri-checkbox-blank-circle-line',
  'ri-checkbox-blank-circle-fill',
  'ri-checkbox-circle-line',
  'ri-checkbox-circle-fill',
  'ri-indeterminate-circle-line',
  'ri-indeterminate-circle-fill',
  'ri-add-circle-line',
  'ri-add-circle-fill',
  'ri-close-circle-line',
  'ri-close-circle-fill',
  'ri-radio-button-line',
  'ri-radio-button-fill',
  'ri-checkbox-multiple-blank-line',
  'ri-checkbox-multiple-blank-fill',
  'ri-checkbox-multiple-line',
  'ri-checkbox-multiple-fill',
  'ri-check-line',
  'ri-check-fill',
  'ri-check-double-line',
  'ri-check-double-fill',
  'ri-close-line',
  'ri-close-fill',
  'ri-add-line',
  'ri-add-fill',
  'ri-subtract-line',
  'ri-subtract-fill',
  'ri-divide-line',
  'ri-divide-fill',
  'ri-arrow-left-up-line',
  'ri-arrow-left-up-fill',
  'ri-arrow-up-line',
  'ri-arrow-up-fill',
  'ri-arrow-right-up-line',
  'ri-arrow-right-up-fill',
  'ri-arrow-right-line',
  'ri-arrow-right-fill',
  'ri-arrow-right-down-line',
  'ri-arrow-right-down-fill',
  'ri-arrow-down-line',
  'ri-arrow-down-fill',
  'ri-arrow-left-down-line',
  'ri-arrow-left-down-fill',
  'ri-arrow-left-line',
  'ri-arrow-left-fill',
  'ri-arrow-up-circle-line',
  'ri-arrow-up-circle-fill',
  'ri-arrow-right-circle-line',
  'ri-arrow-right-circle-fill',
  'ri-arrow-down-circle-line',
  'ri-arrow-down-circle-fill',
  'ri-arrow-left-circle-line',
  'ri-arrow-left-circle-fill',
  'ri-arrow-up-s-line',
  'ri-arrow-up-s-fill',
  'ri-arrow-down-s-line',
  'ri-arrow-down-s-fill',
  'ri-arrow-right-s-line',
  'ri-arrow-right-s-fill',
  'ri-arrow-left-s-line',
  'ri-arrow-left-s-fill',
  'ri-arrow-drop-up-line',
  'ri-arrow-drop-up-fill',
  'ri-arrow-drop-right-line',
  'ri-arrow-drop-right-fill',
  'ri-arrow-drop-down-line',
  'ri-arrow-drop-down-fill',
  'ri-arrow-drop-left-line',
  'ri-arrow-drop-left-fill',
  'ri-arrow-left-right-line',
  'ri-arrow-left-right-fill',
  'ri-arrow-up-down-line',
  'ri-arrow-up-down-fill',
  'ri-arrow-go-back-line',
  'ri-arrow-go-back-fill',
  'ri-arrow-go-forward-line',
  'ri-arrow-go-forward-fill',
  'ri-download-line',
  'ri-download-fill',
  'ri-upload-line',
  'ri-upload-fill',
  'ri-download-2-line',
  'ri-download-2-fill',
  'ri-upload-2-line',
  'ri-upload-2-fill',
  'ri-download-cloud-line',
  'ri-download-cloud-fill',
  'ri-download-cloud-2-line',
  'ri-download-cloud-2-fill',
  'ri-upload-cloud-line',
  'ri-upload-cloud-fill',
  'ri-upload-cloud-2-line',
  'ri-upload-cloud-2-fill',
  'ri-login-box-line',
  'ri-login-box-fill',
  'ri-logout-box-line',
  'ri-logout-box-fill',
  'ri-logout-box-r-line',
  'ri-logout-box-r-fill',
  'ri-login-circle-line',
  'ri-login-circle-fill',
  'ri-logout-circle-line',
  'ri-logout-circle-fill',
  'ri-logout-circle-r-line',
  'ri-logout-circle-r-fill',
  'ri-refresh-line',
  'ri-refresh-fill',
  'ri-shield-line',
  'ri-shield-fill',
  'ri-shield-cross-line',
  'ri-shield-cross-fill',
  'ri-shield-flash-line',
  'ri-shield-flash-fill',
  'ri-shield-star-line',
  'ri-shield-star-fill',
  'ri-shield-user-line',
  'ri-shield-user-fill',
  'ri-shield-keyhole-line',
  'ri-shield-keyhole-fill',
  'ri-delete-back-line',
  'ri-delete-back-fill',
  'ri-delete-back-2-line',
  'ri-delete-back-2-fill',
  'ri-delete-bin-line',
  'ri-delete-bin-fill',
  'ri-delete-bin-2-line',
  'ri-delete-bin-2-fill',
  'ri-delete-bin-3-line',
  'ri-delete-bin-3-fill',
  'ri-delete-bin-4-line',
  'ri-delete-bin-4-fill',
  'ri-delete-bin-5-line',
  'ri-delete-bin-5-fill',
  'ri-delete-bin-6-line',
  'ri-delete-bin-6-fill',
  'ri-delete-bin-7-line',
  'ri-delete-bin-7-fill',
  'ri-lock-line',
  'ri-lock-fill',
  'ri-lock-2-line',
  'ri-lock-2-fill',
  'ri-lock-password-line',
  'ri-lock-password-fill',
  'ri-lock-unlock-line',
  'ri-lock-unlock-fill',
  'ri-eye-line',
  'ri-eye-fill',
  'ri-eye-off-line',
  'ri-eye-off-fill',
  'ri-eye-2-line',
  'ri-eye-2-fill',
  'ri-eye-close-line',
  'ri-eye-close-fill',
  'ri-search-line',
  'ri-search-fill',
  'ri-search-2-line',
  'ri-search-2-fill',
  'ri-search-eye-line',
  'ri-search-eye-fill',
  'ri-zoom-in-line',
  'ri-zoom-in-fill',
  'ri-zoom-out-line',
  'ri-zoom-out-fill',
  'ri-find-replace-line',
  'ri-find-replace-fill',
  'ri-share-line',
  'ri-share-fill',
  'ri-share-box-line',
  'ri-share-box-fill',
  'ri-share-circle-line',
  'ri-share-circle-fill',
  'ri-share-forward-line',
  'ri-share-forward-fill',
  'ri-share-forward-2-line',
  'ri-share-forward-2-fill',
  'ri-share-forward-box-line',
  'ri-share-forward-box-fill',
  'ri-side-bar-line',
  'ri-side-bar-fill',
  'ri-time-line',
  'ri-time-fill',
  'ri-timer-line',
  'ri-timer-fill',
  'ri-timer-2-line',
  'ri-timer-2-fill',
  'ri-timer-flash-line',
  'ri-timer-flash-fill',
  'ri-alarm-line',
  'ri-alarm-fill',
  'ri-history-line',
  'ri-history-fill',
  'ri-thumb-down-line',
  'ri-thumb-down-fill',
  'ri-thumb-up-line',
  'ri-thumb-up-fill',
  'ri-alarm-warning-line',
  'ri-alarm-warning-fill',
  'ri-notification-badge-line',
  'ri-notification-badge-fill',
  'ri-toggle-line',
  'ri-toggle-fill',
  'ri-filter-line',
  'ri-filter-fill',
  'ri-filter-2-line',
  'ri-filter-2-fill',
  'ri-filter-3-line',
  'ri-filter-3-fill',
  'ri-loader-line',
  'ri-loader-fill',
  'ri-loader-2-line',
  'ri-loader-2-fill',
  'ri-loader-3-line',
  'ri-loader-3-fill',
  'ri-loader-4-line',
  'ri-loader-4-fill',
  'ri-loader-5-line',
  'ri-loader-5-fill',
  'ri-external-link-line',
  'ri-external-link-fill'
];
