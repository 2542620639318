export default [
  'ri-chat-1-line',
  'ri-chat-1-fill',
  'ri-chat-2-line',
  'ri-chat-2-fill',
  'ri-chat-3-line',
  'ri-chat-3-fill',
  'ri-chat-4-line',
  'ri-chat-4-fill',
  'ri-message-line',
  'ri-message-fill',
  'ri-message-2-line',
  'ri-message-2-fill',
  'ri-message-3-line',
  'ri-message-3-fill',
  'ri-chat-check-line',
  'ri-chat-check-fill',
  'ri-chat-delete-line',
  'ri-chat-delete-fill',
  'ri-chat-forward-line',
  'ri-chat-forward-fill',
  'ri-chat-upload-line',
  'ri-chat-upload-fill',
  'ri-chat-download-line',
  'ri-chat-download-fill',
  'ri-chat-new-line',
  'ri-chat-new-fill',
  'ri-chat-settings-line',
  'ri-chat-settings-fill',
  'ri-chat-smile-line',
  'ri-chat-smile-fill',
  'ri-chat-smile-2-line',
  'ri-chat-smile-2-fill',
  'ri-chat-smile-3-line',
  'ri-chat-smile-3-fill',
  'ri-chat-heart-line',
  'ri-chat-heart-fill',
  'ri-chat-off-line',
  'ri-chat-off-fill',
  'ri-feedback-line',
  'ri-feedback-fill',
  'ri-discuss-line',
  'ri-discuss-fill',
  'ri-question-answer-line',
  'ri-question-answer-fill',
  'ri-questionnaire-line',
  'ri-questionnaire-fill',
  'ri-video-chat-line',
  'ri-video-chat-fill',
  'ri-chat-voice-line',
  'ri-chat-voice-fill',
  'ri-chat-quote-line',
  'ri-chat-quote-fill',
  'ri-chat-follow-up-line',
  'ri-chat-follow-up-fill',
  'ri-chat-poll-line',
  'ri-chat-poll-fill',
  'ri-chat-history-line',
  'ri-chat-history-fill',
  'ri-chat-private-line',
  'ri-chat-private-fill'
];
