import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Container,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  Fade,
  Grow,
  Slide,
  Snackbar,
  makeStyles
} from '@material-ui/core';

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

function GrowTransition(props) {
  return <Grow {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {},
  code: {
    width: '100%',
    paddingTop: '15px',
    background: '#272C34',
    color: '#fff',
    marginTop: '10px',
    borderRadius: '4px',
    overflowX: 'scroll'
  }
}));

const OutlinedButtons = ({ className, ...rest }) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    open: false,
    Transition: Fade
  });

  const handleClick = Transition => () => {
    setState({
      open: true,
      Transition
    });
  };

  const handleClose = () => {
    setState({
      ...state,
      open: false
    });
  };

  var code = `<Button onClick={handleClick(GrowTransition)}>Grow Transition</Button>
<Button onClick={handleClick(Fade)}>Fade Transition</Button>
<Button onClick={handleClick(SlideTransition)}>Slide Transition</Button>
<Snackbar
  open={state.open}
  onClose={handleClose}
  TransitionComponent={state.Transition}
  message="I love snacks"
  key={state.Transition.name}
/>`;
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader
          title={<Typography variant="h2">Change Transition</Typography>}
        />
        <Divider />
        <CardContent>
          <Box mt={1} style={{ display: 'flex', justifyContent: 'center' }}>
            <div>
              <Button onClick={handleClick(GrowTransition)}>
                Grow Transition
              </Button>
              <Button onClick={handleClick(Fade)}>Fade Transition</Button>
              <Button onClick={handleClick(SlideTransition)}>
                Slide Transition
              </Button>
              <Snackbar
                open={state.open}
                onClose={handleClose}
                TransitionComponent={state.Transition}
                message="I love snacks"
                key={state.Transition.name}
              />
            </div>
          </Box>
          <Box className={classes.code}>
            <Container>
              <pre>
                <Typography
                  variant="subtitle2"
                  style={{ fontFamily: 'monospace', fontSize: 14 }}
                >
                  {code}
                </Typography>
              </pre>
            </Container>
          </Box>
          <Box mt={1}>
            <Typography variant="subtitle1">
              <b>Grow</b> is the default transition but you can use a different
              one.
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

OutlinedButtons.propTypes = {
  className: PropTypes.string
};

export default OutlinedButtons;
