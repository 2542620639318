import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Paper,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  makeStyles,
  Grid
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  cardbackground: {
    background: theme.palette.background.dark
  },
  grid: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }
}));

const AutoLayoutGrid = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card className={classes.cardbackground}>
        <CardHeader title={<Typography variant="h2">Auto-layout</Typography>} />
        <Divider />
        <CardContent className={classes.cardbackground}>
          <Box mt={1} mb={3}>
            <div className={classes.grid}>
              <Grid container spacing={3}>
                <Grid item xs>
                  <Paper className={classes.paper}>xs</Paper>
                </Grid>
                <Grid item xs>
                  <Paper className={classes.paper}>xs</Paper>
                </Grid>
                <Grid item xs>
                  <Paper className={classes.paper}>xs</Paper>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs>
                  <Paper className={classes.paper}>xs</Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper className={classes.paper}>xs=6</Paper>
                </Grid>
                <Grid item xs>
                  <Paper className={classes.paper}>xs</Paper>
                </Grid>
              </Grid>
            </div>
          </Box>
          <Box mt={1}>
            <Typography variant="subtitle1">
              The Auto-layout makes the items equitably share the available
              space. That also means you can set the width of one item and the
              others will automatically resize around it.
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

AutoLayoutGrid.propTypes = {
  className: PropTypes.string
};

export default AutoLayoutGrid;
