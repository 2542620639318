import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Avatar,
  Container,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';
import { green, pink } from '@material-ui/core/colors';
import FolderIcon from '@material-ui/icons/Folder';
import PageviewIcon from '@material-ui/icons/Pageview';
import AssignmentIcon from '@material-ui/icons/Assignment';

const useStyles = makeStyles(theme => ({
  root: {},
  image: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  pink: {
    color: theme.palette.getContrastText(pink[500]),
    backgroundColor: pink[500]
  },
  green: {
    color: '#fff',
    backgroundColor: green[500]
  },
  code: {
    width: '100%',
    paddingTop: '15px',
    background: '#272C34',
    color: '#fff',
    marginTop: '10px',
    borderRadius: '4px',
    overflowX: 'scroll'
  }
}));

const IconAvatar = ({ className, ...rest }) => {
  const classes = useStyles();

  var code = `<Avatar>
  <FolderIcon />
</Avatar>
<Avatar className={classes.pink}>
  <PageviewIcon />
</Avatar>
<Avatar className={classes.green}>
  <AssignmentIcon />
</Avatar>`;
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader
          title={<Typography variant="h2">Icon avatars</Typography>}
        />
        <Divider />
        <CardContent>
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <div className={classes.image}>
              <Avatar>
                <FolderIcon />
              </Avatar>
              <Avatar className={classes.pink}>
                <PageviewIcon />
              </Avatar>
              <Avatar className={classes.green}>
                <AssignmentIcon />
              </Avatar>
            </div>
          </Box>
          <Box className={classes.code}>
            <Container>
              <pre>
                <Typography
                  variant="subtitle2"
                  style={{ fontFamily: 'monospace', fontSize: 14 }}
                >
                  {code}
                </Typography>
              </pre>
            </Container>
          </Box>
          <Box mt={1}>
            <Typography variant="subtitle1">
              Icon avatars are created by passing an icon as <b>children</b>.
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

IconAvatar.propTypes = {
  className: PropTypes.string
};

export default IconAvatar;
