import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  makeStyles,
  Dialog,
  Switch,
  Select,
  MenuItem,
  InputLabel,
  FormControlLabel,
  FormControl,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content'
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120
  },
  formControlLabel: {
    marginTop: theme.spacing(1)
  }
}));

const OptionalSizesDialog = ({ className, ...rest }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMaxWidthChange = event => {
    setMaxWidth(event.target.value);
  };

  const handleFullWidthChange = event => {
    setFullWidth(event.target.checked);
  };
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader
          title={<Typography variant="h2">Optional sizes</Typography>}
        />
        <Divider />
        <CardContent>
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleClickOpen}
            >
              Open max-width dialog
            </Button>
            <Dialog
              fullWidth={fullWidth}
              maxWidth={maxWidth}
              open={open}
              onClose={handleClose}
              aria-labelledby="max-width-dialog-title"
            >
              <DialogTitle id="max-width-dialog-title">
                Optional sizes
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  You can set my maximum width and whether to adapt or not.
                </DialogContentText>
                <form className={classes.form} noValidate>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="max-width">maxWidth</InputLabel>
                    <Select
                      autoFocus
                      value={maxWidth}
                      onChange={handleMaxWidthChange}
                      inputProps={{
                        name: 'max-width',
                        id: 'max-width'
                      }}
                    >
                      <MenuItem value={false}>false</MenuItem>
                      <MenuItem value="xs">xs</MenuItem>
                      <MenuItem value="sm">sm</MenuItem>
                      <MenuItem value="md">md</MenuItem>
                      <MenuItem value="lg">lg</MenuItem>
                      <MenuItem value="xl">xl</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControlLabel
                    className={classes.formControlLabel}
                    control={
                      <Switch
                        checked={fullWidth}
                        onChange={handleFullWidthChange}
                      />
                    }
                    label="Full width"
                  />
                </form>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
          <Box mt={1}>
            <Typography variant="subtitle1">
              You can set a dialog maximum width by using the <b>maxWidth </b>
              enumerable in combination with the <b>fullWidth</b> boolean. When
              the <b>fullWidth</b> property is true, the dialog will adapt based
              on the
              <b> maxWidth</b> value.
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

OptionalSizesDialog.propTypes = {
  className: PropTypes.string
};

export default OptionalSizesDialog;
