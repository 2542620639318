import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles
} from '@material-ui/core';
import {
  DirectionsRenderer,
  GoogleMap,
  withGoogleMap
} from 'react-google-maps';

const google = window.google;
const useStyles = makeStyles(theme => ({
  root: {}
}));

const DirectionsExampleGoogleMap = withGoogleMap(props => (
  <GoogleMap defaultZoom={7} defaultCenter={props.center}>
    {props.directions && <DirectionsRenderer directions={props.directions} />}
  </GoogleMap>
));

const MapDirection = ({ className, ...rest }) => {
  const classes = useStyles();
  const origin = new google.maps.LatLng(41.85073, -87.65126);
  const destination = new google.maps.LatLng(41.85258, -87.65141);
  const [directions, setDirections] = useState(null);

  useEffect(() => {
    const DirectionsService = new google.maps.DirectionsService();

    DirectionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: google.maps.TravelMode.DRIVING
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          setDirections(result);
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  }, [destination, origin]);

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader title="Map Direction" />
        <Divider />
        <CardContent>
          <DirectionsExampleGoogleMap
            loadingElement={<Box height={1} />}
            containerElement={<Box height={{ xs: 300, sm: 400 }} />}
            mapElement={<Box height={1} />}
            center={origin}
            directions={directions}
          />
        </CardContent>
      </Card>
    </div>
  );
};

MapDirection.propTypes = {
  className: PropTypes.string
};

export default MapDirection;
