import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Select,
  InputLabel,
  FormHelperText,
  FormControl,
  Container,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  NativeSelect,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  code: {
    width: '100%',
    paddingTop: '15px',
    background: '#272C34',
    color: '#fff',
    marginTop: '10px',
    borderRadius: '4px',
    overflowX: 'scroll'
  }
}));

const NativeSelectComponent = ({ className, ...rest }) => {
  const classes = useStyles();

  const [state, setState] = React.useState({
    age: '',
    name: 'hai'
  });

  const handleChange = event => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value
    });
  };

  var code = `<FormControl className={classes.formControl}>
  <InputLabel htmlFor="age-native-simple">Age</InputLabel>
  <Select
    native
    value={state.age}
    onChange={handleChange}
    inputProps={{
      name: 'age',
      id: 'age-native-simple',
    }}
  >
    <option aria-label="None" value="" />
    <option value={10}>Ten</option>
    <option value={20}>Twenty</option>
    <option value={30}>Thirty</option>
  </Select>
</FormControl>`;
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader
          title={<Typography variant="h2">Native Select</Typography>}
        />
        <Divider />
        <CardContent>
          <Box mt={1} style={{ textAlign: 'center' }}>
            <div>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="age-native-simple">Age</InputLabel>
                <Select
                  native
                  value={state.age}
                  onChange={handleChange}
                  inputProps={{
                    name: 'age',
                    id: 'age-native-simple'
                  }}
                >
                  <option aria-label="None" value="" />
                  <option value={10}>Ten</option>
                  <option value={20}>Twenty</option>
                  <option value={30}>Thirty</option>
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="age-native-helper">Age</InputLabel>
                <NativeSelect
                  value={state.age}
                  onChange={handleChange}
                  inputProps={{
                    name: 'age',
                    id: 'age-native-helper'
                  }}
                >
                  <option aria-label="None" value="" />
                  <option value={10}>Ten</option>
                  <option value={20}>Twenty</option>
                  <option value={30}>Thirty</option>
                </NativeSelect>
                <FormHelperText>Some important helper text</FormHelperText>
              </FormControl>
              <FormControl className={classes.formControl}>
                <NativeSelect
                  value={state.age}
                  onChange={handleChange}
                  name="age"
                  className={classes.selectEmpty}
                  inputProps={{ 'aria-label': 'age' }}
                >
                  <option value="">None</option>
                  <option value={10}>Ten</option>
                  <option value={20}>Twenty</option>
                  <option value={30}>Thirty</option>
                </NativeSelect>
                <FormHelperText>With visually hidden label</FormHelperText>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel shrink htmlFor="age-native-label-placeholder">
                  Age
                </InputLabel>
                <NativeSelect
                  value={state.age}
                  onChange={handleChange}
                  inputProps={{
                    name: 'age',
                    id: 'age-native-label-placeholder'
                  }}
                >
                  <option value="">None</option>
                  <option value={10}>Ten</option>
                  <option value={20}>Twenty</option>
                  <option value={30}>Thirty</option>
                </NativeSelect>
                <FormHelperText>Label + placeholder</FormHelperText>
              </FormControl>
              <FormControl className={classes.formControl} disabled>
                <InputLabel htmlFor="name-native-disabled">Name</InputLabel>
                <NativeSelect
                  value={state.name}
                  onChange={handleChange}
                  inputProps={{
                    name: 'name',
                    id: 'name-native-disabled'
                  }}
                >
                  <option value="">None</option>
                  <optgroup label="Author">
                    <option value="hai">Hai</option>
                  </optgroup>
                  <optgroup label="Contributors">
                    <option value="olivier">Olivier</option>
                    <option value="kevin">Kevin</option>
                  </optgroup>
                </NativeSelect>
                <FormHelperText>Disabled</FormHelperText>
              </FormControl>
              <FormControl className={classes.formControl} error>
                <InputLabel htmlFor="name-native-error">Name</InputLabel>
                <NativeSelect
                  value={state.name}
                  onChange={handleChange}
                  name="name"
                  inputProps={{
                    id: 'name-native-error'
                  }}
                >
                  <optgroup label="Author">
                    <option value="hai">Hai</option>
                  </optgroup>
                  <optgroup label="Contributors">
                    <option value="olivier">Olivier</option>
                    <option value="kevin">Kevin</option>
                  </optgroup>
                </NativeSelect>
                <FormHelperText>Error</FormHelperText>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="uncontrolled-native">Name</InputLabel>
                <NativeSelect
                  defaultValue={30}
                  inputProps={{
                    name: 'name',
                    id: 'uncontrolled-native'
                  }}
                >
                  <option value={10}>Ten</option>
                  <option value={20}>Twenty</option>
                  <option value={30}>Thirty</option>
                </NativeSelect>
                <FormHelperText>Uncontrolled</FormHelperText>
              </FormControl>
              <FormControl className={classes.formControl}>
                <NativeSelect
                  className={classes.selectEmpty}
                  value={state.age}
                  name="age"
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'age' }}
                >
                  <option value="" disabled>
                    Placeholder
                  </option>
                  <option value={10}>Ten</option>
                  <option value={20}>Twenty</option>
                  <option value={30}>Thirty</option>
                </NativeSelect>
                <FormHelperText>Placeholder</FormHelperText>
              </FormControl>
              <FormControl required className={classes.formControl}>
                <InputLabel htmlFor="age-native-required">Age</InputLabel>
                <Select
                  native
                  value={state.age}
                  onChange={handleChange}
                  name="age"
                  inputProps={{
                    id: 'age-native-required'
                  }}
                >
                  <option aria-label="None" value="" />
                  <option value={10}>Ten</option>
                  <option value={20}>Twenty</option>
                  <option value={30}>Thirty</option>
                </Select>
                <FormHelperText>Required</FormHelperText>
              </FormControl>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="outlined-age-native-simple">
                  Age
                </InputLabel>
                <Select
                  native
                  value={state.age}
                  onChange={handleChange}
                  label="Age"
                  inputProps={{
                    name: 'age',
                    id: 'outlined-age-native-simple'
                  }}
                >
                  <option aria-label="None" value="" />
                  <option value={10}>Ten</option>
                  <option value={20}>Twenty</option>
                  <option value={30}>Thirty</option>
                </Select>
              </FormControl>
              <FormControl variant="filled" className={classes.formControl}>
                <InputLabel htmlFor="filled-age-native-simple">Age</InputLabel>
                <Select
                  native
                  value={state.age}
                  onChange={handleChange}
                  inputProps={{
                    name: 'age',
                    id: 'filled-age-native-simple'
                  }}
                >
                  <option aria-label="None" value="" />
                  <option value={10}>Ten</option>
                  <option value={20}>Twenty</option>
                  <option value={30}>Thirty</option>
                </Select>
              </FormControl>
            </div>
          </Box>
          <Box className={classes.code}>
            <Container>
              <pre>
                <Typography
                  variant="subtitle2"
                  style={{ fontFamily: 'monospace', fontSize: 14 }}
                >
                  {code}
                </Typography>
              </pre>
            </Container>
          </Box>
          <Box mt={1}>
            <Typography variant="subtitle1">
              <b>Outlined buttons</b> are medium-emphasis buttons. They contain
              actions that are important, but aren’t the primary action in an
              app.
            </Typography>
            <Typography variant="subtitle1">
              Outlined buttons are also a lower emphasis alternative to
              contained buttons, or a higher emphasis alternative to text
              buttons.
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

NativeSelectComponent.propTypes = {
  className: PropTypes.string
};

export default NativeSelectComponent;
