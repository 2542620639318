import React from 'react';
import clsx from 'clsx';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  Container,
  Typography
} from '@material-ui/core';

const states = [
  {
    value: '',
    label: ''
  },
  {
    value: 'alabama',
    label: 'Alabama'
  },
  {
    value: 'new-york',
    label: 'New York'
  },
  {
    value: 'san-francisco',
    label: 'San Francisco'
  }
];

const useStyles = makeStyles(() => ({
  root: {}
}));

const ProfileDetails = (props, { className, ...rest }) => {
  const classes = useStyles();
  return (
    <Container maxWidth="md">
      <Formik
        initialValues={{
          firstName: 'Jnext',
          lastName: 'Developement',
          email: 'jnext@co.in',
          phone: '0123456789',
          country: 'USA',
          state: 'alabama'
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string().required('Please Enter Current is required'),
          lastName: Yup.string().required('Password is required'),
          email: Yup.string()
            .email('Must be a valid Email')
            .required('Email is required'),
          phone: Yup.string()
            .min(10, 'Invalid Phone Number')
            .required('Phone Number is required'),
          country: Yup.string().required('Country is required'),
          state: Yup.string().required('State is required')
        })}
        onSubmit={data => {
          console.log('data', data);
          props.SuccessClick(); // for success pop-up msg
          // props.ErrorClick(); // for error pop-up msg
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values
        }) => (
          <form
            onSubmit={handleSubmit}
            className={clsx(classes.root, className)}
          >
            <Card>
              <CardHeader
                title={
                  <Typography color="textPrimary" variant="h2">
                    Profile
                  </Typography>
                }
              />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.firstName && errors.firstName)}
                      fullWidth
                      helperText={touched.firstName && errors.firstName}
                      label="First name"
                      name="firstName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.firstName}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.lastName && errors.lastName)}
                      fullWidth
                      helperText={touched.lastName && errors.lastName}
                      label="Last name"
                      name="lastName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.lastName}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      label="Email Address"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.phone && errors.phone)}
                      fullWidth
                      helperText={touched.phone && errors.phone}
                      label="Phone Number"
                      name="phone"
                      onBlur={handleBlur}
                      onChange={e => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === '' || re.test(e.target.value)) {
                          handleChange(e);
                        }
                      }}
                      inputProps={{ maxLength: 10 }}
                      type="text"
                      value={values.phone}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.country && errors.country)}
                      fullWidth
                      helperText={touched.country && errors.country}
                      label="Country"
                      name="country"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.country}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.state && errors.state)}
                      fullWidth
                      helperText={touched.state && errors.state}
                      label="Select State"
                      name="state"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      select
                      SelectProps={{ native: true }}
                      value={values.state}
                      variant="outlined"
                    >
                      {states.map(option => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box display="flex" justifyContent="flex-end" p={2}>
                <Button type="submit" color="primary" variant="contained">
                  Save details
                </Button>
              </Box>
            </Card>
          </form>
        )}
      </Formik>
    </Container>
  );
};

export default ProfileDetails;
