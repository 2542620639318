import React from 'react';
import { Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import IconsView from './icon';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const VenueView = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="DripIcons">
      <Container>
        <IconsView />
      </Container>
    </Page>
  );
};

export default VenueView;
