import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles
} from '@material-ui/core';
import { GoogleMap, KmlLayer, withGoogleMap } from 'react-google-maps';

const useStyles = makeStyles(theme => ({
  root: {}
}));

const KmlLayerExampleGoogleMap = withGoogleMap(() => (
  <GoogleMap defaultZoom={8} defaultCenter={{ lat: 41.876, lng: -87.624 }}>
    <KmlLayer
      url="http://googlemaps.github.io/js-v2-samples/ggeoxml/cta.kml"
      options={{ preserveViewport: true }}
    />
  </GoogleMap>
));

const KMLayerMap = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader title="Map KM Layer" />
        <Divider />
        <CardContent>
          <KmlLayerExampleGoogleMap
            loadingElement={<Box height={1} />}
            containerElement={<Box height={{ xs: 300, sm: 400 }} />}
            mapElement={<Box height={1} />}
          />
        </CardContent>
      </Card>
    </div>
  );
};

KMLayerMap.propTypes = {
  className: PropTypes.string
};

export default KMLayerMap;
