import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  CardActionArea,
  CardMedia,
  Divider,
  Typography,
  makeStyles,
  Grid
} from '@material-ui/core';
import data from './Data/data';

const useStyles = makeStyles(theme => ({
  root: {},
  cardRoot: {
    minWidth: 275
  },
  cardbackground: {
    background: theme.palette.background.dark
  },
  media: {
    height: 140
  }
}));

const OutlinedButtons = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card className={classes.cardbackground}>
        <CardHeader title={<Typography variant="h2">Media</Typography>} />
        <Divider />
        <CardContent className={classes.cardbackground}>
          <Box mt={1} mb={3}>
            <Grid container justify="center" spacing={3}>
              {data.map(val => {
                return (
                  <Grid item xs={12} sm={3} key={val.id}>
                    <Card className={classes.root}>
                      <CardActionArea>
                        <CardMedia
                          className={classes.media}
                          image="https://material-ui.com/static/images/cards/contemplative-reptile.jpg"
                          title="Contemplative Reptile"
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="h2">
                            Lizard
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                          >
                            Lizards are a widespread group of squamate reptiles,
                            with over 6,000 species, ranging across all
                            continents except Antarctica
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                      <CardActions>
                        <Button size="small" color="primary">
                          Share
                        </Button>
                        <Button size="small" color="primary">
                          Learn More
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Box>

          <Box mt={1}>
            <Typography variant="subtitle1">
              Example of a card using an image to reinforce the content.
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

OutlinedButtons.propTypes = {
  className: PropTypes.string
};

export default OutlinedButtons;
