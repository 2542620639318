import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import 'src/Assets/css/profile.scss';

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Jnext Developement',
  timezone: 'GTM-7'
};

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 150,
    width: 150
  },
  input: {
    display: 'none'
  }
}));

const Profile = (props, { className, ...rest }) => {
  const classes = useStyles();
  const [avatar, setAvatar] = React.useState(user?.avatar);

  const ProfilePicUpload = event => {
    const file = event.target.files;
    const formData = new FormData(); //formdata converts the binary format that is valid for upload
    formData.append('file', file[0]); // for single file

    // api here for image upload

    setAvatar(URL.createObjectURL(event.target.files[0])); // after upload set response file name that set into the avatar for preview

    props.SuccessClick(); // for success messege pop-up show

    // props.ErrorClick(); // for error msg
  };

  const ProfilPicDelete = () => {
    console.log('delete image');
    setAvatar('');
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Box alignItems="center" display="flex" flexDirection="column">
          <label className="profile-image-del">
            <div className="profile-image-delete" onClick={ProfilPicDelete}>
              <DeleteIcon />
            </div>
            <Avatar className={classes.avatar} src={avatar} alt={avatar} />
          </label>
          <Typography
            style={{ marginTop: 10 }}
            color="textPrimary"
            gutterBottom
            variant="h3"
          >
            {user.name}
          </Typography>
          <Typography color="textSecondary" variant="body1">
            {`${user.city} ${user.country}`}
          </Typography>
          <Typography
            className={classes.dateText}
            color="textSecondary"
            variant="body1"
          >
            {`${moment().format('hh:mm A')} ${user.timezone}`}
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        <input
          accept="image/*"
          className={classes.input}
          id="contained-button-file"
          onChange={ProfilePicUpload}
          type="file"
        />
        <label
          htmlFor="contained-button-file"
          style={{ width: '100%', marginLeft: 0 }}
        >
          <Button
            fullWidth
            variant="contained"
            color="primary"
            component="span"
          >
            Upload picture
          </Button>
        </label>
      </CardActions>
    </Card>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
