import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  TextField,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  text: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch'
    }
  }
}));

const TextFieldValidation = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader title={<Typography variant="h2">Validation</Typography>} />
        <Divider />
        <CardContent>
          <Box mt={1}>
            <form className={classes.text} style={{ textAlign: 'center' }}>
              <div>
                <TextField
                  error
                  id="standard-error"
                  label="Error"
                  defaultValue="Hello World"
                />
                <TextField
                  error
                  id="standard-error-helper-text"
                  label="Error"
                  defaultValue="Hello World"
                  helperText="Incorrect entry."
                />
              </div>
              <div>
                <TextField
                  error
                  id="filled-error"
                  label="Error"
                  defaultValue="Hello World"
                  variant="filled"
                />
                <TextField
                  error
                  id="filled-error-helper-text"
                  label="Error"
                  defaultValue="Hello World"
                  helperText="Incorrect entry."
                  variant="filled"
                />
              </div>
              <div>
                <TextField
                  error
                  id="outlined-error"
                  label="Error"
                  defaultValue="Hello World"
                  variant="outlined"
                />
                <TextField
                  error
                  id="outlined-error-helper-text"
                  label="Error"
                  defaultValue="Hello World"
                  helperText="Incorrect entry."
                  variant="outlined"
                />
              </div>
            </form>
          </Box>

          <Box mt={1}>
            <Typography variant="subtitle1">
              The <b>error</b> prop toggles the error state, the&nbsp;
              <b>helperText</b> prop can then be used to provide feedback to the
              user about the error.
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

TextFieldValidation.propTypes = {
  className: PropTypes.string
};

export default TextFieldValidation;
