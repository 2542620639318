import React from 'react';
import { Box, Container, Grid, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import AddCardForm from './addCardForm';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  productCard: {
    height: '100%'
  }
}));

const VenueView = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Cards">
      <Container maxWidth={false}>
        <Box>
          <Grid container justify="center" spacing={3}>
            <AddCardForm />
          </Grid>
        </Box>
      </Container>
    </Page>
  );
};

export default VenueView;
