import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Link,
  Card,
  Grid,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const LoginView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <Page className={classes.root} title="Forgot Password">
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '100%' }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Must be a valid email')
                .max(255)
                .required('Email is required')
            })}
            onSubmit={data => {
              console.log('data', data);
              navigate('/forgot-password-change', { replace: true });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Card>
                  <CardHeader
                    title={
                      <Typography color="textPrimary" variant="h2">
                        Reset Password
                      </Typography>
                    }
                  />
                  <Divider />
                  <CardContent>
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      label="Registered Email Address"
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.email}
                      variant="outlined"
                      autoComplete="new-password"
                    />

                    <Box my={2} align="center">
                      <Button
                        color="primary"
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        style={{ color: '#fff' }}
                      >
                        Submit
                      </Button>
                    </Box>
                    <Typography color="textSecondary" variant="body1">
                      <Link component={RouterLink} to="/" variant="h6">
                        Sign in
                      </Link>
                    </Typography>
                  </CardContent>
                </Card>
              </form>
            )}
          </Formik>
        </Container>
      </Grid>
    </Page>
  );
};

export default LoginView;
