import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  Container,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  button: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  code: {
    width: '100%',
    paddingTop: '15px',
    background: '#272C34',
    color: '#fff',
    marginTop: '10px',
    borderRadius: '4px',
    overflowX: 'scroll'
  }
}));

const ButtonText = ({ className, ...rest }) => {
  const classes = useStyles();
  var code = `<Button>Default</Button>
<Button color="primary">Primary</Button>
<Button color="secondary">Secondary</Button>
<Button disabled>Disabled</Button>
<Button href="#text-buttons" color="primary">
  Link
</Button>`;
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader
          title={<Typography variant="h2">Text Buttons</Typography>}
          subheader=""
        />
        <Divider />
        <CardContent>
          <Box mt={1}>
            <div className={classes.button} style={{ textAlign: 'center' }}>
              <Button>Default</Button>
              <Button color="primary">Primary</Button>
              <Button color="secondary">Secondary</Button>
              <Button disabled>Disabled</Button>
              <Button href="#text-buttons" color="primary">
                Link
              </Button>
            </div>
          </Box>
          <Box className={classes.code}>
            <Container>
              <pre>
                <Typography
                  variant="subtitle2"
                  style={{ fontFamily: 'monospace', fontSize: 14 }}
                >
                  {code}
                </Typography>
              </pre>
            </Container>
          </Box>
          <Box mt={1}>
            <Typography variant="subtitle1">
              <b>Text buttons</b> are typically used for less-pronounced
              actions, including those located : In dialogs & cards
            </Typography>
            <Typography variant="subtitle1">
              In cards, text buttons help maintain an emphasis on card content.
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

ButtonText.propTypes = {
  className: PropTypes.string
};

export default ButtonText;
