import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Paper,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  makeStyles,
  Grid
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  cardbackground: {
    background: theme.palette.background.dark
  },
  grid: {
    flexGrow: 1
  },
  paper: {
    height: 140,
    width: 100
  },
  control: {
    padding: theme.spacing(2)
  }
}));

const SpacingGrid = ({ className, ...rest }) => {
  const classes = useStyles();
  const [spacing, setSpacing] = React.useState(2);

  const handleChange = event => {
    setSpacing(Number(event.target.value));
  };
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card className={classes.cardbackground}>
        <CardHeader title={<Typography variant="h2">Spacing</Typography>} />
        <Divider />
        <CardContent className={classes.cardbackground}>
          <Box mt={1} mb={3}>
            <Grid container className={classes.grid} spacing={2}>
              <Grid item xs={12}>
                <Grid container justify="center" spacing={spacing}>
                  {[0, 1, 2].map(value => (
                    <Grid key={value} item>
                      <Paper className={classes.paper} />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Paper className={classes.control}>
                  <Grid container>
                    <Grid item>
                      <FormLabel>spacing</FormLabel>
                      <RadioGroup
                        name="spacing"
                        aria-label="spacing"
                        value={spacing.toString()}
                        onChange={handleChange}
                        row
                      >
                        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(value => (
                          <FormControlLabel
                            key={value}
                            value={value.toString()}
                            control={<Radio />}
                            label={value.toString()}
                          />
                        ))}
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Box>

          <Box mt={1}>
            <Typography variant="subtitle1">
              The responsive grid focuses on consistent spacing widths, rather
              than column width. Material Design margins and columns follow an
              <b> 8px</b> square baseline grid.
            </Typography>
            <Typography variant="subtitle1">
              The spacing property is an integer between 0 and 10 inclusive. By
              default, the spacing between two grid items follows a linear
              function:
            </Typography>
            <Typography variant="subtitle1">
              <b> output(spacing) = spacing * 8px, e.g. spacing={2} </b>creates
              a 16px wide gap.
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

SpacingGrid.propTypes = {
  className: PropTypes.string
};

export default SpacingGrid;
