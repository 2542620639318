export default [
  'ri-basketball-line',
  'ri-basketball-fill',
  'ri-bell-line',
  'ri-bell-fill',
  'ri-billiards-line',
  'ri-billiards-fill',
  'ri-boxing-line',
  'ri-boxing-fill',
  'ri-cake-line',
  'ri-cake-fill',
  'ri-cake-2-line',
  'ri-cake-2-fill',
  'ri-cake-3-line',
  'ri-cake-3-fill',
  'ri-door-lock-line',
  'ri-door-lock-fill',
  'ri-door-lock-box-line',
  'ri-door-lock-box-fill',
  'ri-flask-line',
  'ri-flask-fill',
  'ri-football-line',
  'ri-football-fill',
  'ri-game-line',
  'ri-game-fill',
  'ri-handbag-line',
  'ri-handbag-fill',
  'ri-hearts-line',
  'ri-hearts-fill',
  'ri-key-line',
  'ri-key-fill',
  'ri-key-2-line',
  'ri-key-2-fill',
  'ri-knife-line',
  'ri-knife-fill',
  'ri-knife-blood-line',
  'ri-knife-blood-fill',
  'ri-lightbulb-line',
  'ri-lightbulb-fill',
  'ri-lightbulb-flash-line',
  'ri-lightbulb-flash-fill',
  'ri-outlet-line',
  'ri-outlet-fill',
  'ri-outlet-2-line',
  'ri-outlet-2-fill',
  'ri-ping-pong-line',
  'ri-ping-pong-fill',
  'ri-plug-line',
  'ri-plug-fill',
  'ri-plug-2-line',
  'ri-plug-2-fill',
  'ri-reserved-line',
  'ri-reserved-fill',
  'ri-shirt-line',
  'ri-shirt-fill',
  'ri-sword-line',
  'ri-sword-fill',
  'ri-t-shirt-line',
  'ri-t-shirt-fill',
  'ri-t-shirt-2-line',
  'ri-t-shirt-2-fill',
  'ri-t-shirt-air-line',
  'ri-t-shirt-air-fill',
  'ri-umbrella-line',
  'ri-umbrella-fill',
  'ri-character-recognition-line',
  'ri-character-recognition-fill',
  'ri-voice-recognition-line',
  'ri-voice-recognition-fill',
  'ri-leaf-line',
  'ri-leaf-fill',
  'ri-plant-line',
  'ri-plant-fill',
  'ri-recycle-line',
  'ri-recycle-fill',
  'ri-scales-line',
  'ri-scales-fill',
  'ri-scales-2-line',
  'ri-scales-2-fill',
  'ri-fridge-line',
  'ri-fridge-fill',
  'ri-wheelchair-line',
  'ri-wheelchair-fill'
];
