import React from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Continuous from './continuous';
import DiscreteSlider from './discrete';
import SmallSteps from './smallSteps';
import CustomMarks from './customMarks';
import Restricted from './restricted';
import LabelAlwaysVisible from './labelAlwaysVisible';
import RangeSlider from './rangeSlider';
import SliderWithTextField from './sliderWithTextField';
import Customized from './customized';
import VerticalSlider from './vertical';
import RemovedTrack from './removedTrack';
import InvertedTrack from './invertedTrack';
import NonLinearScale from './nonLinearScale';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const SelectIndex = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Slider">
      <Container maxWidth="lg">
        <Box>
          <Continuous />
        </Box>
        <Box mt={3}>
          <DiscreteSlider />
        </Box>
        <Box mt={3}>
          <SmallSteps />
        </Box>
        <Box mt={3}>
          <CustomMarks />
        </Box>
        <Box mt={3}>
          <Restricted />
        </Box>
        <Box mt={3}>
          <LabelAlwaysVisible />
        </Box>
        <Box mt={3}>
          <RangeSlider />
        </Box>
        <Box mt={3}>
          <SliderWithTextField />
        </Box>
        <Box mt={3}>
          <Customized />
        </Box>
        <Box mt={3}>
          <VerticalSlider />
        </Box>
        <Box mt={3}>
          <RemovedTrack />
        </Box>
        <Box mt={3}>
          <InvertedTrack />
        </Box>
        <Box mt={3}>
          <NonLinearScale />
        </Box>
      </Container>
    </Page>
  );
};

export default SelectIndex;
