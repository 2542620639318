import React from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import BasicChart from './basic';
import SpotChart from './spotsChart';
import BarChart from './bars';
import ReferenceLineChart from './referenceLine';
import NormalBandChart from './normalBand';
import RealWorld from './realWorld';
import CompositeBarChart from './compositeBar';
import DynamicChart from './dynamic';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const SparklineChartIndex = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Sparkline Chart">
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item lg={4} sm={4} xl={4} xs={12}>
            <SpotChart />
          </Grid>
          <Grid item lg={4} sm={4} xl={4} xs={12}>
            <BarChart />
          </Grid>
          <Grid item lg={4} sm={4} xl={4} xs={12}>
            <BasicChart />
          </Grid>
          <Grid item lg={4} sm={4} xl={4} xs={12}>
            <ReferenceLineChart />
          </Grid>
          <Grid item lg={4} sm={4} xl={4} xs={12}>
            <NormalBandChart />
          </Grid>
          <Grid item lg={4} sm={4} xl={4} xs={12}>
            <RealWorld />
          </Grid>
          <Grid item lg={4} sm={4} xl={4} xs={12}>
            <CompositeBarChart />
          </Grid>
          <Grid item lg={4} sm={4} xl={4} xs={12}>
            <DynamicChart />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default SparklineChartIndex;
