import React from 'react';
import { makeStyles, InputBase, fade, IconButton } from '@material-ui/core';
import { Chat } from '@progress/kendo-react-conversational-ui';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import SearchIcon from '@material-ui/icons/Search';
import './chat.scss';

const useStyles = makeStyles(theme => ({
  input: {
    display: 'none'
  },
  chatSection: {
    width: '100%',
    height: '100%'
  },
  borderRight500: {
    borderRight: '1px solid #e0e0e0'
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.black, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.black, 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto'
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch'
    }
  }
}));

const AttachmentTemplate = props => {
  let attachment = props.item;
  return (
    <div className="k-card k-card-type-rich">
      <div className="k-card-body quoteCard">
        <img
          style={{ maxHeight: '120px' }}
          src={attachment.content}
          alt="attached_image"
          draggable={false}
        />
      </div>
    </div>
  );
};

const ChatBoxComponent = () => {
  const classes = useStyles();
  const [toolbar, setToolbar] = React.useState(false);

  const user = {
    id: 1,
    avatarUrl: 'https://material-ui.com/static/images/avatar/2.jpg'
  };
  const bot = {
    id: 0,
    avatarUrl: 'https://material-ui.com/static/images/avatar/1.jpg'
  };

  const [messages, setMessages] = React.useState([
    {
      author: bot,
      suggestedActions: [
        {
          type: 'reply',
          value: 'Oh, really?'
        },
        {
          type: 'reply',
          value: 'Thanks, but this is boring.'
        }
      ],
      timestamp: new Date(),
      text:
        "Hello, this is a demo bot. I don't do much, but I can count symbols!"
    }
  ]);

  const handleInputChange = e => {
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.onloadend = event => {
      let message = {
        author: user,
        text: '',
        attachments: [
          {
            content: event.target.result,
            contentType: 'image/'
          }
        ]
      };
      setMessages([...messages, message]);
      setToolbar(false);
    };
    reader.readAsDataURL(file);
  };

  const Toolbar = () => {
    return (
      <div>
        <input
          accept="image/*"
          onChange={handleInputChange}
          className={classes.input}
          id="contained-button-file"
          type="file"
        />
        <label htmlFor="contained-button-file">
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
          >
            <ImageIcon />
          </IconButton>
        </label>
      </div>
    );
  };

  const AddNewMessage = event => {
    let botResponce = Object.assign({}, event.message);
    botResponce.text = countReplayLength(event.message.text);
    botResponce.author = bot;
    setMessages([...messages, event.message]);
    setTimeout(() => {
      setMessages(prev => {
        return [...prev, botResponce];
      });
    }, 1000);
  };

  const countReplayLength = question => {
    let length = question.length;
    let answer = question + ' contains exactly ' + length + ' symbols.';
    return answer;
  };

  return (
    <div>
      <Grid container component={Paper} className={classes.chatSection}>
        <Grid item xs={3} className={classes.borderRight500}>
          <List>
            <ListItem>
              <ListItemIcon>
                <Avatar alt="J" src="/static/jnext.png" />
              </ListItemIcon>
              <ListItemText primary="JnReact"></ListItemText>
            </ListItem>
          </List>
          <Divider />
          <Grid item xs={12} style={{ padding: '10px' }}>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput
                }}
                inputProps={{ 'aria-label': 'search' }}
              />
            </div>
          </Grid>
          <Divider />
          <List>
            <ListItem button key="RemySharp">
              <ListItemIcon>
                <Avatar
                  alt="Remy Sharp"
                  src="https://material-ui.com/static/images/avatar/1.jpg"
                />
              </ListItemIcon>
              <ListItemText primary="Remy Sharp">Remy Sharp</ListItemText>
              <ListItemText secondary="online" align="right"></ListItemText>
            </ListItem>
            <ListItem button key="Alice">
              <ListItemIcon>
                <Avatar
                  alt="Alice"
                  src="https://material-ui.com/static/images/avatar/3.jpg"
                />
              </ListItemIcon>
              <ListItemText primary="Alice">Alice</ListItemText>
            </ListItem>
            <ListItem button key="CindyBaker">
              <ListItemIcon>
                <Avatar
                  alt="Cindy Baker"
                  src="https://material-ui.com/static/images/avatar/2.jpg"
                />
              </ListItemIcon>
              <ListItemText primary="Cindy Baker">Cindy Baker</ListItemText>
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={9}>
          <List>
            <ListItem button key="RemySharp">
              <ListItemIcon>
                <Avatar
                  alt="Remy Sharp"
                  src="https://material-ui.com/static/images/avatar/1.jpg"
                />
              </ListItemIcon>
              <ListItemText primary="John Wick"></ListItemText>
            </ListItem>
          </List>
          <Divider />
          <div className="jn-chatbox">
            <Chat
              user={user}
              messages={messages}
              onMessageSend={AddNewMessage}
              placeholder={'Type a message...'}
              width="100%"
              attachmentTemplate={AttachmentTemplate}
              showToolbar={toolbar}
              onToolbarActionExecute={e =>
                toolbar ? setToolbar(false) : setToolbar(true)
              }
              toolbar={<Toolbar />}
            ></Chat>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ChatBoxComponent;
