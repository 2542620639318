import React from 'react';
import { makeStyles, Container } from '@material-ui/core';
import Page from 'src/components/Page';
import ChatView from './chat';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    display: 'flex',
    alignItems: 'center'
  }
}));
const UiComponents = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Chat">
      <Container maxWidth="lg">
        <ChatView />
      </Container>
    </Page>
  );
};

export default UiComponents;
