import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles
} from '@material-ui/core';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const useStyles = makeStyles(theme => ({
  root: {}
}));

const MySwal = withReactContent(Swal);

const CustomePositionComponent = ({ className, ...rest }) => {
  const classes = useStyles();
  const sweetAlerts = () => {
    MySwal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Your work has been saved',
      timer: 1500
    });
  };
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader title="Custom Position" />
        <Divider />
        <CardContent>
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Button variant="outlined" color="primary" onClick={sweetAlerts}>
              Click me
            </Button>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

CustomePositionComponent.propTypes = {
  className: PropTypes.string
};

export default CustomePositionComponent;
