import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Avatar,
  Container,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';
import { deepOrange } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  root: {},
  image: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500]
  },
  code: {
    width: '100%',
    paddingTop: '15px',
    background: '#272C34',
    color: '#fff',
    marginTop: '10px',
    borderRadius: '4px',
    overflowX: 'scroll'
  }
}));

const FallbackAvatar = ({ className, ...rest }) => {
  const classes = useStyles();

  var code = `<Avatar alt="Remy Sharp" src="/broken-image.jpg" className={classes.orange}>
  B
</Avatar>
<Avatar alt="Remy Sharp" src="/broken-image.jpg" className={classes.orange} />
<Avatar src="/broken-image.jpg" />`;
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader title={<Typography variant="h2">Fallbacks</Typography>} />
        <Divider />
        <CardContent>
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <div className={classes.image}>
              <Avatar
                alt="Remy Sharp"
                src="/broken-image.jpg"
                className={classes.orange}
              >
                B
              </Avatar>
              <Avatar
                alt="Remy Sharp"
                src="/broken-image.jpg"
                className={classes.orange}
              />
              <Avatar src="/broken-image.jpg" />
            </div>
          </Box>
          <Box className={classes.code}>
            <Container>
              <pre>
                <Typography
                  variant="subtitle2"
                  style={{ fontFamily: 'monospace', fontSize: 14 }}
                >
                  {code}
                </Typography>
              </pre>
            </Container>
          </Box>
          <Box mt={1}>
            <Typography variant="subtitle1">
              If there is an error loading the avatar image, the component falls
              back to an alternative in the following order:
            </Typography>
            <Typography variant="subtitle1">
              (1) the provided children
            </Typography>
            <Typography variant="subtitle1">
              (2) the first letter of the <b>alt</b> text
            </Typography>
            <Typography variant="subtitle1">
              (3) a generic avatar icon
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

FallbackAvatar.propTypes = {
  className: PropTypes.string
};

export default FallbackAvatar;
