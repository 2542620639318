import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  TextField,
  makeStyles,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Dialog
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {}
}));

const FormDialogCompo = ({ className, ...rest }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader
          title={<Typography variant="h2">Form dialogs</Typography>}
        />
        <Divider />
        <CardContent>
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <div>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClickOpen}
              >
                Open form dialog
              </Button>
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    To subscribe to this website, please enter your email
                    address here. We will send updates occasionally.
                  </DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Email Address"
                    type="email"
                    fullWidth
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={handleClose} color="primary">
                    Subscribe
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </Box>

          <Box mt={1}>
            <Typography variant="subtitle1">
              Form dialogs allow users to fill out form fields within a dialog.
              For example, if your site prompts for potential subscribers to
              fill in their email address, they can fill out the email field and
              touch 'Submit'.
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

FormDialogCompo.propTypes = {
  className: PropTypes.string
};

export default FormDialogCompo;
