import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
//import OtpInput from 'react-otp-input';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Link,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';

import Page from 'src/components/Page';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const OTPVerificationView = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="OTP Verification">
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '100%' }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              otp: ''
            }}
            validationSchema={Yup.object().shape({
              otp: Yup.string()
                .min(4, '4 Digits Required')
                .max(4, 'You Entered More than 4 Digits')
                .required('OTP is required')
            })}
            onSubmit={data => {
              console.log('data', data);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Card>
                  <CardHeader
                    title={
                      <Typography color="textPrimary" variant="h2">
                        OTP Verification
                      </Typography>
                    }
                  />
                  <Divider />
                  <CardContent>
                    <TextField
                      error={Boolean(touched.otp && errors.otp)}
                      fullWidth
                      helperText={touched.otp && errors.otp}
                      label="One Time Password (OTP)"
                      margin="normal"
                      name="otp"
                      onBlur={handleBlur}
                      onChange={e => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === '' || re.test(e.target.value)) {
                          handleChange(e);
                        }
                      }}
                      type="text"
                      inputProps={{ maxLength: 4 }}
                      value={values.otp}
                      variant="outlined"
                      autoComplete="new-password"
                    />

                    <Box my={2} align="center">
                      <Button
                        color="primary"
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        style={{ color: '#fff' }}
                      >
                        verify
                      </Button>
                    </Box>
                    <Typography color="textSecondary" variant="body1">
                      Don't get a Code? &nbsp;
                      <Link style={{ cursor: 'pointer' }} variant="h6">
                        Resend OTP
                      </Link>
                    </Typography>
                    <Typography color="textSecondary" variant="body1">
                      <Link component={RouterLink} to="/register" variant="h6">
                        Sign Up
                      </Link>
                    </Typography>
                  </CardContent>
                </Card>
              </form>
            )}
          </Formik>
        </Container>
      </Grid>
    </Page>
  );
};

export default OTPVerificationView;
