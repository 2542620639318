import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  root: {},
  code: {
    width: '100%',
    paddingTop: '15px',
    background: '#272C34',
    color: '#fff',
    marginTop: '10px',
    borderRadius: '4px',
    overflowX: 'scroll'
  }
}));

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const CircularWithLabelProgress = ({ className, ...rest }) => {
  const classes = useStyles();
  const [progress, setProgress] = React.useState(10);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress(prevProgress =>
        prevProgress >= 100 ? 0 : prevProgress + 10
      );
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  var code = `<CircularProgressWithLabel value={progress} />`;
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader
          title={<Typography variant="h2">Circular with label</Typography>}
        />
        <Divider />
        <CardContent>
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgressWithLabel value={progress} />
          </Box>
          <Box className={classes.code}>
            <Container>
              <pre>
                <Typography
                  variant="subtitle2"
                  style={{ fontFamily: 'monospace', fontSize: 14 }}
                >
                  {code}
                </Typography>
              </pre>
            </Container>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

CircularWithLabelProgress.propTypes = {
  className: PropTypes.string
};

export default CircularWithLabelProgress;
