import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles
} from '@material-ui/core';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const useStyles = makeStyles(theme => ({
  root: {},
  alert: {
    '& > *': {
      '&:not(:last-child)': {
        marginRight: theme.spacing(3)
      }
    }
  }
}));

const MySwal = withReactContent(Swal);

const ToastAlerts = ({ className, ...rest }) => {
  const classes = useStyles();
  const sweetAlerts = variant => {
    const Toast = MySwal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: toast => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      }
    });

    Toast.fire({
      icon: variant,
      title: 'You clicked the button!'
    });
  };
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader title="Toast Alerts" />
        <Divider />
        <CardContent>
          <Box
            className={classes.alert}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Button variant="outlined" onClick={() => sweetAlerts('success')}>
              Success
            </Button>
            <Button variant="outlined" onClick={() => sweetAlerts('error')}>
              Error
            </Button>
            <Button variant="outlined" onClick={() => sweetAlerts('warning')}>
              Warning
            </Button>
            <Button variant="outlined" onClick={() => sweetAlerts('info')}>
              Information
            </Button>
            <Button variant="outlined" onClick={() => sweetAlerts('question')}>
              Question
            </Button>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

ToastAlerts.propTypes = {
  className: PropTypes.string
};

export default ToastAlerts;
