import React from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import BasicMap from './basic';
import StyledMap from './styled';
import GeoLocationMap from './geoLocation';
import MapDirection from './mapDirection';
import MapOverLay from './mapOverlay';
import MapKMLayer from './kmLayer';
import MapPopUpInfo from './popUpInfo';
import StreetView from './streetView';
import ClusteringMap from './clustering';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const GoogleMapComponent = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Google Map">
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <BasicMap />
          </Grid>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <StyledMap />
          </Grid>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <GeoLocationMap />
          </Grid>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <MapDirection />
          </Grid>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <MapOverLay />
          </Grid>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <MapKMLayer />
          </Grid>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <MapPopUpInfo />
          </Grid>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <StreetView />
          </Grid>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <ClusteringMap />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default GoogleMapComponent;
