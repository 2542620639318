import React from 'react';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardHeader,
  makeStyles,
  Paper,
  Grid,
  Typography
} from '@material-ui/core';
import '@fortawesome/fontawesome-free/js/all';
import '@fortawesome/fontawesome-free/css/all.css';
import solid from './Data/solid';
import regular from './Data/regular';
import brands from './Data/brands';

const useStyles = makeStyles(theme => ({
  root: {},
  gridRoot: { flexGrow: 1 },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    fontSize: 24
  }
}));

const IconComponent = () => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root)}>
      <Card>
        <CardHeader title="Solid" subheader="<i class='fas fa-ad'></i>" />
      </Card>
      <Box mt={3} mb={3}>
        <Grid container spacing={3}>
          {solid.map((val, index) => {
            return (
              <Grid item xs={3} key={index}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Paper className={classes.paper}>
                      <i className={val}></i>
                    </Paper>
                  </Grid>
                  <Grid item>
                    <Box pl={1}>
                      <Typography>{val}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Box>

      <Card>
        <CardHeader
          title="Regular"
          subheader="<i class='far fa-address-book'></i>"
        />
      </Card>
      <Box mt={3} mb={3}>
        <Grid container spacing={3}>
          {regular.map((val, index) => {
            return (
              <Grid item xs={3} key={index}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Paper className={classes.paper}>
                      <i className={val}></i>
                    </Paper>
                  </Grid>
                  <Grid item>
                    <Box pl={1}>
                      <Typography>{val}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Box>

      <Card>
        <CardHeader title="Brands" subheader="<i class='fab fa-500px'></i>" />
      </Card>
      <Box mt={3} mb={3}>
        <Grid container spacing={3}>
          {brands.map((val, index) => {
            return (
              <Grid item xs={3} key={index}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Paper className={classes.paper}>
                      <i className={val}></i>
                    </Paper>
                  </Grid>
                  <Grid item>
                    <Box pl={1}>
                      <Typography>{val}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </div>
  );
};

export default IconComponent;
