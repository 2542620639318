import React from 'react';
import {
  Box,
  Container,
  Typography,
  makeStyles,
  Button,
  Grid
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import Page from 'src/components/Page';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  image: {
    display: 'inline-block',
    maxWidth: '100%',
    width: 560
  }
}));

const NotFoundView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <Page className={classes.root} title="Internal Server Error">
      <Grid display="flex" height="100%">
        <Container maxWidth="md">
          <Box textAlign="center">
            <img
              alt="Under development"
              className={classes.image}
              src="/static/images/500.png"
            />
          </Box>
          <Typography align="center" color="textPrimary" variant="h1">
            INTERNAL SERVER ERROR
          </Typography>
          <Box mt={2} textAlign="center">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                navigate('/app', { replace: true });
              }}
            >
              Back to Dashboard
            </Button>
          </Box>
        </Container>
      </Grid>
    </Page>
  );
};

export default NotFoundView;
