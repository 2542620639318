import React from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import SimpleDialog from './simple';
import Alerts from './alerts';
import Transitions from './transitions';
import Form from './form';
import Customized from './customized';
import FullScreenDialog from './fullscreen';
import OptionalSizes from './optionalSizes';
import ResponsiveFullScreen from './responsiveFullScreen';
import ConfirmationDialog from './confirmation';
import Draggable from './draggable';
import ScrollingLongContent from './scrollingLong';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const DialogIndex = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Dialog">
      <Container maxWidth="lg">
        <Box>
          <SimpleDialog />
        </Box>
        <Box mt={3}>
          <Alerts />
        </Box>
        <Box mt={3}>
          <Transitions />
        </Box>
        <Box mt={3}>
          <Form />
        </Box>
        <Box mt={3}>
          <Customized />
        </Box>
        <Box mt={3}>
          <FullScreenDialog />
        </Box>
        <Box mt={3}>
          <OptionalSizes />
        </Box>
        <Box mt={3}>
          <ResponsiveFullScreen />
        </Box>
        <Box mt={3}>
          <ConfirmationDialog />
        </Box>
        <Box mt={3}>
          <Draggable />
        </Box>
        <Box mt={3}>
          <ScrollingLongContent />
        </Box>
      </Container>
    </Page>
  );
};

export default DialogIndex;
