import React from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import SpacingGrid from './spacing';
import BasicGrid from './basicGrid';
import GridAndBreakPoint from './gridAndBreakPoint';
import AutoLayout from './autoLayout';
import Complex from './complex';
import NestedGrid from './nested';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const GridIndex = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Grid">
      <Container maxWidth="lg">
        <Box>
          <SpacingGrid />
        </Box>
        <Box mt={3}>
          <BasicGrid />
        </Box>
        <Box mt={3}>
          <GridAndBreakPoint />
        </Box>
        <Box mt={3}>
          <AutoLayout />
        </Box>
        <Box mt={3}>
          <Complex />
        </Box>
        <Box mt={3}>
          <NestedGrid />
        </Box>
      </Container>
    </Page>
  );
};

export default GridIndex;
