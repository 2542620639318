import React from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import LinearWizard from './linear';
import LinearAlternative from './linearAlternative';
import Customized from './customized';
import NonLinear from './nonLinear';
import NonLinearAlternative from './nonLinearAlternative';
import NonLinearError from './nonLinearError';
import Vertical from './vertical';
import TextStepper from './text';
import TextWithCarousel from './textWithCarousel';
import Dots from './dots';
import Progress from './progress';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const WizardIndex = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Wizard">
      <Container maxWidth="lg">
        <Box>
          <LinearWizard />
        </Box>
        <Box mt={3}>
          <LinearAlternative />
        </Box>
        <Box mt={3}>
          <Customized />
        </Box>
        <Box mt={3}>
          <NonLinear />
        </Box>
        <Box mt={3}>
          <NonLinearAlternative />
        </Box>
        <Box mt={3}>
          <NonLinearError />
        </Box>
        <Box mt={3}>
          <Vertical />
        </Box>
        <Box mt={3}>
          <TextStepper />
        </Box>
        <Box mt={3}>
          <TextWithCarousel />
        </Box>
        <Box mt={3}>
          <Dots />
        </Box>
        <Box mt={3}>
          <Progress />
        </Box>
      </Container>
    </Page>
  );
};

export default WizardIndex;
