import { LOGIN_SUCCESSS } from 'src/Services/constants';

const initialState = {
  AuthUserData: []
};

export default function AdminApp(state = initialState, action) {
  // console.log('action', action, state);
  switch (action.type) {
    case LOGIN_SUCCESSS:
      return Object.assign({}, state, { AuthUserData: action.data });
    default:
      return state;
  }
}
