import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  makeStyles,
  Input
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  inputs: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  code: {
    width: '100%',
    paddingTop: '15px',
    background: '#272C34',
    color: '#fff',
    marginTop: '10px',
    borderRadius: '4px',
    overflowX: 'scroll'
  }
}));

const InputTextField = ({ className, ...rest }) => {
  const classes = useStyles();
  var code = `<form className={classes.root} noValidate autoComplete="off">
  <Input defaultValue="Hello world" inputProps={{ 'aria-label': 'description' }} />
  <Input placeholder="Placeholder" inputProps={{ 'aria-label': 'description' }} />
  <Input defaultValue="Disabled" disabled inputProps={{ 'aria-label': 'description' }} />
  <Input defaultValue="Error" error inputProps={{ 'aria-label': 'description' }} />
</form>`;
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader title={<Typography variant="h2">Inputs</Typography>} />
        <Divider />
        <CardContent>
          <Box mt={1} style={{ display: 'flex', justifyContent: 'center' }}>
            <form className={classes.inputs} noValidate autoComplete="off">
              <Input
                defaultValue="Hello world"
                inputProps={{ 'aria-label': 'description' }}
              />
              <Input
                placeholder="Placeholder"
                inputProps={{ 'aria-label': 'description' }}
              />
              <Input
                defaultValue="Disabled"
                disabled
                inputProps={{ 'aria-label': 'description' }}
              />
              <Input
                defaultValue="Error"
                error
                inputProps={{ 'aria-label': 'description' }}
              />
            </form>
          </Box>
          <Box className={classes.code}>
            <Container>
              <pre>
                <Typography
                  variant="subtitle2"
                  style={{ fontFamily: 'monospace', fontSize: 14 }}
                >
                  {code}
                </Typography>
              </pre>
            </Container>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

InputTextField.propTypes = {
  className: PropTypes.string
};

export default InputTextField;
