import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles
} from '@material-ui/core';
import { Pie } from 'react-chartjs-2';

const useStyles = makeStyles(theme => ({
  root: {}
}));

const PieChart = ({ className, ...rest }) => {
  const classes = useStyles();
  const data = {
    labels: ['Red', 'Blue', 'Yellow'],
    datasets: [
      {
        data: [300, 50, 100],
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56']
      }
    ]
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader title="Pie Chart" />
        <Divider />
        <CardContent>
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Pie data={data} />
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

PieChart.propTypes = {
  className: PropTypes.string
};

export default PieChart;
