import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Card,
  CardContent,
  CardHeader,
  Divider,
  LinearProgress,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  progress: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
  code: {
    width: '100%',
    paddingTop: '15px',
    background: '#272C34',
    color: '#fff',
    marginTop: '10px',
    borderRadius: '4px',
    overflowX: 'scroll'
  }
}));

const LinearDeterminateProgress = ({ className, ...rest }) => {
  const classes = useStyles();
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress(oldProgress => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  var code = `<LinearProgress variant="determinate" value={progress} />`;
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader
          title={<Typography variant="h2">Linear Determinate</Typography>}
        />
        <Divider />
        <CardContent>
          <Box
            mt={1}
            mb={3}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <div className={classes.progress}>
              <LinearProgress variant="determinate" value={progress} />
            </div>
          </Box>
          <Box className={classes.code}>
            <Container>
              <pre>
                <Typography
                  variant="subtitle2"
                  style={{ fontFamily: 'monospace', fontSize: 14 }}
                >
                  {code}
                </Typography>
              </pre>
            </Container>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

LinearDeterminateProgress.propTypes = {
  className: PropTypes.string
};

export default LinearDeterminateProgress;
