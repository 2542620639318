import React, { useState } from 'react';
import clsx from 'clsx';
import {
  Box,
  Avatar,
  Container,
  Grid,
  makeStyles,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Divider,
  Typography
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { Pagination } from '@material-ui/lab';
import Page from 'src/components/Page';
import Toolbar from './Toolbar';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import GetAppIcon from '@material-ui/icons/GetApp';
import data from './data';

const useStyles = makeStyles(theme => ({
  cardRoot: {},
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex'
  },
  statsIcon: {
    marginRight: theme.spacing(1)
  }
}));

const ProductList = ({ className, ...rest }) => {
  const classes = useStyles();
  const [search, SetSearch] = React.useState('');
  const [products] = useState(data);

  return (
    <Page className={classes.root} title="Products">
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={3}>
          <Card>
            <CardContent>
              <Box maxWidth={500}>
                <TextField
                  fullWidth
                  onChange={event => {
                    SetSearch(event.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )
                  }}
                  placeholder="Search product"
                  variant="outlined"
                />
              </Box>
            </CardContent>
          </Card>
        </Box>
        <Box mt={3}>
          <Grid container spacing={3}>
            {products
              .filter(data => {
                if (search === '') return data;
                else if (
                  data.title
                    .toString()
                    .toLowerCase()
                    .includes(search.toString().toLowerCase()) ||
                  data.description
                    .toString()
                    .toLowerCase()
                    .includes(search.toString().toLowerCase()) ||
                  data.totalDownloads
                    .toString()
                    .toLowerCase()
                    .includes(search.toString().toLowerCase())
                ) {
                  return data;
                }
                return '';
              })
              .map(product => (
                <Grid item key={product.id} lg={4} md={6} xs={12}>
                  <Card className={clsx(classes.cardRoot, className)} {...rest}>
                    <CardContent>
                      <Box display="flex" justifyContent="center" mb={3}>
                        <Avatar
                          alt="Product"
                          src={product.media}
                          variant="square"
                        />
                      </Box>
                      <Typography
                        align="center"
                        color="textPrimary"
                        gutterBottom
                        variant="h4"
                      >
                        {product.title}
                      </Typography>
                      <Typography
                        align="center"
                        color="textPrimary"
                        variant="body1"
                      >
                        {product.description}
                      </Typography>
                    </CardContent>
                    <Box flexGrow={1} />
                    <Divider />
                    <Box p={2}>
                      <Grid container justify="space-between" spacing={2}>
                        <Grid className={classes.statsItem} item>
                          <AccessTimeIcon
                            className={classes.statsIcon}
                            color="action"
                          />
                          <Typography
                            color="textSecondary"
                            display="inline"
                            variant="body2"
                          >
                            Updated 2hr ago
                          </Typography>
                        </Grid>
                        <Grid className={classes.statsItem} item>
                          <GetAppIcon
                            className={classes.statsIcon}
                            color="action"
                          />
                          <Typography
                            color="textSecondary"
                            display="inline"
                            variant="body2"
                          >
                            {product.totalDownloads} Downloads
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </Box>
        <Box mt={3} display="flex" justifyContent="center">
          <Pagination color="primary" count={3} size="small" />
        </Box>
      </Container>
    </Page>
  );
};

export default ProductList;
