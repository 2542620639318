export default [
  'fab fa-500px',
  'fab fa-accessible-icon',
  'fab fa-accusoft',
  'fab fa-acquisitions-incorporated',
  'fab fa-adn',
  'fab fa-adobe',
  'fab fa-adversal',
  'fab fa-affiliatetheme',
  'fab fa-airbnb',
  'fab fa-algolia',
  'fab fa-alipay',
  'fab fa-amazon',
  'fab fa-amazon-pay',
  'fab fa-amilia',
  'fab fa-android',
  'fab fa-angellist',
  'fab fa-angrycreative',
  'fab fa-angular',
  'fab fa-app-store',
  'fab fa-app-store-ios',
  'fab fa-apper',
  'fab fa-apple',
  'fab fa-apple-pay',
  'fab fa-artstation',
  'fab fa-asymmetrik',
  'fab fa-atlassian',
  'fab fa-audible',
  'fab fa-autoprefixer',
  'fab fa-avianex',
  'fab fa-aviato',
  'fab fa-aws',
  'fab fa-bandcamp',
  'fab fa-battle-net',
  'fab fa-behance',
  'fab fa-behance-square',
  'fab fa-bimobject',
  'fab fa-bitbucket',
  'fab fa-bitcoin',
  'fab fa-bity',
  'fab fa-black-tie',
  'fab fa-blackberry',
  'fab fa-blogger',
  'fab fa-blogger-b',
  'fab fa-bluetooth',
  'fab fa-bluetooth-b',
  'fab fa-bootstrap',
  'fab fa-btc',
  'fab fa-buffer',
  'fab fa-buromobelexperte',
  'fab fa-buy-n-large',
  'fab fa-buysellads',
  'fab fa-canadian-maple-leaf',
  'fab fa-cc-amazon-pay',
  'fab fa-cc-amex',
  'fab fa-cc-apple-pay',
  'fab fa-cc-diners-club',
  'fab fa-cc-discover',
  'fab fa-cc-jcb',
  'fab fa-cc-mastercard',
  'fab fa-cc-paypal',
  'fab fa-cc-stripe',
  'fab fa-cc-visa',
  'fab fa-centercode',
  'fab fa-centos',
  'fab fa-chrome',
  'fab fa-chromecast',
  'fab fa-cloudscale',
  'fab fa-cloudsmith',
  'fab fa-cloudversify',
  'fab fa-codepen',
  'fab fa-codiepie',
  'fab fa-confluence',
  'fab fa-connectdevelop',
  'fab fa-contao',
  'fab fa-cotton-bureau',
  'fab fa-cpanel',
  'fab fa-creative-commons',
  'fab fa-creative-commons-by',
  'fab fa-creative-commons-nc',
  'fab fa-creative-commons-nc-eu',
  'fab fa-creative-commons-nc-jp',
  'fab fa-creative-commons-nd',
  'fab fa-creative-commons-pd',
  'fab fa-creative-commons-pd-alt',
  'fab fa-creative-commons-remix',
  'fab fa-creative-commons-sa',
  'fab fa-creative-commons-sampling',
  'fab fa-creative-commons-sampling-plus',
  'fab fa-creative-commons-share',
  'fab fa-creative-commons-zero',
  'fab fa-critical-role',
  'fab fa-css3',
  'fab fa-css3-alt',
  'fab fa-cuttlefish',
  'fab fa-d-and-d',
  'fab fa-d-and-d-beyond',
  'fab fa-dailymotion',
  'fab fa-dashcube',
  'fab fa-delicious',
  'fab fa-deploydog',
  'fab fa-deskpro',
  'fab fa-dev',
  'fab fa-deviantart',
  'fab fa-dhl',
  'fab fa-diaspora',
  'fab fa-digg',
  'fab fa-digital-ocean',
  'fab fa-discord',
  'fab fa-discourse',
  'fab fa-dochub',
  'fab fa-docker',
  'fab fa-draft2digital',
  'fab fa-dribbble',
  'fab fa-dribbble-square',
  'fab fa-dropbox',
  'fab fa-drupal',
  'fab fa-dyalog',
  'fab fa-earlybirds',
  'fab fa-ebay',
  'fab fa-edge',
  'fab fa-elementor',
  'fab fa-ello',
  'fab fa-ember',
  'fab fa-empire',
  'fab fa-envira',
  'fab fa-erlang',
  'fab fa-ethereum',
  'fab fa-etsy',
  'fab fa-evernote',
  'fab fa-expeditedssl',
  'fab fa-facebook',
  'fab fa-facebook-f',
  'fab fa-facebook-messenger',
  'fab fa-facebook-square',
  'fab fa-fantasy-flight-games',
  'fab fa-fedex',
  'fab fa-fedora',
  'fab fa-figma',
  'fab fa-firefox',
  'fab fa-firefox-browser',
  'fab fa-first-order',
  'fab fa-first-order-alt',
  'fab fa-firstdraft',
  'fab fa-flickr',
  'fab fa-flipboard',
  'fab fa-fly',
  'fab fa-font-awesome',
  'fab fa-font-awesome-alt',
  'fab fa-font-awesome-flag',
  'fab fa-fonticons',
  'fab fa-fonticons-fi',
  'fab fa-fort-awesome',
  'fab fa-fort-awesome-alt',
  'fab fa-forumbee',
  'fab fa-foursquare',
  'fab fa-free-code-camp',
  'fab fa-freebsd',
  'fab fa-fulcrum',
  'fab fa-galactic-republic',
  'fab fa-galactic-senate',
  'fab fa-get-pocket',
  'fab fa-gg',
  'fab fa-gg-circle',
  'fab fa-git',
  'fab fa-git-alt',
  'fab fa-git-square',
  'fab fa-github',
  'fab fa-github-alt',
  'fab fa-github-square',
  'fab fa-gitkraken',
  'fab fa-gitlab',
  'fab fa-gitter',
  'fab fa-glide',
  'fab fa-glide-g',
  'fab fa-gofore',
  'fab fa-goodreads',
  'fab fa-goodreads-g',
  'fab fa-google',
  'fab fa-google-drive',
  'fab fa-google-play',
  'fab fa-google-plus',
  'fab fa-google-plus-g',
  'fab fa-google-plus-square',
  'fab fa-google-wallet',
  'fab fa-gratipay',
  'fab fa-grav',
  'fab fa-gripfire',
  'fab fa-grunt',
  'fab fa-gulp',
  'fab fa-hacker-news',
  'fab fa-hacker-news-square',
  'fab fa-hackerrank',
  'fab fa-hips',
  'fab fa-hire-a-helper',
  'fab fa-hooli',
  'fab fa-hornbill',
  'fab fa-hotjar',
  'fab fa-houzz',
  'fab fa-html5',
  'fab fa-hubspot',
  'fab fa-ideal',
  'fab fa-imdb',
  'fab fa-instagram',
  'fab fa-instagram-square',
  'fab fa-intercom',
  'fab fa-internet-explorer',
  'fab fa-invision',
  'fab fa-ioxhost',
  'fab fa-itch-io',
  'fab fa-itunes',
  'fab fa-itunes-note',
  'fab fa-java',
  'fab fa-jedi-order',
  'fab fa-jenkins',
  'fab fa-jira',
  'fab fa-joget',
  'fab fa-joomla',
  'fab fa-js',
  'fab fa-js-square',
  'fab fa-jsfiddle',
  'fab fa-kaggle',
  'fab fa-keybase',
  'fab fa-keycdn',
  'fab fa-kickstarter',
  'fab fa-kickstarter-k',
  'fab fa-korvue',
  'fab fa-laravel',
  'fab fa-lastfm',
  'fab fa-lastfm-square',
  'fab fa-leanpub',
  'fab fa-less',
  'fab fa-line',
  'fab fa-linkedin',
  'fab fa-linkedin-in',
  'fab fa-linode',
  'fab fa-linux',
  'fab fa-lyft',
  'fab fa-magento',
  'fab fa-mailchimp',
  'fab fa-mandalorian',
  'fab fa-markdown',
  'fab fa-mastodon',
  'fab fa-maxcdn',
  'fab fa-mdb',
  'fab fa-medapps',
  'fab fa-medium',
  'fab fa-medium-m',
  'fab fa-medrt',
  'fab fa-meetup',
  'fab fa-megaport',
  'fab fa-mendeley',
  'fab fa-microblog',
  'fab fa-microsoft',
  'fab fa-mix',
  'fab fa-mixcloud',
  'fab fa-mixer',
  'fab fa-mizuni',
  'fab fa-modx',
  'fab fa-monero',
  'fab fa-napster',
  'fab fa-neos',
  'fab fa-nimblr',
  'fab fa-node',
  'fab fa-node-js',
  'fab fa-npm',
  'fab fa-ns8',
  'fab fa-nutritionix',
  'fab fa-odnoklassniki',
  'fab fa-odnoklassniki-square',
  'fab fa-old-republic',
  'fab fa-opencart',
  'fab fa-openid',
  'fab fa-opera',
  'fab fa-optin-monster',
  'fab fa-orcid',
  'fab fa-osi',
  'fab fa-page4',
  'fab fa-pagelines',
  'fab fa-palfed',
  'fab fa-patreon',
  'fab fa-paypal',
  'fab fa-penny-arcade',
  'fab fa-periscope',
  'fab fa-phabricator',
  'fab fa-phoenix-framework',
  'fab fa-phoenix-squadron',
  'fab fa-php',
  'fab fa-pied-piper',
  'fab fa-pied-piper-alt',
  'fab fa-pied-piper-hat',
  'fab fa-pied-piper-pp',
  'fab fa-pied-piper-square',
  'fab fa-pinterest',
  'fab fa-pinterest-p',
  'fab fa-pinterest-square',
  'fab fa-playstation',
  'fab fa-product-hunt',
  'fab fa-pushed',
  'fab fa-python',
  'fab fa-qq',
  'fab fa-quinscape',
  'fab fa-quora',
  'fab fa-r-project',
  'fab fa-raspberry-pi',
  'fab fa-ravelry',
  'fab fa-react',
  'fab fa-reacteurope',
  'fab fa-readme',
  'fab fa-rebel',
  'fab fa-red-river',
  'fab fa-reddit',
  'fab fa-reddit-alien',
  'fab fa-reddit-square',
  'fab fa-redhat',
  'fab fa-renren',
  'fab fa-replyd',
  'fab fa-researchgate',
  'fab fa-resolving',
  'fab fa-rev',
  'fab fa-rocketchat',
  'fab fa-rockrms',
  'fab fa-safari',
  'fab fa-salesforce',
  'fab fa-sass',
  'fab fa-schlix',
  'fab fa-scribd',
  'fab fa-searchengin',
  'fab fa-sellcast',
  'fab fa-sellsy',
  'fab fa-servicestack',
  'fab fa-shirtsinbulk',
  'fab fa-shopify',
  'fab fa-shopware',
  'fab fa-simplybuilt',
  'fab fa-sistrix',
  'fab fa-sith',
  'fab fa-sketch',
  'fab fa-skyatlas',
  'fab fa-skype',
  'fab fa-slack',
  'fab fa-slack-hash',
  'fab fa-slideshare',
  'fab fa-snapchat',
  'fab fa-snapchat-ghost',
  'fab fa-snapchat-square',
  'fab fa-soundcloud',
  'fab fa-sourcetree',
  'fab fa-speakap',
  'fab fa-speaker-deck',
  'fab fa-spotify',
  'fab fa-squarespace',
  'fab fa-stack-exchange',
  'fab fa-stack-overflow',
  'fab fa-stackpath',
  'fab fa-staylinked',
  'fab fa-steam',
  'fab fa-steam-square',
  'fab fa-steam-symbol',
  'fab fa-sticker-mule',
  'fab fa-strava',
  'fab fa-stripe',
  'fab fa-stripe-s',
  'fab fa-studiovinari',
  'fab fa-stumbleupon',
  'fab fa-stumbleupon-circle',
  'fab fa-superpowers',
  'fab fa-supple',
  'fab fa-suse',
  'fab fa-swift',
  'fab fa-symfony',
  'fab fa-teamspeak',
  'fab fa-telegram',
  'fab fa-telegram-plane',
  'fab fa-tencent-weibo',
  'fab fa-the-red-yeti',
  'fab fa-themeco',
  'fab fa-themeisle',
  'fab fa-think-peaks',
  'fab fa-trade-federation',
  'fab fa-trello',
  'fab fa-tripadvisor',
  'fab fa-tumblr',
  'fab fa-tumblr-square',
  'fab fa-twitch',
  'fab fa-twitter',
  'fab fa-twitter-square',
  'fab fa-typo3',
  'fab fa-uber',
  'fab fa-ubuntu',
  'fab fa-uikit',
  'fab fa-umbraco',
  'fab fa-uniregistry',
  'fab fa-unity',
  'fab fa-untappd',
  'fab fa-ups',
  'fab fa-usb',
  'fab fa-usps',
  'fab fa-ussunnah',
  'fab fa-vaadin',
  'fab fa-viacoin',
  'fab fa-viadeo',
  'fab fa-viadeo-square',
  'fab fa-viber',
  'fab fa-vimeo',
  'fab fa-vimeo-square',
  'fab fa-vimeo-v',
  'fab fa-vine',
  'fab fa-vk',
  'fab fa-vnv',
  'fab fa-vuejs',
  'fab fa-waze',
  'fab fa-weebly',
  'fab fa-weibo',
  'fab fa-weixin',
  'fab fa-whatsapp',
  'fab fa-whatsapp-square',
  'fab fa-whmcs',
  'fab fa-wikipedia-w',
  'fab fa-windows',
  'fab fa-wix',
  'fab fa-wizards-of-the-coast',
  'fab fa-wolf-pack-battalion',
  'fab fa-wordpress',
  'fab fa-wordpress-simple',
  'fab fa-wpbeginner',
  'fab fa-wpexplorer',
  'fab fa-wpforms',
  'fab fa-wpressr',
  'fab fa-xbox',
  'fab fa-xing',
  'fab fa-xing-square',
  'fab fa-y-combinator',
  'fab fa-yahoo',
  'fab fa-yammer',
  'fab fa-yandex',
  'fab fa-yandex-international',
  'fab fa-yarn',
  'fab fa-yelp',
  'fab fa-yoast',
  'fab fa-youtube',
  'fab fa-youtube-square',
  'fab fa-zhihu'
];
