import React from 'react';
import CKEditor from 'react-ckeditor-component';

let config = {
  toolbarGroups: [
    { name: 'document', groups: ['mode', 'document', 'doctools'] },
    {
      name: 'editing',
      groups: ['find', 'selection', 'spellchecker', 'editing']
    },
    { name: 'forms', groups: ['forms'] },
    { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
    {
      name: 'paragraph',
      groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph']
    },
    '/',
    { name: 'links', groups: ['links'] },
    { name: 'insert', groups: ['insert'] },
    { name: 'styles', groups: ['styles'] },
    { name: 'colors', groups: ['colors'] },
    { name: 'tools', groups: ['tools'] },
    '/',
    { name: 'clipboard', groups: ['clipboard', 'undo'] },
    { name: 'others', groups: ['others'] },
    { name: 'about', groups: ['about'] }
  ],
  removeButtons:
    'Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Find,SelectAll,Scayt,Replace,Form,Checkbox,Textarea,Select,Button,ImageButton,HiddenField,CreateDiv,BidiLtr,BidiRtl,Language,Flash,Smiley,SpecialChar,PageBreak,Iframe,Anchor,ShowBlocks,About,CopyFormatting,Undo,Redo',
  fontSize_sizes: '16/16px;24/24px;48/48px;',
  font_names:
    'Arial/Arial, Helvetica, sans-serif;' +
    'Times New Roman/Times New Roman, Times, serif;' +
    'Verdana',
  allowedContent: true
};

const CKEDitorComponent = props => {
  const [html, setHtml] = React.useState({});

  const onChange = e => {
    var thtml = e.editor.getData();
    console.log('this', this);
    setHtml(thtml);
  };

  const onBlur = e => {
    console.log('onBlur', e);
  };

  const AfterPaste = e => {
    console.log('afterPaste', e);
  };

  return (
    <CKEditor
      activeClass="p10"
      config={config}
      content={html}
      events={{
        blur: onBlur,
        afterPaste: AfterPaste,
        change: onChange
      }}
    />
  );
};

export default CKEDitorComponent;
