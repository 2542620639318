import React from 'react';
import {
  VerticalTimeline,
  VerticalTimelineElement
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import WorkIcon from '@material-ui/icons/Work';
import SchoolIcon from '@material-ui/icons/School';
import StarIcon from '@material-ui/icons/Star';
import { Helmet } from 'react-helmet';
import { Typography } from '@material-ui/core';

const TimeLine = () => {
  return (
    <div>
      <Helmet>
        <title>Timeline</title>
      </Helmet>
      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
          date="2011 - Present"
          iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          icon={<WorkIcon />}
        >
          <Typography variant="h3" className="vertical-timeline-element-title">
            Creative Director
          </Typography>
          <Typography
            variant="h4"
            className="vertical-timeline-element-subtitle"
          >
            Miami, FL
          </Typography>
          <Typography>
            Creative Direction, User Experience, Visual Design, Project
            Management, Team Leading
          </Typography>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2010 - 2011"
          iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          icon={<WorkIcon />}
        >
          <Typography variant="h3" className="vertical-timeline-element-title">
            Art Director
          </Typography>
          <Typography
            variant="h4"
            className="vertical-timeline-element-subtitle"
          >
            San Francisco, CA
          </Typography>
          <Typography>
            Creative Direction, User Experience, Visual Design, SEO, Online
            Marketing
          </Typography>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2008 - 2010"
          iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          icon={<WorkIcon />}
        >
          <Typography variant="h3" className="vertical-timeline-element-title">
            Web Designer
          </Typography>
          <Typography
            variant="h4"
            className="vertical-timeline-element-subtitle"
          >
            Los Angeles, CA
          </Typography>
          <Typography>User Experience, Visual Design</Typography>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2006 - 2008"
          iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          icon={<WorkIcon />}
        >
          <Typography variant="h3" className="vertical-timeline-element-title">
            Web Designer
          </Typography>
          <Typography
            variant="h4"
            className="vertical-timeline-element-subtitle"
          >
            San Francisco, CA
          </Typography>
          <Typography>User Experience, Visual Design</Typography>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="April 2013"
          iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
          icon={<SchoolIcon />}
        >
          <Typography variant="h3" className="vertical-timeline-element-title">
            Content Marketing for Web, Mobile and Social Media
          </Typography>
          <Typography
            variant="h4"
            className="vertical-timeline-element-subtitle"
          >
            Online Course
          </Typography>
          <Typography>Strategy, Social Media</Typography>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="November 2012"
          iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
          icon={<SchoolIcon />}
        >
          <Typography variant="h3" className="vertical-timeline-element-title">
            Agile Development Scrum Master
          </Typography>
          <Typography
            variant="h4"
            className="vertical-timeline-element-subtitle"
          >
            Certification
          </Typography>
          <Typography>
            Creative Direction, User Experience, Visual Design
          </Typography>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2002 - 2006"
          iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
          icon={<SchoolIcon />}
        >
          <Typography variant="h3" className="vertical-timeline-element-title">
            Bachelor of Science in Interactive Digital Media Visual Imaging
          </Typography>
          <Typography
            variant="h4"
            className="vertical-timeline-element-subtitle"
          >
            Bachelor Degree
          </Typography>
          <Typography>Creative Direction, Visual Design</Typography>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
          icon={<StarIcon />}
        />
      </VerticalTimeline>
    </div>
  );
};

export default TimeLine;
