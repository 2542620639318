import { v4 as uuid } from 'uuid';

export default [
  {
    id: uuid()
  },
  {
    id: uuid()
  },
  {
    id: uuid()
  }
  // {
  //   id: uuid()
  // }
];
