import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles
} from '@material-ui/core';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const useStyles = makeStyles(theme => ({
  root: {}
}));

const MySwal = withReactContent(Swal);

const ImageWithMsgAlert = ({ className, ...rest }) => {
  const classes = useStyles();
  const sweetAlerts = () => {
    MySwal.fire({
      title: 'Sweet!',
      text: 'Modal with a custom image.',
      imageUrl: 'https://jumbo.g-axon.work/images/dashboard/crypto/crypto2.jpg',
      imageWidth: 600,
      imageHeight: 400,
      imageAlt: 'Custom image'
    });
  };
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader title="Image with message" />
        <Divider />
        <CardContent>
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Button variant="outlined" color="primary" onClick={sweetAlerts}>
              Click me
            </Button>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

ImageWithMsgAlert.propTypes = {
  className: PropTypes.string
};

export default ImageWithMsgAlert;
