import React from 'react';
import clsx from 'clsx';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles({
  root: {}
});

const Password = ({ className, ...rest }) => {
  const classes = useStyles();
  return (
    <Formik
      initialValues={{
        currentPassword: '',
        password: '',
        changepassword: ''
      }}
      validationSchema={Yup.object().shape({
        currentPassword: Yup.string()
          .required('Please Enter Current is required')
          .min(8, 'Atleast 8 character needed'),
        password: Yup.string()
          .required('Password is required')
          .min(8, 'Atleast 8 character needed'),
        changepassword: Yup.string()
          .required('Confirm Password is required')
          .min(8, 'Atleast 8 character needed')
          .when('password', {
            is: val => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
              [Yup.ref('password')],
              'Password and Confirm Password Not Matched !'
            )
          })
      })}
      onSubmit={data => {
        console.log('data', data);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
            <CardHeader
              title={
                <Typography color="textPrimary" variant="h2">
                  Update password
                </Typography>
              }
            />
            <Divider />
            <CardContent>
              <TextField
                error={Boolean(
                  touched.currentPassword && errors.currentPassword
                )}
                fullWidth
                helperText={touched.currentPassword && errors.currentPassword}
                label="Current Password"
                margin="normal"
                name="currentPassword"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.currentPassword}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                label="New Password"
                margin="normal"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.password}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.changepassword && errors.changepassword)}
                fullWidth
                helperText={touched.changepassword && errors.changepassword}
                label="Confirm Password"
                margin="normal"
                name="changepassword"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.changepassword}
                variant="outlined"
              />
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button color="primary" type="submit" variant="contained">
                Update
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default Password;
