import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles
} from '@material-ui/core';
import { GoogleMap, withGoogleMap } from 'react-google-maps';
import InfoBox from 'react-google-maps/lib/components/addons/InfoBox';

const google = window.google;
const fancyMapStyles = {};
const useStyles = makeStyles(theme => ({
  root: {}
}));

const StyledMapExampleGoogleMap = withGoogleMap(props => (
  <GoogleMap
    defaultZoom={11}
    defaultCenter={props.center}
    defaultOptions={{ styles: fancyMapStyles }}
  >
    <InfoBox
      defaultPosition={props.center}
      options={{ closeBoxURL: ``, enableEventPropagation: true }}
    >
      <Box
        style={{ backgroundColor: `yellow`, opacity: 0.75, padding: `20px` }}
        onClick={props.onClickFromChildrenOfInfoBox}
      >
        <Box fontSize={16} color="#08233B">
          Taipei
        </Box>
      </Box>
    </InfoBox>
  </GoogleMap>
));

const StyledMapComponent = ({ className, ...rest }) => {
  const classes = useStyles();
  const handleClickFromChildrenOfInfoBox = e => {
    console.log(e);
  };
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader title="Styled Map" />
        <Divider />
        <CardContent>
          <StyledMapExampleGoogleMap
            loadingElement={<Box height={1} />}
            containerElement={<Box height={{ xs: 300, sm: 400 }} />}
            mapElement={<Box height={1} />}
            center={new google.maps.LatLng(44.8799929, 21.3190073)}
            onClickFromChildrenOfInfoBox={handleClickFromChildrenOfInfoBox}
          />
        </CardContent>
      </Card>
    </div>
  );
};

StyledMapComponent.propTypes = {
  className: PropTypes.string
};

export default StyledMapComponent;
