import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  TextField,
  Container,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  text: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch'
    }
  },
  code: {
    width: '100%',
    paddingTop: '15px',
    background: '#272C34',
    color: '#fff',
    marginTop: '10px',
    borderRadius: '4px',
    overflowX: 'scroll'
  }
}));

const SimpleTextField = ({ className, ...rest }) => {
  const classes = useStyles();
  var code = `<form className={classes.root} noValidate autoComplete="off">
  <TextField id="standard-basic" label="Standard" />
  <TextField id="filled-basic" label="Filled" variant="filled" />
  <TextField id="outlined-basic" label="Outlined" variant="outlined" />
</form>`;
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader title={<Typography variant="h2">Text Field</Typography>} />
        <Divider />
        <CardContent>
          <Box mt={1}>
            <div className={classes.button} style={{ textAlign: 'center' }}>
              <form className={classes.text} noValidate autoComplete="off">
                <TextField id="standard-basic" label="Standard" />
                <TextField id="filled-basic" label="Filled" variant="filled" />
                <TextField
                  id="outlined-basic"
                  label="Outlined"
                  variant="outlined"
                />
              </form>
            </div>
          </Box>
          <Box className={classes.code}>
            <Container>
              <pre>
                <Typography
                  variant="subtitle2"
                  style={{ fontFamily: 'monospace', fontSize: 14 }}
                >
                  {code}
                </Typography>
              </pre>
            </Container>
          </Box>
          <Box mt={1}>
            <Typography variant="subtitle1">
              The <b>TextField</b> wrapper component is a complete form control
              including a label, input and help text. It supports standard,
              outlined and filled styling.
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

SimpleTextField.propTypes = {
  className: PropTypes.string
};

export default SimpleTextField;
