import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  makeStyles,
  Slider
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  code: {
    width: '100%',
    paddingTop: '15px',
    background: '#272C34',
    color: '#fff',
    marginTop: '10px',
    borderRadius: '4px',
    overflowX: 'scroll'
  }
}));

function valueLabelFormat(value) {
  const [coefficient, exponent] = value
    .toExponential()
    .split('e')
    .map(item => Number(item));
  return `${Math.round(coefficient)}e^${exponent}`;
}

const OutlinedButtons = ({ className, ...rest }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  var code = `<Typography id="non-linear-slider" gutterBottom>
  Temperature range
</Typography>
<Slider
  value={value}
  min={0}
  step={0.1}
  max={6}
  scale={(x) => x ** 10}
  getAriaValueText={valueLabelFormat}
  valueLabelFormat={valueLabelFormat}
  onChange={handleChange}
  valueLabelDisplay="auto"
  aria-labelledby="non-linear-slider"
/>`;
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader
          title={<Typography variant="h2">Non-linear scale</Typography>}
        />
        <Divider />
        <CardContent>
          <Box mt={1} style={{ display: 'flex', justifyContent: 'center' }}>
            <div>
              <Typography id="non-linear-slider" gutterBottom>
                Temperature range
              </Typography>
              <Slider
                value={value}
                min={0}
                step={0.1}
                max={6}
                scale={x => x ** 10}
                getAriaValueText={valueLabelFormat}
                valueLabelFormat={valueLabelFormat}
                onChange={handleChange}
                valueLabelDisplay="auto"
                aria-labelledby="non-linear-slider"
              />
            </div>
          </Box>
          <Box className={classes.code}>
            <Container>
              <pre>
                <Typography
                  variant="subtitle2"
                  style={{ fontFamily: 'monospace', fontSize: 14 }}
                >
                  {code}
                </Typography>
              </pre>
            </Container>
          </Box>
          <Box mt={1}>
            <Typography variant="subtitle1">
              You can use the <b>scale</b> prop to represent the <b>value</b> on
              a different scale. For instance, in the following demo, the value
              x represents the power of 10^x.
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

OutlinedButtons.propTypes = {
  className: PropTypes.string
};

export default OutlinedButtons;
