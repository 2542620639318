import React from 'react';
import clsx from 'clsx';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles
} from '@material-ui/core';
import { VectorMap } from 'react-jvectormap';
import './vector.scss';

const useStyles = makeStyles(theme => ({
  root: {}
}));

const EuropeMapComponent = ({ className, ...rest }) => {
  const classes = useStyles();
  return (
    <div
      className={clsx(classes.root, className)}
      style={{
        height: 500
      }}
    >
      <Card>
        <CardHeader title="Europe" />
        <Divider />
        <CardContent>
          <VectorMap
            map={'europe_mill'}
            backgroundColor=""
            markerStyle={{
              initial: {
                fill: '#FFFF',
                stroke: '#383f47'
              }
            }}
            regionStyle={{
              initial: {
                fill: '#128da7'
              },
              hover: {
                fill: '#A0D1DC'
              }
            }}
            containerStyle={{
              width: '100%',
              height: '400px'
            }}
            containerClassName="map"
          />
        </CardContent>
      </Card>
    </div>
  );
};

export default EuropeMapComponent;
