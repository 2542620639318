import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  CardMedia,
  Divider,
  Typography,
  makeStyles,
  useTheme,
  Grid
} from '@material-ui/core';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import data from './Data/data';

const useStyles = makeStyles(theme => ({
  root: {},
  cardRoot: { display: 'flex' },
  cardbackground: {
    background: theme.palette.background.dark
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: '1 0 auto'
  },
  cover: {
    width: 151
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  playIcon: {
    height: 38,
    width: 38
  }
}));

const OutlinedButtons = ({ className, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card className={classes.cardbackground}>
        <CardHeader title={<Typography variant="h2">UI Controls</Typography>} />
        <Divider />
        <CardContent className={classes.cardbackground}>
          <Box mt={1} mb={3}>
            <Grid container justify="center" spacing={3}>
              {data.map(val => {
                return (
                  <Grid item xs={12} sm={3} key={val.id}>
                    <Card className={classes.cardRoot}>
                      <div className={classes.details}>
                        <CardContent className={classes.content}>
                          <Typography component="h5" variant="h5">
                            Live From Space
                          </Typography>
                          <Typography variant="subtitle1" color="textSecondary">
                            Mac Miller
                          </Typography>
                        </CardContent>
                        <div className={classes.controls}>
                          <IconButton aria-label="previous">
                            {theme.direction === 'rtl' ? (
                              <SkipNextIcon />
                            ) : (
                              <SkipPreviousIcon />
                            )}
                          </IconButton>
                          <IconButton aria-label="play/pause">
                            <PlayArrowIcon className={classes.playIcon} />
                          </IconButton>
                          <IconButton aria-label="next">
                            {theme.direction === 'rtl' ? (
                              <SkipPreviousIcon />
                            ) : (
                              <SkipNextIcon />
                            )}
                          </IconButton>
                        </div>
                      </div>
                      <CardMedia
                        className={classes.cover}
                        image="https://material-ui.com/static/images/cards/live-from-space.jpg"
                        title="Live from space album cover"
                      />
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Box>

          <Box mt={1}>
            <Typography variant="subtitle1">
              Supplemental actions within the card are explicitly called out
              using icons, text, and UI controls, typically placed at the bottom
              of the card.
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

OutlinedButtons.propTypes = {
  className: PropTypes.string
};

export default OutlinedButtons;
