export default [
  'ri-tv-line',
  'ri-tv-fill',
  'ri-tv-2-line',
  'ri-tv-2-fill',
  'ri-computer-line',
  'ri-computer-fill',
  'ri-mac-line',
  'ri-mac-fill',
  'ri-macbook-line',
  'ri-macbook-fill',
  'ri-cellphone-line',
  'ri-cellphone-fill',
  'ri-smartphone-line',
  'ri-smartphone-fill',
  'ri-tablet-line',
  'ri-tablet-fill',
  'ri-device-line',
  'ri-device-fill',
  'ri-phone-line',
  'ri-phone-fill',
  'ri-database-line',
  'ri-database-fill',
  'ri-database-2-line',
  'ri-database-2-fill',
  'ri-server-line',
  'ri-server-fill',
  'ri-hard-drive-line',
  'ri-hard-drive-fill',
  'ri-hard-drive-2-line',
  'ri-hard-drive-2-fill',
  'ri-install-line',
  'ri-install-fill',
  'ri-uninstall-line',
  'ri-uninstall-fill',
  'ri-save-line',
  'ri-save-fill',
  'ri-save-2-line',
  'ri-save-2-fill',
  'ri-save-3-line',
  'ri-save-3-fill',
  'ri-sd-card-line',
  'ri-sd-card-fill',
  'ri-sd-card-mini-line',
  'ri-sd-card-mini-fill',
  'ri-sim-card-line',
  'ri-sim-card-fill',
  'ri-sim-card-2-line',
  'ri-sim-card-2-fill',
  'ri-dual-sim-1-line',
  'ri-dual-sim-1-fill',
  'ri-dual-sim-2-line',
  'ri-dual-sim-2-fill',
  'ri-u-disk-line',
  'ri-u-disk-fill',
  'ri-battery-line',
  'ri-battery-fill',
  'ri-battery-charge-line',
  'ri-battery-charge-fill',
  'ri-battery-low-line',
  'ri-battery-low-fill',
  'ri-battery-2-line',
  'ri-battery-2-fill',
  'ri-battery-2-charge-line',
  'ri-battery-2-charge-fill',
  'ri-battery-saver-line',
  'ri-battery-saver-fill',
  'ri-battery-share-line',
  'ri-battery-share-fill',
  'ri-cast-line',
  'ri-cast-fill',
  'ri-airplay-line',
  'ri-airplay-fill',
  'ri-cpu-line',
  'ri-cpu-fill',
  'ri-gradienter-line',
  'ri-gradienter-fill',
  'ri-keyboard-line',
  'ri-keyboard-fill',
  'ri-keyboard-box-line',
  'ri-keyboard-box-fill',
  'ri-mouse-line',
  'ri-mouse-fill',
  'ri-sensor-line',
  'ri-sensor-fill',
  'ri-router-line',
  'ri-router-fill',
  'ri-radar-line',
  'ri-radar-fill',
  'ri-gamepad-line',
  'ri-gamepad-fill',
  'ri-remote-control-line',
  'ri-remote-control-fill',
  'ri-remote-control-2-line',
  'ri-remote-control-2-fill',
  'ri-device-recover-line',
  'ri-device-recover-fill',
  'ri-hotspot-line',
  'ri-hotspot-fill',
  'ri-phone-find-line',
  'ri-phone-find-fill',
  'ri-phone-lock-line',
  'ri-phone-lock-fill',
  'ri-rotate-lock-line',
  'ri-rotate-lock-fill',
  'ri-restart-line',
  'ri-restart-fill',
  'ri-shut-down-line',
  'ri-shut-down-fill',
  'ri-fingerprint-line',
  'ri-fingerprint-fill',
  'ri-fingerprint-2-line',
  'ri-fingerprint-2-fill',
  'ri-barcode-line',
  'ri-barcode-fill',
  'ri-barcode-box-line',
  'ri-barcode-box-fill',
  'ri-qr-code-line',
  'ri-qr-code-fill',
  'ri-qr-scan-line',
  'ri-qr-scan-fill',
  'ri-qr-scan-2-line',
  'ri-qr-scan-2-fill',
  'ri-scan-line',
  'ri-scan-fill',
  'ri-scan-2-line',
  'ri-scan-2-fill',
  'ri-rss-line',
  'ri-rss-fill',
  'ri-gps-line',
  'ri-gps-fill',
  'ri-base-station-line',
  'ri-base-station-fill',
  'ri-bluetooth-line',
  'ri-bluetooth-fill',
  'ri-bluetooth-connect-line',
  'ri-bluetooth-connect-fill',
  'ri-wifi-line',
  'ri-wifi-fill',
  'ri-wifi-off-line',
  'ri-wifi-off-fill',
  'ri-signal-wifi-line',
  'ri-signal-wifi-fill',
  'ri-signal-wifi-1-line',
  'ri-signal-wifi-1-fill',
  'ri-signal-wifi-2-line',
  'ri-signal-wifi-2-fill',
  'ri-signal-wifi-3-line',
  'ri-signal-wifi-3-fill',
  'ri-signal-wifi-error-line',
  'ri-signal-wifi-error-fill',
  'ri-signal-wifi-off-line',
  'ri-signal-wifi-off-fill',
  'ri-wireless-charging-line',
  'ri-wireless-charging-fill'
];
