export default [
  'far fa-address-book',
  'far fa-address-card',
  'far fa-angry',
  'far fa-arrow-alt-circle-down',
  'far fa-arrow-alt-circle-left',
  'far fa-arrow-alt-circle-right',
  'far fa-arrow-alt-circle-up',
  'far fa-bell',
  'far fa-bell-slash',
  'far fa-bookmark',
  'far fa-building',
  'far fa-calendar',
  'far fa-calendar-alt',
  'far fa-calendar-check',
  'far fa-calendar-minus',
  'far fa-calendar-plus',
  'far fa-calendar-times',
  'far fa-caret-square-down',
  'far fa-caret-square-left',
  'far fa-caret-square-right',
  'far fa-caret-square-up',
  'far fa-chart-bar',
  'far fa-check-circle',
  'far fa-check-square',
  'far fa-circle',
  'far fa-clipboard',
  'far fa-clock',
  'far fa-clone',
  'far fa-closed-captioning',
  'far fa-comment',
  'far fa-comment-alt',
  'far fa-comment-dots',
  'far fa-comments',
  'far fa-compass',
  'far fa-copy',
  'far fa-copyright',
  'far fa-credit-card',
  'far fa-dizzy',
  'far fa-dot-circle',
  'far fa-edit',
  'far fa-envelope',
  'far fa-envelope-open',
  'far fa-eye',
  'far fa-eye-slash',
  'far fa-file',
  'far fa-file-alt',
  'far fa-file-archive',
  'far fa-file-audio',
  'far fa-file-code',
  'far fa-file-excel',
  'far fa-file-image',
  'far fa-file-pdf',
  'far fa-file-powerpoint',
  'far fa-file-video',
  'far fa-file-word',
  'far fa-flag',
  'far fa-flushed',
  'far fa-folder',
  'far fa-folder-open',
  'far fa-frown',
  'far fa-frown-open',
  'far fa-futbol',
  'far fa-gem',
  'far fa-grimace',
  'far fa-grin',
  'far fa-grin-alt',
  'far fa-grin-beam',
  'far fa-grin-beam-sweat',
  'far fa-grin-hearts',
  'far fa-grin-squint',
  'far fa-grin-squint-tears',
  'far fa-grin-stars',
  'far fa-grin-tears',
  'far fa-grin-tongue',
  'far fa-grin-tongue-squint',
  'far fa-grin-tongue-wink',
  'far fa-grin-wink',
  'far fa-hand-lizard',
  'far fa-hand-paper',
  'far fa-hand-peace',
  'far fa-hand-point-down',
  'far fa-hand-point-left',
  'far fa-hand-point-right',
  'far fa-hand-point-up',
  'far fa-hand-pointer',
  'far fa-hand-rock',
  'far fa-hand-scissors',
  'far fa-hand-spock',
  'far fa-handshake',
  'far fa-hdd',
  'far fa-heart',
  'far fa-hospital',
  'far fa-hourglass',
  'far fa-id-badge',
  'far fa-id-card',
  'far fa-image',
  'far fa-images',
  'far fa-keyboard',
  'far fa-kiss',
  'far fa-kiss-beam',
  'far fa-kiss-wink-heart',
  'far fa-laugh',
  'far fa-laugh-beam',
  'far fa-laugh-squint',
  'far fa-laugh-wink',
  'far fa-lemon',
  'far fa-life-ring',
  'far fa-lightbulb',
  'far fa-list-alt',
  'far fa-map',
  'far fa-meh',
  'far fa-meh-blank',
  'far fa-meh-rolling-eyes',
  'far fa-minus-square',
  'far fa-money-bill-alt',
  'far fa-moon',
  'far fa-newspaper',
  'far fa-object-group',
  'far fa-object-ungroup',
  'far fa-paper-plane',
  'far fa-pause-circle',
  'far fa-play-circle',
  'far fa-plus-square',
  'far fa-question-circle',
  'far fa-registered',
  'far fa-sad-cry',
  'far fa-sad-tear',
  'far fa-save',
  'far fa-share-square',
  'far fa-smile',
  'far fa-smile-beam',
  'far fa-smile-wink',
  'far fa-snowflake',
  'far fa-square',
  'far fa-star',
  'far fa-star-half',
  'far fa-sticky-note',
  'far fa-stop-circle',
  'far fa-sun',
  'far fa-surprise',
  'far fa-thumbs-down',
  'far fa-thumbs-up',
  'far fa-times-circle',
  'far fa-tired',
  'far fa-trash-alt',
  'far fa-user',
  'far fa-user-circle',
  'far fa-window-close',
  'far fa-window-maximize',
  'far fa-window-minimize',
  'far fa-window-restore'
];
