import React from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  TextField
} from '@material-ui/core';
import Page from 'src/components/Page';
import QRCode from 'react-qr-code';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const GoogleMapComponent = () => {
  const classes = useStyles();
  const [create, setCreate] = React.useState('');

  return (
    <Page className={classes.root} title="QR Generate">
      <Container maxWidth="lg">
        <Box m={3}>
          <Grid container spacing={3} justify="center">
            <Grid item>
              <QRCode value={create} />
            </Grid>
          </Grid>
        </Box>

        <Box mt={3}>
          <Grid container spacing={3} justify="center">
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                placeholder="create QR"
                size="small"
                value={create}
                onChange={e => {
                  setCreate(e.target.value);
                }}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
};

export default GoogleMapComponent;
