import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  makeStyles,
  useTheme,
  MobileStepper
} from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

const useStyles = makeStyles(theme => ({
  root: { width: '100%' },
  text: {
    maxWidth: 400,
    flexGrow: 1
  }
}));

const ProgressWizard = ({ className, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader title={<Typography variant="h2">Progress</Typography>} />
        <Divider />
        <CardContent>
          <Box
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <MobileStepper
              variant="progress"
              steps={6}
              position="static"
              activeStep={activeStep}
              className={classes.text}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === 5}
                >
                  Next
                  {theme.direction === 'rtl' ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  {theme.direction === 'rtl' ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                  Back
                </Button>
              }
            />
          </Box>

          <Box mt={1}>
            <Typography variant="subtitle1">
              Use a progress bar when there are many steps, or if there are
              steps that need to be inserted during the process (based on
              responses to earlier steps).
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

ProgressWizard.propTypes = {
  className: PropTypes.string
};

export default ProgressWizard;
