import React from 'react';
import { Container, makeStyles, Grid, Typography } from '@material-ui/core';
import Page from 'src/components/Page';
import Graph from './graph';
import OnlineSignups from './signup';
import TotalRevenueThisYear from './TotalRevenueThisYear';
import TotalEmailSent from './EmailSent';
import LastMonthSale from './LastMonthSale';
import LatestProducts from './LatestProducts';
import OrderGraph from './orderGraph';
import LatestOrders from './LatestOrders';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const UiComponents = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Ecommerce">
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <Typography variant="h3">ECommerce Dashboard</Typography>
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <OnlineSignups />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <LastMonthSale />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TotalRevenueThisYear />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TotalEmailSent />
          </Grid>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <Graph />
          </Grid>

          <Grid item lg={4} sm={6} xl={3} xs={12}>
            <LatestProducts />
          </Grid>
          <Grid item lg={8} sm={6} xl={3} xs={12}>
            <OrderGraph />
          </Grid>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <LatestOrders />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default UiComponents;
