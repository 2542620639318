import React from 'react';
import moment from 'moment';
import {
  Badge,
  Box,
  Container,
  makeStyles,
  withStyles,
  Drawer,
  AppBar,
  Toolbar,
  fade,
  Grid,
  InputBase,
  Tooltip,
  List,
  ListItem,
  Typography,
  Divider,
  IconButton,
  Card,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Avatar
} from '@material-ui/core';
import Page from 'src/components/Page';
import classNames from 'classnames';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import SendIcon from '@material-ui/icons/Send';
import NotificationsIcon from '@material-ui/icons/Notifications';
import CancelIcon from '@material-ui/icons/Cancel';
import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';
import StarIcon from '@material-ui/icons/Star';
import CreateIcon from '@material-ui/icons/Create';
import ArchiveIcon from '@material-ui/icons/Archive';
import DeleteIcon from '@material-ui/icons/Delete';
import ForwardIcon from '@material-ui/icons/Forward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ReplyAllIcon from '@material-ui/icons/ReplyAll';
import ErrorIcon from '@material-ui/icons/Error';
import DraftsIcon from '@material-ui/icons/Drafts';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import ComposeEditor from './composeEditor';
import mails from './data';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    maxHeight: '50%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  listitem: { paddingLeft: 24 },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.black, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.black, 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto'
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch'
    }
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
}));

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '1',
    zIndex: 1,
    overflowY: 'auto',
    position: 'relative',
    display: 'flex'
  },
  appBar: {
    background: '#fff',
    color: '#000',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  drawerPaper: {
    width: 250,
    overflow: 'auto',
    height: '100%',
    [theme.breakpoints.up('md')]: {
      overflow: 'auto',
      width: drawerWidth,
      position: 'relative',
      height: '100%'
    }
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9)
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    maxHeight: '86vh',
    overflowY: 'scroll',
    backgroundColor: theme.palette.background.default
    // padding: theme.spacing(3)
  },
  mailRow: {
    cursor: 'pointer'
  },
  readmailHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  readMailHeaderSpacing: { marginLeft: theme.spacing(3), cursor: 'pointer' },
  readmailContact: {
    display: 'flex',
    alignItems: 'center'
  }
});

const FileUploadIndex = props => {
  const dclass = useStyles();
  const { classes } = props;
  const [checked, setChecked] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const [data] = React.useState(mails);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(true);
  const [composeOpen, SetComposeOpen] = React.useState(false);
  const [readMail, setReadMail] = React.useState({});
  const [clickMail, SetClickMail] = React.useState(false);
  const isMenuOpen = Boolean(anchorEl);

  const handleClickOpen = () => {
    SetComposeOpen(true);
  };

  const handleClose = () => {
    SetComposeOpen(false);
  };

  const handleChange = event => {
    setChecked(event.target.checked);
  };
  const searchHandler = e => {
    setSearch(e.target.value);
  };
  const handleProfileMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );
  return (
    <Page className={dclass.root} title="Mail Box">
      <Container>
        <Card className={classes.root}>
          <AppBar position="absolute" className={classNames(classes.appBar)}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={open ? handleDrawerClose : handleDrawerOpen}
                className={classNames(dclass.menuButton)}
              >
                <MenuIcon />
              </IconButton>
              <Typography className={classes.title} variant="h3" noWrap>
                Messages
              </Typography>
              <div className={dclass.search}>
                <div className={dclass.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Search…"
                  onChange={searchHandler}
                  classes={{
                    root: dclass.inputRoot,
                    input: dclass.inputInput
                  }}
                  inputProps={{ 'aria-label': 'search' }}
                />
              </div>
              <div className={dclass.grow} />
              <div className={dclass.sectionDesktop}>
                <IconButton aria-label="show 4 new mails" color="inherit">
                  <Badge badgeContent={4} color="secondary">
                    <MailIcon />
                  </Badge>
                </IconButton>
                <IconButton
                  aria-label="show 17 new notifications"
                  color="inherit"
                >
                  <Badge badgeContent={17} color="secondary">
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>
          {renderMenu}
          <Drawer
            variant="permanent"
            classes={{
              paper: classNames(
                classes.drawerPaper,
                !open && classes.drawerPaperClose
              )
            }}
            open={open}
          >
            <Box style={{ height: '86vh' }}>
              <div className={classes.toolbar}>
                <IconButton onClick={handleDrawerClose}></IconButton>
              </div>
              <List>
                <ListItem
                  button
                  onClick={handleClickOpen}
                  className={dclass.listitem}
                >
                  <ListItemIcon>
                    <CreateIcon />
                  </ListItemIcon>
                  <ListItemText primary="Compose" />
                </ListItem>

                <Dialog
                  open={composeOpen}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Typography>Compose</Typography>
                      <CancelIcon
                        style={{ cursor: 'pointer' }}
                        onClick={handleClose}
                      />
                    </div>
                  </DialogTitle>
                  <Divider />
                  <DialogContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <TextField
                          size="small"
                          fullWidth
                          placeholder="To"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          size="small"
                          fullWidth
                          placeholder="Subject"
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Box mt={1}>
                      <ComposeEditor />
                    </Box>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="contained"
                      onClick={handleClose}
                      color="primary"
                      autoFocus
                    >
                      Send &nbsp;
                      <SendIcon />
                    </Button>
                  </DialogActions>
                </Dialog>
              </List>
              <Divider />
              <List>
                <ListItem button className={dclass.listitem}>
                  <ListItemIcon>
                    <MoveToInboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="Inbox" />
                  <ListItemText align="right">3</ListItemText>
                </ListItem>
                <ListItem button className={dclass.listitem}>
                  <ListItemIcon>
                    <SendIcon />
                  </ListItemIcon>
                  <ListItemText primary="Sent" />
                </ListItem>
                <ListItem button className={dclass.listitem}>
                  <ListItemIcon>
                    <DraftsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Drafts" />
                </ListItem>
                <ListItem button className={dclass.listitem}>
                  <ListItemIcon>
                    <ErrorIcon />
                  </ListItemIcon>
                  <ListItemText primary="Spam" />
                  <ListItemText align="right">5</ListItemText>
                </ListItem>
                <ListItem button className={dclass.listitem}>
                  <ListItemIcon>
                    <DeleteIcon />
                  </ListItemIcon>
                  <ListItemText primary="Trash" />
                  <ListItemText align="right">22</ListItemText>
                </ListItem>
                <ListItem button className={dclass.listitem}>
                  <ListItemIcon>
                    <ArchiveIcon />
                  </ListItemIcon>
                  <ListItemText primary="Archived" />
                </ListItem>
              </List>
              <Divider />
              <List>
                <ListItem button className={dclass.listitem}>
                  <ListItemIcon>
                    <LabelImportantIcon />
                  </ListItemIcon>
                  <ListItemText primary="Important" />
                </ListItem>
                <ListItem button className={dclass.listitem}>
                  <ListItemIcon>
                    <StarIcon />
                  </ListItemIcon>
                  <ListItemText primary="Favorite" />
                </ListItem>
              </List>
              <Divider />
              <List>
                <ListItem button className={dclass.listitem}>
                  <ListItemIcon>
                    <LabelImportantIcon style={{ color: 'gold' }} />
                  </ListItemIcon>
                  <ListItemText primary="Banking" />
                </ListItem>
                <ListItem button className={dclass.listitem}>
                  <ListItemIcon>
                    <LabelImportantIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Company" />
                </ListItem>
              </List>
              <Divider />
              <List>
                <ListItem button className={dclass.listitem}>
                  <ListItemIcon>
                    <LabelImportantIcon style={{ color: 'gold' }} />
                  </ListItemIcon>
                  <ListItemText primary="Banking" />
                </ListItem>
                <ListItem button className={dclass.listitem}>
                  <ListItemIcon>
                    <LabelImportantIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Company" />
                </ListItem>
              </List>
            </Box>
          </Drawer>
          <main className={classes.content}>
            <div className={classes.toolbar} />
            {!clickMail ? (
              <Table size="small">
                <TableBody>
                  {data
                    .filter(data => {
                      if (search === '') return data;
                      else if (
                        data.who
                          .toString()
                          .toLowerCase()
                          .includes(search.toString().toLowerCase()) ||
                        data.subject
                          .toString()
                          .toLowerCase()
                          .includes(search.toString().toLowerCase()) ||
                        data.about
                          .toString()
                          .toLowerCase()
                          .includes(search.toString().toLowerCase()) ||
                        moment(data.date)
                          .calendar()
                          .toString()
                          .toLowerCase()
                          .includes(search.toString().toLowerCase())
                      ) {
                        return data;
                      }
                      return '';
                    })
                    .map((val, index) => {
                      return (
                        <TableRow
                          className={classes.mailRow}
                          key={index}
                          onClick={() => {
                            setReadMail(val);
                            // SetClickMail(true);
                          }}
                        >
                          <TableCell>
                            <Checkbox
                              color="primary"
                              onChange={handleChange}
                              value={checked}
                            />
                          </TableCell>
                          <TableCell
                            onClick={() => {
                              SetClickMail(true);
                            }}
                          >
                            {val?.who}
                            {val?.to === '' ? null : ' , ' + val?.to}&nbsp;
                            {val?.total === null
                              ? null
                              : '(' + val?.total + ')'}
                          </TableCell>
                          <TableCell
                            onClick={() => {
                              SetClickMail(true);
                            }}
                          >
                            {val?.about.length > 70
                              ? val?.about.substring(0, 70 - 3) + '...'
                              : val.about}
                          </TableCell>
                          <TableCell
                            onClick={() => {
                              SetClickMail(true);
                            }}
                          >
                            {moment(val?.date).calendar()}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            ) : (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box m={2} className={classes.readmailHeader}>
                    <ArrowBackIcon
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        SetClickMail(false);
                      }}
                    />
                    <Box>
                      <Tooltip title="Delete">
                        <DeleteIcon className={classes.readMailHeaderSpacing} />
                      </Tooltip>
                      <Tooltip title="Reply">
                        <ReplyAllIcon
                          className={classes.readMailHeaderSpacing}
                        />
                      </Tooltip>
                      <Tooltip title="Forward">
                        <ForwardIcon
                          className={classes.readMailHeaderSpacing}
                        />
                      </Tooltip>
                      <Tooltip title="Archieve">
                        <ArchiveIcon
                          className={classes.readMailHeaderSpacing}
                        />
                      </Tooltip>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box ml={2} className={classes.readmailContact}>
                    <Avatar
                      alt={readMail.who}
                      src="https://material-ui.com/static/images/avatar/1.jpg"
                    />
                    <Box pl={3}>
                      <Typography variant="h4">{readMail.who}</Typography>
                      <Typography variant="subtitle1">
                        {readMail.email}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box pl={3}>
                    <Typography variant="h5">{readMail.subject}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box pl={3}>
                    <Typography variant="subtitle2">
                      Dear Lorem Ipsum,
                    </Typography>

                    <Typography variant="subtitle2">
                      Praesent dui ex, dapibus eget mauris ut, finibus
                      vestibulum enim. Quisque arcu leo, facilisis in fringilla
                      id, luctus in tortor. Nunc vestibulum est quis orci varius
                      viverra. Curabitur dictum volutpat massa vulputate
                      molestie. In at felis ac velit maximus convallis.
                    </Typography>

                    <Typography variant="subtitle2">
                      Sed elementum turpis eu lorem interdum, sed porttitor eros
                      commodo. Nam eu venenatis tortor, id lacinia diam. Sed
                      aliquam in dui et porta. Sed bibendum orci non tincidunt
                      ultrices. Vivamus fringilla, mi lacinia dapibus
                      condimentum, ipsum urna lacinia lacus, vel tincidunt mi
                      nibh sit amet lorem.
                    </Typography>

                    <Typography variant="subtitle2">Sincerly,</Typography>
                  </Box>
                  <Box mt={5} pl={3}>
                    <Button variant="contained" color="secondary">
                      Reply&nbsp;
                      <ReplyAllIcon />
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            )}
          </main>
        </Card>
      </Container>
    </Page>
  );
};

export default withStyles(styles, { withTheme: true })(FileUploadIndex);
