import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  BarChart as BarChartIcon,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  User as UserIcon,
  Users as UsersIcon
} from 'react-feather';
import AppsIcon from '@material-ui/icons/Apps';
import MapIcon from '@material-ui/icons/Map';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import ControlCameraIcon from '@material-ui/icons/ControlCamera';
import SpellcheckIcon from '@material-ui/icons/Spellcheck';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import WidgetsRoundedIcon from '@material-ui/icons/WidgetsRounded';
import CreditCardTwoToneIcon from '@material-ui/icons/CreditCardTwoTone';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import TableChartIcon from '@material-ui/icons/TableChart';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import PagesIcon from '@material-ui/icons/Pages';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import VerifiedUserTwoToneIcon from '@material-ui/icons/VerifiedUserTwoTone';
import NavItem from './NavItem';

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  jobTitle: 'Senior Developer',
  name: 'Jnext Developement'
};

const items = [
  {
    href: '/app/dashboard',
    icon: BarChartIcon,
    title: 'Dashboard',
    sub: []
  },
  {
    href: '/app/customers',
    icon: UsersIcon,
    title: 'Customers',
    sub: []
  },
  {
    href: '/app/products',
    icon: ShoppingBagIcon,
    title: 'Products',
    sub: []
  },
  {
    href: '/app/account',
    icon: UserIcon,
    title: 'Account',
    sub: []
  },
  {
    href: '/app/settings',
    icon: SettingsIcon,
    title: 'Settings',
    sub: []
  },
  {
    href: '/app/ecommerce',
    icon: ShoppingCartIcon,
    title: 'Ecommerce',
    sub: []
  },
  {
    href: '',
    icon: AppsIcon,
    title: 'Application',
    sub: [
      {
        href: '/app/chat',
        icon: '',
        title: 'Chat',
        sub: []
      },
      {
        href: '/app/mail',
        icon: '',
        title: 'Mail',
        sub: []
      },
      {
        href: '/app/checkout',
        icon: '',
        title: 'Checkout',
        sub: []
      },
      {
        href: '/app/calender',
        icon: '',
        title: 'Calender',
        sub: []
      },
      {
        href: '/app/qr-generate',
        icon: '',
        title: 'QR Generate',
        sub: []
      }
    ]
  },
  {
    href: '',
    icon: VerifiedUserTwoToneIcon,
    title: 'Authentication',
    sub: [
      {
        href: '/login',
        icon: '',
        title: 'Login',
        sub: []
      },
      {
        href: '/register',
        icon: '',
        title: 'Register',
        sub: []
      },
      {
        href: '/otp-verify',
        icon: '',
        title: 'OTP Verify',
        sub: []
      },
      {
        href: '/forgot-password',
        icon: '',
        title: 'Forgot Password',
        sub: []
      },
      {
        href: '/forgot-password-change',
        icon: '',
        title: 'Forgot Password Change',
        sub: []
      }
    ]
  },
  {
    href: '/app/dashboard',
    icon: EqualizerIcon,
    title: 'Charts',
    sub: [
      {
        href: '/app/apexcharts',
        icon: '',
        title: 'Apexcharts',
        sub: []
      },
      {
        href: '/app/chartjs2',
        icon: '',
        title: 'Chartjs',
        sub: []
      },
      {
        href: '/app/sparkline',
        icon: '',
        title: 'Sparkline',
        sub: []
      }
    ]
  },
  {
    href: '',
    icon: SpellcheckIcon,
    title: 'Editor',
    sub: [
      {
        href: '/app/ck-editor',
        icon: '',
        title: 'CKEditor',
        sub: []
      },
      {
        href: '/app/wysiswyg-editor',
        icon: '',
        title: 'WYSISWYG Editor',
        sub: []
      }
    ]
  },
  {
    href: '',
    icon: AspectRatioIcon,
    title: 'Forms',
    sub: [
      {
        href: '/app/validation',
        icon: '',
        title: 'Formik Validation',
        sub: []
      },
      {
        href: '/app/file-upload',
        icon: '',
        title: 'File Upload',
        sub: []
      },
      {
        href: '/app/wizard',
        icon: '',
        title: 'Wizard',
        sub: []
      }
    ]
  },
  {
    href: '',
    icon: AcUnitIcon,
    title: 'Icons',
    sub: [
      {
        href: '/app/dripicons',
        icon: '',
        title: 'Drip Icons'
      },
      {
        href: '/app/font-awesome-icons',
        icon: '',
        title: 'Font Awesome'
      },
      {
        href: '/app/remix-icons',
        icon: '',
        title: 'Remix Icons'
      }
    ]
  },
  {
    href: '',
    icon: MapIcon,
    title: 'Maps',
    sub: [
      {
        href: '/app/google-map',
        icon: '',
        title: 'Google Map',
        sub: []
      },
      {
        href: '/app/full-screen-map',
        icon: '',
        title: 'Full Screen Map',
        sub: []
      },
      {
        href: '/app/vector-map',
        icon: '',
        title: 'Vector Map',
        sub: []
      }
    ]
  },
  {
    href: '/app/sweet-alert',
    icon: AnnouncementIcon,
    title: 'Sweet Alert',
    sub: []
  },
  {
    href: '',
    icon: TableChartIcon,
    title: 'Tables',
    sub: [
      {
        href: '/app/basic-table',
        icon: '',
        title: 'Basic Table',
        sub: []
      },
      {
        href: '/app/data-table',
        icon: '',
        title: 'Data Table',
        sub: []
      },
      {
        href: '/app/dense-table',
        icon: '',
        title: 'Dense Table',
        sub: []
      },
      {
        href: '/app/sorting-and-selecting-table',
        icon: '',
        title: 'Sorting & selecting',
        sub: []
      },
      {
        href: '/app/customized-table',
        icon: '',
        title: 'Customized',
        sub: []
      }
    ]
  },
  {
    href: '',
    icon: PagesIcon,
    title: 'Utility',
    sub: [
      {
        href: '/maintenance',
        icon: '',
        title: 'Maintenance',
        sub: []
      },
      {
        href: '/coming-soon',
        icon: '',
        title: 'Coming Soon',
        sub: []
      },
      {
        href: '/app/timeline',
        icon: '',
        title: 'Timeline',
        sub: []
      },
      {
        href: '/app/pricing',
        icon: '',
        title: 'Pricing',
        sub: []
      },
      {
        href: '/404',
        icon: '',
        title: 'Error 404',
        sub: []
      },
      {
        href: '/500',
        icon: '',
        title: 'Error 500',
        sub: []
      }
    ]
  },
  {
    href: '',
    icon: WidgetsRoundedIcon,
    title: 'UI Elements',
    sub: [
      {
        href: '/app/alerts',
        icon: '',
        title: 'Alerts'
      },
      {
        href: '/app/buttons',
        icon: '',
        title: 'Buttons'
      },
      {
        href: '/app/button-group',
        icon: '',
        title: 'Button Group'
      },
      {
        href: '/app/cards',
        icon: '',
        title: 'Cards'
      },
      {
        href: '/app/date-time',
        icon: '',
        title: 'Date & Time'
      },
      {
        href: '/app/dialog',
        icon: '',
        title: 'Dialog'
      },
      {
        href: '/app/grid',
        icon: '',
        title: 'Grid'
      },
      {
        href: '/app/images',
        icon: '',
        title: 'Images'
      },
      {
        href: '/app/progress-bars',
        icon: '',
        title: 'Progress Bars'
      },
      {
        href: '/app/select',
        icon: '',
        title: 'Select'
      },
      {
        href: '/app/slider',
        icon: '',
        title: 'Slider'
      },
      {
        href: '/app/text-field',
        icon: '',
        title: 'Text Field'
      },
      {
        href: '/app/typography',
        icon: '',
        title: 'Typography'
      }
    ]
  },
  {
    href: '/app/kanban-board',
    icon: ControlCameraIcon,
    title: 'Kanban Board',
    sub: []
  },
  {
    href: '/app/credit-card',
    icon: CreditCardTwoToneIcon,
    title: 'Credit Card',
    sub: []
  }
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.4em'
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: 'none',
      borderRadius: '4px'
    }
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={user.avatar}
          to="/app/account"
        />
        <Typography className={classes.name} color="textPrimary" variant="h5">
          {user.name}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {user.jobTitle}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {items.map(item => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
              sub={item.sub}
            />
          ))}
          <NavItem
            href="/app/logout"
            title="LogOut"
            icon={ExitToAppIcon}
            sub={[]}
          />
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
