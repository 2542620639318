import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Paper,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  makeStyles,
  Grid
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  cardbackground: {
    background: theme.palette.background.dark
  },
  grid: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  code: {
    width: '100%',
    paddingTop: '15px',
    background: '#272C34',
    color: '#fff',
    marginTop: '10px',
    borderRadius: '4px',
    overflowX: 'scroll'
  }
}));

const NestedGrid = ({ className, ...rest }) => {
  const classes = useStyles();
  var code = `<Grid container spacing={1}>
  <Grid container item xs={12} spacing={3}>
    <FormRow />
  </Grid>
  <Grid container item xs={12} spacing={3}>
    <FormRow />
  </Grid>
  <Grid container item xs={12} spacing={3}>
    <FormRow />
  </Grid>
</Grid>`;

  function FormRow() {
    return (
      <React.Fragment>
        <Grid item xs={4}>
          <Paper className={classes.paper}>item</Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.paper}>item</Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.paper}>item</Paper>
        </Grid>
      </React.Fragment>
    );
  }

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card className={classes.cardbackground}>
        <CardHeader
          title={<Typography variant="h2">Complex Grid</Typography>}
        />
        <Divider />
        <CardContent className={classes.cardbackground}>
          <Box mt={1}>
            <div className={classes.grid}>
              <Grid container spacing={1}>
                <Grid container item xs={12} spacing={3}>
                  <FormRow />
                </Grid>
                <Grid container item xs={12} spacing={3}>
                  <FormRow />
                </Grid>
                <Grid container item xs={12} spacing={3}>
                  <FormRow />
                </Grid>
              </Grid>
            </div>
          </Box>
          <Box className={classes.code}>
            <Container>
              <pre>
                <Typography
                  variant="subtitle2"
                  style={{ fontFamily: 'monospace', fontSize: 14 }}
                >
                  {code}
                </Typography>
              </pre>
            </Container>
          </Box>
          <Box mt={1}>
            <Typography variant="subtitle1">
              The <b>container</b> and <b>item</b> properties are two
              independent booleans. They can be combined.
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

NestedGrid.propTypes = {
  className: PropTypes.string
};

export default NestedGrid;
