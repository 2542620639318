import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  makeStyles,
  Container
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AlarmIcon from '@material-ui/icons/Alarm';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  button: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  code: {
    width: '100%',
    paddingTop: '15px',
    background: '#272C34',
    color: '#fff',
    marginTop: '10px',
    borderRadius: '4px',
    overflowX: 'scroll'
  }
}));

const IconsButton = ({ className, ...rest }) => {
  const classes = useStyles();
  var code = `<IconButton aria-label="delete">
  <DeleteIcon />
</IconButton>
<IconButton aria-label="delete" disabled color="primary">
  <DeleteIcon />
</IconButton>
<IconButton color="secondary" aria-label="add an alarm">
  <AlarmIcon />
</IconButton>
<IconButton color="primary" aria-label="add to shopping cart">
  <AddShoppingCartIcon />
</IconButton>`;
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader
          title={<Typography variant="h2">Icon Buttons</Typography>}
          subheader=""
        />
        <Divider />
        <CardContent>
          <Box mt={1}>
            <div className={classes.root} style={{ textAlign: 'center' }}>
              <IconButton aria-label="delete">
                <DeleteIcon />
              </IconButton>
              <IconButton aria-label="delete" disabled color="primary">
                <DeleteIcon />
              </IconButton>
              <IconButton color="secondary" aria-label="add an alarm">
                <AlarmIcon />
              </IconButton>
              <IconButton color="primary" aria-label="add to shopping cart">
                <AddShoppingCartIcon />
              </IconButton>
            </div>
          </Box>
          <Box className={classes.code}>
            <Container>
              <pre>
                <Typography
                  variant="subtitle2"
                  style={{ fontFamily: 'monospace', fontSize: 14 }}
                >
                  {code}
                </Typography>
              </pre>
            </Container>
          </Box>
          <Box mt={1}>
            <Typography variant="subtitle1">
              Icon buttons are commonly found in app bars and toolbars. Icons
              are also appropriate for toggle buttons that allow a single choice
              to be selected or deselected, such as adding or removing a star to
              an item.
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

IconsButton.propTypes = {
  className: PropTypes.string
};

export default IconsButton;
