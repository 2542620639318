import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  TextField,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  layout: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch'
  }
}));

const TextFieldLayout = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader title={<Typography variant="h2">Layout</Typography>} />
        <Divider />
        <CardContent>
          <Box mt={1}>
            <div
              className={classes.layout}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <div>
                <TextField
                  id="standard-full-width"
                  label="Label"
                  style={{ margin: 8 }}
                  placeholder="Placeholder"
                  helperText="Full width!"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                <TextField
                  label="None"
                  id="margin-none"
                  defaultValue="Default Value"
                  className={classes.textField}
                  helperText="Some important text"
                />
                <TextField
                  label="Dense"
                  id="margin-dense"
                  defaultValue="Default Value"
                  className={classes.textField}
                  helperText="Some important text"
                  margin="dense"
                />
                <TextField
                  label="Normal"
                  id="margin-normal"
                  defaultValue="Default Value"
                  className={classes.textField}
                  helperText="Some important text"
                  margin="normal"
                />
              </div>
              <div>
                <TextField
                  id="filled-full-width"
                  label="Label"
                  style={{ margin: 8 }}
                  placeholder="Placeholder"
                  helperText="Full width!"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true
                  }}
                  variant="filled"
                />
                <TextField
                  label="None"
                  id="filled-margin-none"
                  defaultValue="Default Value"
                  className={classes.textField}
                  helperText="Some important text"
                  variant="filled"
                />
                <TextField
                  label="Dense"
                  id="filled-margin-dense"
                  defaultValue="Default Value"
                  className={classes.textField}
                  helperText="Some important text"
                  margin="dense"
                  variant="filled"
                />
                <TextField
                  label="Normal"
                  id="filled-margin-normal"
                  defaultValue="Default Value"
                  className={classes.textField}
                  helperText="Some important text"
                  margin="normal"
                  variant="filled"
                />
              </div>
              <div>
                <TextField
                  id="outlined-full-width"
                  label="Label"
                  style={{ margin: 8 }}
                  placeholder="Placeholder"
                  helperText="Full width!"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true
                  }}
                  variant="outlined"
                />
                <TextField
                  label="None"
                  id="outlined-margin-none"
                  defaultValue="Default Value"
                  className={classes.textField}
                  helperText="Some important text"
                  variant="outlined"
                />
                <TextField
                  label="Dense"
                  id="outlined-margin-dense"
                  defaultValue="Default Value"
                  className={classes.textField}
                  helperText="Some important text"
                  margin="dense"
                  variant="outlined"
                />
                <TextField
                  label="Normal"
                  id="outlined-margin-normal"
                  defaultValue="Default Value"
                  className={classes.textField}
                  helperText="Some important text"
                  margin="normal"
                  variant="outlined"
                />
              </div>
            </div>
          </Box>

          <Box mt={1}>
            <Typography variant="subtitle1">
              <b>margin</b> prop can be used to alter the vertical spacing of
              inputs. Using <b>none</b> (default) will not apply margins to the
              <b>FormControl</b>, whereas <b>dense</b> and <b>normal</b> will.
              <b>dense</b> and <b>normal</b> alter other styles to meet the
              specification.
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

TextFieldLayout.propTypes = {
  className: PropTypes.string
};

export default TextFieldLayout;
