export default [
  'ri-mail-line',
  'ri-mail-fill',
  'ri-mail-open-line',
  'ri-mail-open-fill',
  'ri-mail-send-line',
  'ri-mail-send-fill',
  'ri-mail-unread-line',
  'ri-mail-unread-fill',
  'ri-mail-add-line',
  'ri-mail-add-fill',
  'ri-mail-check-line',
  'ri-mail-check-fill',
  'ri-mail-close-line',
  'ri-mail-close-fill',
  'ri-mail-download-line',
  'ri-mail-download-fill',
  'ri-mail-forbid-line',
  'ri-mail-forbid-fill',
  'ri-mail-lock-line',
  'ri-mail-lock-fill',
  'ri-mail-settings-line',
  'ri-mail-settings-fill',
  'ri-mail-star-line',
  'ri-mail-star-fill',
  'ri-mail-volume-line',
  'ri-mail-volume-fill',
  'ri-inbox-line',
  'ri-inbox-fill',
  'ri-inbox-archive-line',
  'ri-inbox-archive-fill',
  'ri-inbox-unarchive-line',
  'ri-inbox-unarchive-fill',
  'ri-cloud-line',
  'ri-cloud-fill',
  'ri-cloud-off-line',
  'ri-cloud-off-fill',
  'ri-attachment-line',
  'ri-attachment-fill',
  'ri-profile-line',
  'ri-profile-fill',
  'ri-archive-line',
  'ri-archive-fill',
  'ri-archive-drawer-line',
  'ri-archive-drawer-fill',
  'ri-at-line',
  'ri-at-fill',
  'ri-award-line',
  'ri-award-fill',
  'ri-medal-line',
  'ri-medal-fill',
  'ri-medal-2-line',
  'ri-medal-2-fill',
  'ri-bar-chart-line',
  'ri-bar-chart-fill',
  'ri-bar-chart-horizontal-line',
  'ri-bar-chart-horizontal-fill',
  'ri-bar-chart-2-line',
  'ri-bar-chart-2-fill',
  'ri-bar-chart-box-line',
  'ri-bar-chart-box-fill',
  'ri-bar-chart-grouped-line',
  'ri-bar-chart-grouped-fill',
  'ri-bubble-chart-line',
  'ri-bubble-chart-fill',
  'ri-pie-chart-line',
  'ri-pie-chart-fill',
  'ri-pie-chart-2-line',
  'ri-pie-chart-2-fill',
  'ri-pie-chart-box-line',
  'ri-pie-chart-box-fill',
  'ri-donut-chart-line',
  'ri-donut-chart-fill',
  'ri-line-chart-line',
  'ri-line-chart-fill',
  'ri-bookmark-line',
  'ri-bookmark-fill',
  'ri-bookmark-2-line',
  'ri-bookmark-2-fill',
  'ri-bookmark-3-line',
  'ri-bookmark-3-fill',
  'ri-briefcase-line',
  'ri-briefcase-fill',
  'ri-briefcase-2-line',
  'ri-briefcase-2-fill',
  'ri-briefcase-3-line',
  'ri-briefcase-3-fill',
  'ri-briefcase-4-line',
  'ri-briefcase-4-fill',
  'ri-briefcase-5-line',
  'ri-briefcase-5-fill',
  'ri-calculator-line',
  'ri-calculator-fill',
  'ri-calendar-line',
  'ri-calendar-fill',
  'ri-calendar-2-line',
  'ri-calendar-2-fill',
  'ri-calendar-event-line',
  'ri-calendar-event-fill',
  'ri-calendar-todo-line',
  'ri-calendar-todo-fill',
  'ri-calendar-check-line',
  'ri-calendar-check-fill',
  'ri-customer-service-line',
  'ri-customer-service-fill',
  'ri-customer-service-2-line',
  'ri-customer-service-2-fill',
  'ri-flag-line',
  'ri-flag-fill',
  'ri-flag-2-line',
  'ri-flag-2-fill',
  'ri-global-line',
  'ri-global-fill',
  'ri-honour-line',
  'ri-honour-fill',
  'ri-links-line',
  'ri-links-fill',
  'ri-printer-line',
  'ri-printer-fill',
  'ri-printer-cloud-line',
  'ri-printer-cloud-fill',
  'ri-record-mail-line',
  'ri-record-mail-fill',
  'ri-reply-line',
  'ri-reply-fill',
  'ri-send-plane-line',
  'ri-send-plane-fill',
  'ri-send-plane-2-line',
  'ri-send-plane-2-fill',
  'ri-projector-line',
  'ri-projector-fill',
  'ri-projector-2-line',
  'ri-projector-2-fill',
  'ri-slideshow-line',
  'ri-slideshow-fill',
  'ri-slideshow-2-line',
  'ri-slideshow-2-fill',
  'ri-slideshow-3-line',
  'ri-slideshow-3-fill',
  'ri-slideshow-4-line',
  'ri-slideshow-4-fill',
  'ri-window-line',
  'ri-window-fill',
  'ri-window-2-line',
  'ri-window-2-fill',
  'ri-stack-line',
  'ri-stack-fill',
  'ri-service-line',
  'ri-service-fill',
  'ri-registered-line',
  'ri-registered-fill',
  'ri-trademark-line',
  'ri-trademark-fill',
  'ri-advertisement-line',
  'ri-advertisement-fill',
  'ri-copyright-line',
  'ri-copyright-fill',
  'ri-creative-commons-line',
  'ri-creative-commons-fill',
  'ri-creative-commons-by-line',
  'ri-creative-commons-by-fill',
  'ri-creative-commons-nc-line',
  'ri-creative-commons-nc-fill',
  'ri-creative-commons-nd-line',
  'ri-creative-commons-nd-fill',
  'ri-creative-commons-sa-line',
  'ri-creative-commons-sa-fill',
  'ri-creative-commons-zero-line',
  'ri-creative-commons-zero-fill'
];
