import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles
} from '@material-ui/core';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const useStyles = makeStyles(theme => ({
  root: {}
}));

const MySwal = withReactContent(Swal);

const AdvanceAlertComponent = ({ className, ...rest }) => {
  const classes = useStyles();
  const sweetAlerts = () => {
    MySwal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then(result => {
      if (result.value) {
        MySwal.fire('Deleted!', 'Your file has been deleted.', 'success');
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        MySwal.fire('Cancelled', 'Your imaginary file is safe :)', 'error');
      }
    });
  };
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader title="Advanced Alert" />
        <Divider />
        <CardContent>
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Button variant="outlined" color="primary" onClick={sweetAlerts}>
              Click me
            </Button>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

AdvanceAlertComponent.propTypes = {
  className: PropTypes.string
};

export default AdvanceAlertComponent;
