export default [
  'ri-wallet-line',
  'ri-wallet-fill',
  'ri-wallet-2-line',
  'ri-wallet-2-fill',
  'ri-wallet-3-line',
  'ri-wallet-3-fill',
  'ri-bank-card-line',
  'ri-bank-card-fill',
  'ri-bank-card-2-line',
  'ri-bank-card-2-fill',
  'ri-secure-payment-line',
  'ri-secure-payment-fill',
  'ri-refund-line',
  'ri-refund-fill',
  'ri-refund-2-line',
  'ri-refund-2-fill',
  'ri-safe-line',
  'ri-safe-fill',
  'ri-safe-2-line',
  'ri-safe-2-fill',
  'ri-price-tag-line',
  'ri-price-tag-fill',
  'ri-price-tag-2-line',
  'ri-price-tag-2-fill',
  'ri-price-tag-3-line',
  'ri-price-tag-3-fill',
  'ri-ticket-line',
  'ri-ticket-fill',
  'ri-ticket-2-line',
  'ri-ticket-2-fill',
  'ri-coupon-line',
  'ri-coupon-fill',
  'ri-coupon-2-line',
  'ri-coupon-2-fill',
  'ri-coupon-3-line',
  'ri-coupon-3-fill',
  'ri-coupon-4-line',
  'ri-coupon-4-fill',
  'ri-coupon-5-line',
  'ri-coupon-5-fill',
  'ri-shopping-bag-line',
  'ri-shopping-bag-fill',
  'ri-shopping-bag-2-line',
  'ri-shopping-bag-2-fill',
  'ri-shopping-bag-3-line',
  'ri-shopping-bag-3-fill',
  'ri-shopping-basket-line',
  'ri-shopping-basket-fill',
  'ri-shopping-basket-2-line',
  'ri-shopping-basket-2-fill',
  'ri-shopping-cart-line',
  'ri-shopping-cart-fill',
  'ri-shopping-cart-2-line',
  'ri-shopping-cart-2-fill',
  'ri-vip-line',
  'ri-vip-fill',
  'ri-vip-crown-line',
  'ri-vip-crown-fill',
  'ri-vip-crown-2-line',
  'ri-vip-crown-2-fill',
  'ri-vip-diamond-line',
  'ri-vip-diamond-fill',
  'ri-trophy-line',
  'ri-trophy-fill',
  'ri-exchange-line',
  'ri-exchange-fill',
  'ri-exchange-box-line',
  'ri-exchange-box-fill',
  'ri-swap-line',
  'ri-swap-fill',
  'ri-swap-box-line',
  'ri-swap-box-fill',
  'ri-exchange-dollar-line',
  'ri-exchange-dollar-fill',
  'ri-exchange-cny-line',
  'ri-exchange-cny-fill',
  'ri-exchange-funds-line',
  'ri-exchange-funds-fill',
  'ri-increase-decrease-line',
  'ri-increase-decrease-fill',
  'ri-percent-line',
  'ri-percent-fill',
  'ri-copper-coin-line',
  'ri-copper-coin-fill',
  'ri-copper-diamond-line',
  'ri-copper-diamond-fill',
  'ri-money-cny-box-line',
  'ri-money-cny-box-fill',
  'ri-money-cny-circle-line',
  'ri-money-cny-circle-fill',
  'ri-money-dollar-box-line',
  'ri-money-dollar-box-fill',
  'ri-money-dollar-circle-line',
  'ri-money-dollar-circle-fill',
  'ri-money-euro-box-line',
  'ri-money-euro-box-fill',
  'ri-money-euro-circle-line',
  'ri-money-euro-circle-fill',
  'ri-money-pound-box-line',
  'ri-money-pound-box-fill',
  'ri-money-pound-circle-line',
  'ri-money-pound-circle-fill',
  'ri-bit-coin-line',
  'ri-bit-coin-fill',
  'ri-coin-line',
  'ri-coin-fill',
  'ri-coins-line',
  'ri-coins-fill',
  'ri-currency-line',
  'ri-currency-fill',
  'ri-funds-line',
  'ri-funds-fill',
  'ri-funds-box-line',
  'ri-funds-box-fill',
  'ri-red-packet-line',
  'ri-red-packet-fill',
  'ri-water-flash-line',
  'ri-water-flash-fill',
  'ri-stock-line',
  'ri-stock-fill',
  'ri-auction-line',
  'ri-auction-fill',
  'ri-gift-line',
  'ri-gift-fill',
  'ri-gift-2-line',
  'ri-gift-2-fill',
  'ri-hand-coin-line',
  'ri-hand-coin-fill',
  'ri-hand-heart-line',
  'ri-hand-heart-fill'
];
