import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  RadioGroup,
  Radio,
  FormControlLabel,
  ListItemText,
  ListItem,
  List
} from '@material-ui/core';

const options = [
  'None',
  'Atria',
  'Callisto',
  'Dione',
  'Ganymede',
  'Hangouts Call',
  'Luna',
  'Oberon',
  'Phobos',
  'Pyxis',
  'Sedna',
  'Titania',
  'Triton',
  'Umbriel'
];

function ConfirmationDialogRaw(props) {
  const { onClose, value: valueProp, open, ...other } = props;
  const [value, setValue] = React.useState(valueProp);
  const radioGroupRef = React.useRef(null);

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(value);
  };

  const handleChange = event => {
    setValue(event.target.value);
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      onEntering={handleEntering}
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">Phone Ringtone</DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          ref={radioGroupRef}
          aria-label="ringtone"
          name="ringtone"
          value={value}
          onChange={handleChange}
        >
          {options.map(option => (
            <FormControlLabel
              value={option}
              key={option}
              control={<Radio />}
              label={option}
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleOk} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles(theme => ({
  root: {},
  dialog: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  paper: {
    width: '80%',
    maxHeight: 435
  }
}));

const ConfirmationDialogCompo = ({ className, ...rest }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState('Dione');

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleClose = newValue => {
    setOpen(false);

    if (newValue) {
      setValue(newValue);
    }
  };
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader
          title={<Typography variant="h2">Confirmation dialogs</Typography>}
        />
        <Divider />
        <CardContent>
          <Box mt={1} style={{ display: 'flex', justifyContent: 'center' }}>
            <div className={classes.dialog}>
              <List component="div" role="list">
                <ListItem button divider disabled role="listitem">
                  <ListItemText primary="Interruptions" />
                </ListItem>
                <ListItem
                  button
                  divider
                  aria-haspopup="true"
                  aria-controls="ringtone-menu"
                  aria-label="phone ringtone"
                  onClick={handleClickListItem}
                  role="listitem"
                >
                  <ListItemText primary="Phone ringtone" secondary={value} />
                </ListItem>
                <ListItem button divider disabled role="listitem">
                  <ListItemText
                    primary="Default notification ringtone"
                    secondary="Tethys"
                  />
                </ListItem>
                <ConfirmationDialogRaw
                  classes={{
                    paper: classes.paper
                  }}
                  id="ringtone-menu"
                  keepMounted
                  open={open}
                  onClose={handleClose}
                  value={value}
                />
              </List>
            </div>
          </Box>
          <Box mt={1}>
            <Typography variant="subtitle1">
              Confirmation dialogs require users to explicitly confirm their
              choice before an option is committed. For example, users can
              listen to multiple ringtones but only make a final selection upon
              touching “OK”.
            </Typography>
            <Typography variant="subtitle1">
              Touching “Cancel” in a confirmation dialog, or pressing Back,
              cancels the action, discards any changes, and closes the dialog.
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

ConfirmationDialogCompo.propTypes = {
  className: PropTypes.string
};

export default ConfirmationDialogCompo;
