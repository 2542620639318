export default [
  'ri-pencil-line',
  'ri-pencil-fill',
  'ri-edit-line',
  'ri-edit-fill',
  'ri-edit-2-line',
  'ri-edit-2-fill',
  'ri-ball-pen-line',
  'ri-ball-pen-fill',
  'ri-quill-pen-line',
  'ri-quill-pen-fill',
  'ri-mark-pen-line',
  'ri-mark-pen-fill',
  'ri-markup-line',
  'ri-markup-fill',
  'ri-pen-nib-line',
  'ri-pen-nib-fill',
  'ri-edit-box-line',
  'ri-edit-box-fill',
  'ri-edit-circle-line',
  'ri-edit-circle-fill',
  'ri-sip-line',
  'ri-sip-fill',
  'ri-brush-line',
  'ri-brush-fill',
  'ri-brush-2-line',
  'ri-brush-2-fill',
  'ri-brush-3-line',
  'ri-brush-3-fill',
  'ri-brush-4-line',
  'ri-brush-4-fill',
  'ri-paint-brush-line',
  'ri-paint-brush-fill',
  'ri-contrast-line',
  'ri-contrast-fill',
  'ri-contrast-2-line',
  'ri-contrast-2-fill',
  'ri-drop-line',
  'ri-drop-fill',
  'ri-blur-off-line',
  'ri-blur-off-fill',
  'ri-contrast-drop-line',
  'ri-contrast-drop-fill',
  'ri-contrast-drop-2-line',
  'ri-contrast-drop-2-fill',
  'ri-compasses-line',
  'ri-compasses-fill',
  'ri-compasses-2-line',
  'ri-compasses-2-fill',
  'ri-scissors-line',
  'ri-scissors-fill',
  'ri-scissors-cut-line',
  'ri-scissors-cut-fill',
  'ri-scissors-2-line',
  'ri-scissors-2-fill',
  'ri-slice-line',
  'ri-slice-fill',
  'ri-eraser-line',
  'ri-eraser-fill',
  'ri-ruler-line',
  'ri-ruler-fill',
  'ri-ruler-2-line',
  'ri-ruler-2-fill',
  'ri-pencil-ruler-line',
  'ri-pencil-ruler-fill',
  'ri-pencil-ruler-2-line',
  'ri-pencil-ruler-2-fill',
  'ri-t-box-line',
  'ri-t-box-fill',
  'ri-input-method-line',
  'ri-input-method-fill',
  'ri-artboard-line',
  'ri-artboard-fill',
  'ri-artboard-2-line',
  'ri-artboard-2-fill',
  'ri-crop-line',
  'ri-crop-fill',
  'ri-crop-2-line',
  'ri-crop-2-fill',
  'ri-screenshot-line',
  'ri-screenshot-fill',
  'ri-screenshot-2-line',
  'ri-screenshot-2-fill',
  'ri-drag-move-line',
  'ri-drag-move-fill',
  'ri-drag-move-2-line',
  'ri-drag-move-2-fill',
  'ri-focus-line',
  'ri-focus-fill',
  'ri-focus-2-line',
  'ri-focus-2-fill',
  'ri-focus-3-line',
  'ri-focus-3-fill',
  'ri-paint-line',
  'ri-paint-fill',
  'ri-palette-line',
  'ri-palette-fill',
  'ri-pantone-line',
  'ri-pantone-fill',
  'ri-shape-line',
  'ri-shape-fill',
  'ri-shape-2-line',
  'ri-shape-2-fill',
  'ri-magic-line',
  'ri-magic-fill',
  'ri-anticlockwise-line',
  'ri-anticlockwise-fill',
  'ri-anticlockwise-2-line',
  'ri-anticlockwise-2-fill',
  'ri-clockwise-line',
  'ri-clockwise-fill',
  'ri-clockwise-2-line',
  'ri-clockwise-2-fill',
  'ri-hammer-line',
  'ri-hammer-fill',
  'ri-tools-line',
  'ri-tools-fill',
  'ri-drag-drop-line',
  'ri-drag-drop-fill',
  'ri-table-line',
  'ri-table-fill',
  'ri-table-alt-line',
  'ri-table-alt-fill',
  'ri-layout-line',
  'ri-layout-fill',
  'ri-layout-2-line',
  'ri-layout-2-fill',
  'ri-layout-3-line',
  'ri-layout-3-fill',
  'ri-layout-4-line',
  'ri-layout-4-fill',
  'ri-layout-5-line',
  'ri-layout-5-fill',
  'ri-layout-6-line',
  'ri-layout-6-fill',
  'ri-layout-column-line',
  'ri-layout-column-fill',
  'ri-layout-row-line',
  'ri-layout-row-fill',
  'ri-layout-top-line',
  'ri-layout-top-fill',
  'ri-layout-right-line',
  'ri-layout-right-fill',
  'ri-layout-bottom-line',
  'ri-layout-bottom-fill',
  'ri-layout-left-line',
  'ri-layout-left-fill',
  'ri-layout-top-2-line',
  'ri-layout-top-2-fill',
  'ri-layout-right-2-line',
  'ri-layout-right-2-fill',
  'ri-layout-bottom-2-line',
  'ri-layout-bottom-2-fill',
  'ri-layout-left-2-line',
  'ri-layout-left-2-fill',
  'ri-layout-grid-line',
  'ri-layout-grid-fill',
  'ri-layout-masonry-line',
  'ri-layout-masonry-fill',
  'ri-grid-line',
  'ri-grid-fill'
];
