import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Paper,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  makeStyles,
  Grid
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  cardbackground: {
    background: theme.palette.background.dark
  },
  grid: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }
}));

const BasicGrid = ({ className, ...rest }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card className={classes.cardbackground}>
        <CardHeader title={<Typography variant="h2">Basic Grid</Typography>} />
        <Divider />
        <CardContent className={classes.cardbackground}>
          <Box mt={1} mb={3}>
            <div className={classes.grid}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Paper className={classes.paper}>xs=12</Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper className={classes.paper}>xs=6</Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper className={classes.paper}>xs=6</Paper>
                </Grid>
                <Grid item xs={3}>
                  <Paper className={classes.paper}>xs=3</Paper>
                </Grid>
                <Grid item xs={3}>
                  <Paper className={classes.paper}>xs=3</Paper>
                </Grid>
                <Grid item xs={3}>
                  <Paper className={classes.paper}>xs=3</Paper>
                </Grid>
                <Grid item xs={3}>
                  <Paper className={classes.paper}>xs=3</Paper>
                </Grid>
              </Grid>
            </div>
          </Box>

          <Box mt={1}>
            <Typography variant="subtitle1">
              The column widths apply at all breakpoints (i.e. <b>xs</b> and
              up).
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

BasicGrid.propTypes = {
  className: PropTypes.string
};

export default BasicGrid;
