import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  TextField,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Input,
  InputLabel,
  InputAdornment,
  FormControl,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';

const useStyles = makeStyles(theme => ({
  root: {},
  margin: {
    margin: theme.spacing(1)
  }
}));

const IconTextField = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader title={<Typography variant="h2">Icons</Typography>} />
        <Divider />
        <CardContent>
          <Box mt={1} style={{ display: 'flex', justifyContent: 'center' }}>
            <div>
              <FormControl className={classes.margin}>
                <InputLabel htmlFor="input-with-icon-adornment">
                  With a start adornment
                </InputLabel>
                <Input
                  id="input-with-icon-adornment"
                  startAdornment={
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  }
                />
              </FormControl>
              <TextField
                className={classes.margin}
                id="input-with-icon-textfield"
                label="TextField"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  )
                }}
              />
              <div className={classes.margin}>
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item>
                    <AccountCircle />
                  </Grid>
                  <Grid item>
                    <TextField id="input-with-icon-grid" label="With a grid" />
                  </Grid>
                </Grid>
              </div>
            </div>
          </Box>

          <Box mt={1}>
            <Typography variant="subtitle1">
              There are multiple ways to display an icon with a text field.
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

IconTextField.propTypes = {
  className: PropTypes.string
};

export default IconTextField;
