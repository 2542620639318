export default [
  'ri-bold',
  'ri-italic',
  'ri-heading',
  'ri-text',
  'ri-font-color',
  'ri-font-size',
  'ri-font-size-2',
  'ri-underline',
  'ri-emphasis',
  'ri-emphasis-cn',
  'ri-strikethrough',
  'ri-strikethrough-2',
  'ri-format-clear',
  'ri-align-left',
  'ri-align-center',
  'ri-align-right',
  'ri-align-justify',
  'ri-align-top',
  'ri-align-vertically',
  'ri-align-bottom',
  'ri-list-check',
  'ri-list-check-2',
  'ri-list-ordered',
  'ri-list-unordered',
  'ri-indent-decrease',
  'ri-indent-increase',
  'ri-line-height',
  'ri-text-spacing',
  'ri-text-wrap',
  'ri-attachment-2',
  'ri-link',
  'ri-link-unlink',
  'ri-link-m',
  'ri-link-unlink-m',
  'ri-separator',
  'ri-space',
  'ri-page-separator',
  'ri-code-view',
  'ri-double-quotes-l',
  'ri-double-quotes-r',
  'ri-single-quotes-l',
  'ri-single-quotes-r',
  'ri-table-2',
  'ri-subscript',
  'ri-subscript-2',
  'ri-superscript',
  'ri-superscript-2',
  'ri-paragraph',
  'ri-text-direction-l',
  'ri-text-direction-r',
  'ri-functions',
  'ri-omega',
  'ri-hashtag',
  'ri-asterisk',
  'ri-translate',
  'ri-translate-2',
  'ri-a-b',
  'ri-english-input',
  'ri-pinyin-input',
  'ri-wubi-input',
  'ri-input-cursor-move',
  'ri-number-1',
  'ri-number-2',
  'ri-number-3',
  'ri-number-4',
  'ri-number-5',
  'ri-number-6',
  'ri-number-7',
  'ri-number-8',
  'ri-number-9',
  'ri-number-0',
  'ri-sort-asc',
  'ri-sort-desc',
  'ri-bring-forward',
  'ri-send-backward',
  'ri-bring-to-front',
  'ri-send-to-back'
];
