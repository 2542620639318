import React, { useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Container,
  makeStyles,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Checkbox,
  Avatar,
  Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
import getInitials from 'src/utils/getInitials';
import Toolbar from './Toolbar';
import { Search as SearchIcon } from 'react-feather';
import data from './data';

const useStyles = makeStyles(theme => ({
  tableRoot: {},
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CustomerListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const [search, SetSearch] = React.useState('');
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAll = event => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = data.map(customer => customer.id);
    } else {
      newSelectedCustomerIds = [];
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds,
        id
      );
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(1)
      );
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  return (
    <Page className={classes.root} title="Customers">
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={3}>
          <Card>
            <CardContent>
              <Box maxWidth={500}>
                <TextField
                  fullWidth
                  onChange={e => {
                    console.log('search', SetSearch(e.target.value));
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )
                  }}
                  placeholder="Search customer"
                  variant="outlined"
                />
              </Box>
            </CardContent>
          </Card>
        </Box>
        <Box mt={3}>
          <Card className={clsx(classes.tableRoot, className)} {...rest}>
            <PerfectScrollbar>
              <Box minWidth={1050}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedCustomerIds.length === data.length}
                          color="primary"
                          indeterminate={
                            selectedCustomerIds.length > 0 &&
                            selectedCustomerIds.length < data.length
                          }
                          onChange={handleSelectAll}
                        />
                      </TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Location</TableCell>
                      <TableCell>Phone</TableCell>
                      <TableCell>Registration date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .filter(data => {
                        if (search === '') return data;
                        else if (
                          data.name
                            .toString()
                            .toLowerCase()
                            .includes(search.toString().toLowerCase()) ||
                          data.email
                            .toString()
                            .toLowerCase()
                            .includes(search.toString().toLowerCase()) ||
                          data.address.city
                            .toString()
                            .toLowerCase()
                            .includes(search.toString().toLowerCase()) ||
                          data.address.state
                            .toString()
                            .toLowerCase()
                            .includes(search.toString().toLowerCase()) ||
                          data.address.country
                            .toString()
                            .toLowerCase()
                            .includes(search.toString().toLowerCase()) ||
                          data.phone
                            .toString()
                            .toLowerCase()
                            .includes(search.toString().toLowerCase()) ||
                          moment(data.createdAt)
                            .format('DD/MM/YYYY')
                            .toString()
                            .toLowerCase()
                            .includes(search.toString().toLowerCase())
                        ) {
                          return data;
                        }
                        return '';
                      })
                      .map(customer => (
                        <TableRow
                          hover
                          key={customer.id}
                          selected={
                            selectedCustomerIds.indexOf(customer.id) !== -1
                          }
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={
                                selectedCustomerIds.indexOf(customer.id) !== -1
                              }
                              onChange={event =>
                                handleSelectOne(event, customer.id)
                              }
                              value="true"
                            />
                          </TableCell>
                          <TableCell>
                            <Box alignItems="center" display="flex">
                              <Avatar
                                className={classes.avatar}
                                src={customer.avatarUrl}
                              >
                                {getInitials(customer.name)}
                              </Avatar>
                              &nbsp;
                              <Typography color="textPrimary" variant="body1">
                                {customer.name}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell>{customer.email}</TableCell>
                          <TableCell>
                            {`${customer.address.city}, ${customer.address.state}, ${customer.address.country}`}
                          </TableCell>
                          <TableCell>{customer.phone}</TableCell>
                          <TableCell>
                            {moment(customer.createdAt).format('DD/MM/YYYY')}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Box>
            </PerfectScrollbar>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Card>
        </Box>
      </Container>
    </Page>
  );
};

export default CustomerListView;
