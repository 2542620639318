import React from 'react';
import ReactApexChart from 'react-apexcharts';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {}
}));

const RadialChart = ({ className, ...rest }) => {
  const classes = useStyles();
  const [state] = React.useState({
    series: [44, 55, 67, 83],
    options: {
      chart: {
        height: 350,
        type: 'radialBar'
      },
      plotOptions: {
        radialBar: {
          dataLabels: {
            name: {
              fontSize: '22px'
            },
            value: {
              fontSize: '16px'
            },
            total: {
              show: true,
              label: 'Total',
              formatter: function(w) {
                // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                return 249;
              }
            }
          }
        }
      },
      labels: ['Apples', 'Oranges', 'Bananas', 'Berries']
    }
  });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader title="Radial Chart" />
        <Divider />
        <CardContent>
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <ReactApexChart
              options={state.options}
              series={state.series}
              type="radialBar"
              height={350}
              width={500}
            />
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

RadialChart.propTypes = {
  className: PropTypes.string
};

export default RadialChart;
