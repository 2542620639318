export default [
  'ri-map-pin-line',
  'ri-map-pin-fill',
  'ri-map-pin-2-line',
  'ri-map-pin-2-fill',
  'ri-map-pin-3-line',
  'ri-map-pin-3-fill',
  'ri-map-pin-4-line',
  'ri-map-pin-4-fill',
  'ri-map-pin-5-line',
  'ri-map-pin-5-fill',
  'ri-map-pin-add-line',
  'ri-map-pin-add-fill',
  'ri-map-pin-range-line',
  'ri-map-pin-range-fill',
  'ri-map-pin-time-line',
  'ri-map-pin-time-fill',
  'ri-map-pin-user-line',
  'ri-map-pin-user-fill',
  'ri-pin-distance-line',
  'ri-pin-distance-fill',
  'ri-pushpin-line',
  'ri-pushpin-fill',
  'ri-pushpin-2-line',
  'ri-pushpin-2-fill',
  'ri-compass-line',
  'ri-compass-fill',
  'ri-compass-2-line',
  'ri-compass-2-fill',
  'ri-compass-3-line',
  'ri-compass-3-fill',
  'ri-compass-4-line',
  'ri-compass-4-fill',
  'ri-compass-discover-line',
  'ri-compass-discover-fill',
  'ri-anchor-line',
  'ri-anchor-fill',
  'ri-china-railway-line',
  'ri-china-railway-fill',
  'ri-space-ship-line',
  'ri-space-ship-fill',
  'ri-rocket-line',
  'ri-rocket-fill',
  'ri-rocket-2-line',
  'ri-rocket-2-fill',
  'ri-map-line',
  'ri-map-fill',
  'ri-map-2-line',
  'ri-map-2-fill',
  'ri-treasure-map-line',
  'ri-treasure-map-fill',
  'ri-road-map-line',
  'ri-road-map-fill',
  'ri-earth-line',
  'ri-earth-fill',
  'ri-globe-line',
  'ri-globe-fill',
  'ri-parking-line',
  'ri-parking-fill',
  'ri-parking-box-line',
  'ri-parking-box-fill',
  'ri-route-line',
  'ri-route-fill',
  'ri-guide-line',
  'ri-guide-fill',
  'ri-gas-station-line',
  'ri-gas-station-fill',
  'ri-charging-pile-line',
  'ri-charging-pile-fill',
  'ri-charging-pile-2-line',
  'ri-charging-pile-2-fill',
  'ri-car-line',
  'ri-car-fill',
  'ri-car-washing-line',
  'ri-car-washing-fill',
  'ri-roadster-line',
  'ri-roadster-fill',
  'ri-taxi-line',
  'ri-taxi-fill',
  'ri-taxi-wifi-line',
  'ri-taxi-wifi-fill',
  'ri-police-car-line',
  'ri-police-car-fill',
  'ri-bus-line',
  'ri-bus-fill',
  'ri-bus-2-line',
  'ri-bus-2-fill',
  'ri-bus-wifi-line',
  'ri-bus-wifi-fill',
  'ri-truck-line',
  'ri-truck-fill',
  'ri-train-line',
  'ri-train-fill',
  'ri-train-wifi-line',
  'ri-train-wifi-fill',
  'ri-subway-line',
  'ri-subway-fill',
  'ri-subway-wifi-line',
  'ri-subway-wifi-fill',
  'ri-flight-takeoff-line',
  'ri-flight-takeoff-fill',
  'ri-flight-land-line',
  'ri-flight-land-fill',
  'ri-plane-line',
  'ri-plane-fill',
  'ri-sailboat-line',
  'ri-sailboat-fill',
  'ri-ship-line',
  'ri-ship-fill',
  'ri-ship-2-line',
  'ri-ship-2-fill',
  'ri-bike-line',
  'ri-bike-fill',
  'ri-e-bike-line',
  'ri-e-bike-fill',
  'ri-e-bike-2-line',
  'ri-e-bike-2-fill',
  'ri-takeaway-line',
  'ri-takeaway-fill',
  'ri-motorbike-line',
  'ri-motorbike-fill',
  'ri-caravan-line',
  'ri-caravan-fill',
  'ri-walk-line',
  'ri-walk-fill',
  'ri-run-line',
  'ri-run-fill',
  'ri-riding-line',
  'ri-riding-fill',
  'ri-barricade-line',
  'ri-barricade-fill',
  'ri-footprint-line',
  'ri-footprint-fill',
  'ri-traffic-light-line',
  'ri-traffic-light-fill',
  'ri-signal-tower-line',
  'ri-signal-tower-fill',
  'ri-restaurant-line',
  'ri-restaurant-fill',
  'ri-restaurant-2-line',
  'ri-restaurant-2-fill',
  'ri-cup-line',
  'ri-cup-fill',
  'ri-goblet-line',
  'ri-goblet-fill',
  'ri-hotel-bed-line',
  'ri-hotel-bed-fill',
  'ri-navigation-line',
  'ri-navigation-fill',
  'ri-oil-line',
  'ri-oil-fill',
  'ri-direction-line',
  'ri-direction-fill',
  'ri-steering-line',
  'ri-steering-fill',
  'ri-steering-2-line',
  'ri-steering-2-fill',
  'ri-lifebuoy-line',
  'ri-lifebuoy-fill',
  'ri-passport-line',
  'ri-passport-fill',
  'ri-suitcase-line',
  'ri-suitcase-fill',
  'ri-suitcase-2-line',
  'ri-suitcase-2-fill',
  'ri-suitcase-3-line',
  'ri-suitcase-3-fill',
  'ri-luggage-deposit-line',
  'ri-luggage-deposit-fill',
  'ri-luggage-cart-line',
  'ri-luggage-cart-fill'
];
