import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  makeStyles,
  TextField
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  color: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch'
    }
  },
  code: {
    width: '100%',
    paddingTop: '15px',
    background: '#272C34',
    color: '#fff',
    marginTop: '10px',
    borderRadius: '4px',
    overflowX: 'scroll'
  }
}));

const ColorTextField = ({ className, ...rest }) => {
  const classes = useStyles();
  var code = `<form className={classes.root} noValidate autoComplete="off">
  <TextField id="standard-secondary" label="Standard secondary" color="secondary" />
  <TextField
    id="filled-secondary"
    label="Filled secondary"
    variant="filled"
    color="secondary"
  />
  <TextField
    id="outlined-secondary"
    label="Outlined secondary"
    variant="outlined"
    color="secondary"
  />
</form>`;
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader title={<Typography variant="h2">Color</Typography>} />
        <Divider />
        <CardContent>
          <Box mt={1} style={{ display: 'flex', justifyContent: 'center' }}>
            <form className={classes.color} noValidate autoComplete="off">
              <TextField
                id="standard-secondary"
                label="Standard secondary"
                color="secondary"
              />
              <TextField
                id="filled-secondary"
                label="Filled secondary"
                variant="filled"
                color="secondary"
              />
              <TextField
                id="outlined-secondary"
                label="Outlined secondary"
                variant="outlined"
                color="secondary"
              />
            </form>
          </Box>
          <Box className={classes.code}>
            <Container>
              <pre>
                <Typography
                  variant="subtitle2"
                  style={{ fontFamily: 'monospace', fontSize: 14 }}
                >
                  {code}
                </Typography>
              </pre>
            </Container>
          </Box>
          <Box mt={1}>
            <Typography variant="subtitle1">
              The <b>color</b> prop changes the highlight color of the text
              field when focused.
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

ColorTextField.propTypes = {
  className: PropTypes.string
};

export default ColorTextField;
