import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  text: {
    width: '100%',
    maxWidth: 500
  },
  code: {
    width: '100%',
    paddingTop: '15px',
    background: '#272C34',
    color: '#fff',
    marginTop: '10px',
    borderRadius: '4px',
    overflowX: 'scroll'
  }
}));

const TypographyComponent = ({ className, ...rest }) => {
  const classes = useStyles();

  var code = `<Typography variant="h1" component="h2">
  h1. Heading
</Typography>`;
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader title={<Typography variant="h2">Typography</Typography>} />
        <Divider />
        <CardContent>
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <div className={classes.text}>
              <Typography variant="h1" component="h2" gutterBottom>
                h1. Heading
              </Typography>
              <Typography variant="h2" gutterBottom>
                h2. Heading
              </Typography>
              <Typography variant="h3" gutterBottom>
                h3. Heading
              </Typography>
              <Typography variant="h4" gutterBottom>
                h4. Heading
              </Typography>
              <Typography variant="h5" gutterBottom>
                h5. Heading
              </Typography>
              <Typography variant="h6" gutterBottom>
                h6. Heading
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing
                elit. Quos blanditiis tenetur
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                subtitle2. Lorem ipsum dolor sit amet, consectetur adipisicing
                elit. Quos blanditiis tenetur
              </Typography>
              <Typography variant="body1" gutterBottom>
                body1. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Quos blanditiis tenetur unde suscipit, quam beatae rerum
                inventore consectetur, neque doloribus, cupiditate numquam
                dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.
              </Typography>
              <Typography variant="body2" gutterBottom>
                body2. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Quos blanditiis tenetur unde suscipit, quam beatae rerum
                inventore consectetur, neque doloribus, cupiditate numquam
                dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.
              </Typography>
              <Typography variant="button" display="block" gutterBottom>
                button text
              </Typography>
              <Typography variant="caption" display="block" gutterBottom>
                caption text
              </Typography>
              <Typography variant="overline" display="block" gutterBottom>
                overline text
              </Typography>
            </div>
          </Box>
          <Box className={classes.code}>
            <Container>
              <pre>
                <Typography
                  variant="subtitle2"
                  style={{ fontFamily: 'monospace', fontSize: 14 }}
                >
                  {code}
                </Typography>
              </pre>
            </Container>
          </Box>
          <Box mt={1}>
            <Typography variant="subtitle1">
              Use typography to present your design and content as clearly and
              efficiently as possible.
            </Typography>
            <Typography variant="subtitle1">
              Too many type sizes and styles at once can spoil any layout. A
              typographic scale has a limited set of type sizes that work well
              together along with the layout grid.
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

TypographyComponent.propTypes = {
  className: PropTypes.string
};

export default TypographyComponent;
