import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Card,
  Grid,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ForgetPasswordOTPVerify = props => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <Page className={classes.root} title="Change Password">
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '100%' }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              password: '',
              changepassword: ''
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string()
                .min(8, 'Atleast 8 characters needed')
                .required('Password is required'),
              changepassword: Yup.string()
                .min(8, 'Atleast 8 characters needed')
                .required('Confirm Password Required')
                .when('password', {
                  is: val => (val && val.length > 0 ? true : false),
                  then: Yup.string().oneOf(
                    [Yup.ref('password')],
                    'Password and Confirm Password Not Matched !'
                  )
                })
            })}
            onSubmit={data => {
              console.log('data', data);
              navigate('/', { replace: true });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Card>
                  <CardHeader
                    title={
                      <Typography color="textPrimary" variant="h2">
                        New Password
                      </Typography>
                    }
                  />
                  <Divider />
                  <CardContent>
                    <TextField
                      error={Boolean(touched.password && errors.password)}
                      fullWidth
                      helperText={touched.password && errors.password}
                      label="Password"
                      margin="normal"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.password}
                      variant="outlined"
                      autoComplete="new-password"
                    />
                    <TextField
                      error={Boolean(
                        touched.changepassword && errors.changepassword
                      )}
                      fullWidth
                      helperText={
                        touched.changepassword && errors.changepassword
                      }
                      label="Confirm Password"
                      margin="normal"
                      name="changepassword"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.changepassword}
                      variant="outlined"
                      autoComplete="new-password"
                    />
                    <Box my={2} align="center">
                      <Button
                        color="primary"
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        style={{ color: '#fff' }}
                      >
                        submit
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </form>
            )}
          </Formik>
        </Container>
      </Grid>
    </Page>
  );
};

export default ForgetPasswordOTPVerify;
