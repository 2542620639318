export default [
  'ri-sun-line',
  'ri-sun-fill',
  'ri-moon-line',
  'ri-moon-fill',
  'ri-flashlight-line',
  'ri-flashlight-fill',
  'ri-cloudy-line',
  'ri-cloudy-fill',
  'ri-cloudy-2-line',
  'ri-cloudy-2-fill',
  'ri-mist-line',
  'ri-mist-fill',
  'ri-foggy-line',
  'ri-foggy-fill',
  'ri-cloud-windy-line',
  'ri-cloud-windy-fill',
  'ri-windy-line',
  'ri-windy-fill',
  'ri-rainy-line',
  'ri-rainy-fill',
  'ri-drizzle-line',
  'ri-drizzle-fill',
  'ri-showers-line',
  'ri-showers-fill',
  'ri-heavy-showers-line',
  'ri-heavy-showers-fill',
  'ri-thunderstorms-line',
  'ri-thunderstorms-fill',
  'ri-hail-line',
  'ri-hail-fill',
  'ri-snowy-line',
  'ri-snowy-fill',
  'ri-sun-cloudy-line',
  'ri-sun-cloudy-fill',
  'ri-moon-cloudy-line',
  'ri-moon-cloudy-fill',
  'ri-tornado-line',
  'ri-tornado-fill',
  'ri-typhoon-line',
  'ri-typhoon-fill',
  'ri-haze-line',
  'ri-haze-fill',
  'ri-haze-2-line',
  'ri-haze-2-fill',
  'ri-sun-foggy-line',
  'ri-sun-foggy-fill',
  'ri-moon-foggy-line',
  'ri-moon-foggy-fill',
  'ri-moon-clear-line',
  'ri-moon-clear-fill',
  'ri-temp-hot-line',
  'ri-temp-hot-fill',
  'ri-temp-cold-line',
  'ri-temp-cold-fill',
  'ri-celsius-line',
  'ri-celsius-fill',
  'ri-fahrenheit-line',
  'ri-fahrenheit-fill',
  'ri-fire-line',
  'ri-fire-fill',
  'ri-blaze-line',
  'ri-blaze-fill',
  'ri-earthquake-line',
  'ri-earthquake-fill',
  'ri-flood-line',
  'ri-flood-fill',
  'ri-meteor-line',
  'ri-meteor-fill',
  'ri-rainbow-line',
  'ri-rainbow-fill'
];
