import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  makeStyles,
  Slider
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  slider: {
    width: 300
  },
  code: {
    width: '100%',
    paddingTop: '15px',
    background: '#272C34',
    color: '#fff',
    marginTop: '10px',
    borderRadius: '4px',
    overflowX: 'scroll'
  }
}));

function valuetext(value) {
  return `${value}°C`;
}

const OutlinedButtons = ({ className, ...rest }) => {
  const classes = useStyles();
  var code = `<Typography id="discrete-slider-small-steps" gutterBottom>
  Small steps
</Typography>
<Slider
  defaultValue={0.00000005}
  getAriaValueText={valuetext}
  aria-labelledby="discrete-slider-small-steps"
  step={0.00000001}
  marks
  min={-0.00000005}
  max={0.0000001}
  valueLabelDisplay="auto"
/>`;
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader title={<Typography variant="h2">Small steps</Typography>} />
        <Divider />
        <CardContent>
          <Box mt={1} style={{ display: 'flex', justifyContent: 'center' }}>
            <div className={classes.slider}>
              <Typography id="discrete-slider-small-steps" gutterBottom>
                Small steps
              </Typography>
              <Slider
                defaultValue={0.00000005}
                getAriaValueText={valuetext}
                aria-labelledby="discrete-slider-small-steps"
                step={0.00000001}
                marks
                min={-0.00000005}
                max={0.0000001}
                valueLabelDisplay="auto"
              />
            </div>
          </Box>
          <Box className={classes.code}>
            <Container>
              <pre>
                <Typography
                  variant="subtitle2"
                  style={{ fontFamily: 'monospace', fontSize: 14 }}
                >
                  {code}
                </Typography>
              </pre>
            </Container>
          </Box>
          <Box mt={1}>
            <Typography variant="subtitle1">
              You can change the default step increment.
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

OutlinedButtons.propTypes = {
  className: PropTypes.string
};

export default OutlinedButtons;
