import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
  colors
} from '@material-ui/core';
import {
  Sparklines,
  SparklinesLine,
  SparklinesNormalBand,
  SparklinesReferenceLine
} from 'react-sparklines';
import InsertChartIcon from '@material-ui/icons/InsertChartOutlined';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.orange[600],
    height: 56,
    width: 56
  }
}));

const TasksProgress = ({ className, ...rest }) => {
  const classes = useStyles();
  function boxMullerRandom() {
    let phase = false,
      x1,
      x2,
      w;

    return (function() {
      if ((phase = !phase)) {
        do {
          x1 = 2.0 * Math.random() - 1.0;
          x2 = 2.0 * Math.random() - 1.0;
          w = x1 * x1 + x2 * x2;
        } while (w >= 1.0);

        w = Math.sqrt((-2.0 * Math.log(w)) / w);
        return x1 * w;
      } else {
        return x2 * w;
      }
    })();
  }
  function randomData(n = 20) {
    return Array.apply(0, Array(n)).map(boxMullerRandom);
  }
  const sampleData = randomData(20);
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Box mb={4}>
          <Sparklines data={sampleData}>
            <SparklinesLine style={{ fill: 'none' }} />
            <SparklinesNormalBand
              style={{ fill: '#2991c8', fillOpacity: 0.1 }}
            />
            <SparklinesReferenceLine type="mean" />
          </Sparklines>
        </Box>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              TOTAL EMAIL SENT
            </Typography>
            <Typography color="textPrimary" variant="h3">
              2,355
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <InsertChartIcon />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

TasksProgress.propTypes = {
  className: PropTypes.string
};

export default TasksProgress;
