import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Container,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';

const useStyles = makeStyles(theme => ({
  root: {},

  code: {
    width: '100%',
    paddingTop: '15px',
    background: '#272C34',
    color: '#fff',
    marginTop: '10px',
    borderRadius: '4px',
    overflowX: 'scroll'
  }
}));

const PositionsAlert = ({ className, ...rest }) => {
  const classes = useStyles();

  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  });

  const { vertical, horizontal, open } = state;

  const handleClick = newState => () => {
    setState({ open: true, ...newState });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const buttons = (
    <React.Fragment>
      <Button onClick={handleClick({ vertical: 'top', horizontal: 'center' })}>
        Top-Center
      </Button>
      <Button onClick={handleClick({ vertical: 'top', horizontal: 'right' })}>
        Top-Right
      </Button>
      <Button
        onClick={handleClick({ vertical: 'bottom', horizontal: 'right' })}
      >
        Bottom-Right
      </Button>
      <Button
        onClick={handleClick({ vertical: 'bottom', horizontal: 'center' })}
      >
        Bottom-Center
      </Button>
      <Button onClick={handleClick({ vertical: 'bottom', horizontal: 'left' })}>
        Bottom-Left
      </Button>
      <Button onClick={handleClick({ vertical: 'top', horizontal: 'left' })}>
        Top-Left
      </Button>
    </React.Fragment>
  );

  var code = `{buttons}
  <Snackbar
    anchorOrigin={{ vertical, horizontal }}
    open={open}
    onClose={handleClose}
    message="I love snacks"
    key={vertical + horizontal}
  />`;
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader
          title={<Typography variant="h2">Positioned Alerts</Typography>}
        />
        <Divider />
        <CardContent>
          <Box
            mt={1}
            mb={2}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <div>
              {buttons}
              <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                onClose={handleClose}
                message="I love snacks"
                key={vertical + horizontal}
              />
            </div>
          </Box>
          <Box className={classes.code}>
            <Container>
              <pre>
                <Typography
                  variant="subtitle2"
                  style={{ fontFamily: 'monospace', fontSize: 14 }}
                >
                  {code}
                </Typography>
              </pre>
            </Container>
          </Box>
          <Box mt={1}>
            <Typography variant="subtitle1">
              In wide layouts, snackbars can be left-aligned or center-aligned
              if they are consistently placed on the same spot at the bottom of
              the screen, however there may be circumstances where the placement
              of the snackbar needs to be more flexible. You can control the
              position of the snackbar by specifying the <b>anchorOrigin </b>
              prop.
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

PositionsAlert.propTypes = {
  className: PropTypes.string
};

export default PositionsAlert;
