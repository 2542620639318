export default [
  'ri-image-line',
  'ri-image-fill',
  'ri-image-2-line',
  'ri-image-2-fill',
  'ri-image-add-line',
  'ri-image-add-fill',
  'ri-landscape-line',
  'ri-landscape-fill',
  'ri-gallery-line',
  'ri-gallery-fill',
  'ri-gallery-upload-line',
  'ri-gallery-upload-fill',
  'ri-video-line',
  'ri-video-fill',
  'ri-movie-line',
  'ri-movie-fill',
  'ri-movie-2-line',
  'ri-movie-2-fill',
  'ri-film-line',
  'ri-film-fill',
  'ri-clapperboard-line',
  'ri-clapperboard-fill',
  'ri-vidicon-line',
  'ri-vidicon-fill',
  'ri-vidicon-2-line',
  'ri-vidicon-2-fill',
  'ri-live-line',
  'ri-live-fill',
  'ri-video-add-line',
  'ri-video-add-fill',
  'ri-video-upload-line',
  'ri-video-upload-fill',
  'ri-video-download-line',
  'ri-video-download-fill',
  'ri-dv-line',
  'ri-dv-fill',
  'ri-camera-line',
  'ri-camera-fill',
  'ri-camera-off-line',
  'ri-camera-off-fill',
  'ri-camera-2-line',
  'ri-camera-2-fill',
  'ri-camera-3-line',
  'ri-camera-3-fill',
  'ri-camera-lens-line',
  'ri-camera-lens-fill',
  'ri-camera-switch-line',
  'ri-camera-switch-fill',
  'ri-polaroid-line',
  'ri-polaroid-fill',
  'ri-polaroid-2-line',
  'ri-polaroid-2-fill',
  'ri-phone-camera-line',
  'ri-phone-camera-fill',
  'ri-webcam-line',
  'ri-webcam-fill',
  'ri-mv-line',
  'ri-mv-fill',
  'ri-music-line',
  'ri-music-fill',
  'ri-music-2-line',
  'ri-music-2-fill',
  'ri-disc-line',
  'ri-disc-fill',
  'ri-album-line',
  'ri-album-fill',
  'ri-dvd-line',
  'ri-dvd-fill',
  'ri-headphone-line',
  'ri-headphone-fill',
  'ri-radio-line',
  'ri-radio-fill',
  'ri-radio-2-line',
  'ri-radio-2-fill',
  'ri-tape-line',
  'ri-tape-fill',
  'ri-mic-line',
  'ri-mic-fill',
  'ri-mic-2-line',
  'ri-mic-2-fill',
  'ri-mic-off-line',
  'ri-mic-off-fill',
  'ri-volume-down-line',
  'ri-volume-down-fill',
  'ri-volume-mute-line',
  'ri-volume-mute-fill',
  'ri-volume-up-line',
  'ri-volume-up-fill',
  'ri-volume-vibrate-line',
  'ri-volume-vibrate-fill',
  'ri-volume-off-vibrate-line',
  'ri-volume-off-vibrate-fill',
  'ri-speaker-line',
  'ri-speaker-fill',
  'ri-speaker-2-line',
  'ri-speaker-2-fill',
  'ri-speaker-3-line',
  'ri-speaker-3-fill',
  'ri-surround-sound-line',
  'ri-surround-sound-fill',
  'ri-broadcast-line',
  'ri-broadcast-fill',
  'ri-notification-line',
  'ri-notification-fill',
  'ri-notification-2-line',
  'ri-notification-2-fill',
  'ri-notification-3-line',
  'ri-notification-3-fill',
  'ri-notification-4-line',
  'ri-notification-4-fill',
  'ri-notification-off-line',
  'ri-notification-off-fill',
  'ri-play-circle-line',
  'ri-play-circle-fill',
  'ri-pause-circle-line',
  'ri-pause-circle-fill',
  'ri-record-circle-line',
  'ri-record-circle-fill',
  'ri-stop-circle-line',
  'ri-stop-circle-fill',
  'ri-eject-line',
  'ri-eject-fill',
  'ri-play-line',
  'ri-play-fill',
  'ri-pause-line',
  'ri-pause-fill',
  'ri-stop-line',
  'ri-stop-fill',
  'ri-rewind-line',
  'ri-rewind-fill',
  'ri-speed-line',
  'ri-speed-fill',
  'ri-skip-back-line',
  'ri-skip-back-fill',
  'ri-skip-forward-line',
  'ri-skip-forward-fill',
  'ri-play-mini-line',
  'ri-play-mini-fill',
  'ri-pause-mini-line',
  'ri-pause-mini-fill',
  'ri-stop-mini-line',
  'ri-stop-mini-fill',
  'ri-rewind-mini-line',
  'ri-rewind-mini-fill',
  'ri-speed-mini-line',
  'ri-speed-mini-fill',
  'ri-skip-back-mini-line',
  'ri-skip-back-mini-fill',
  'ri-skip-forward-mini-line',
  'ri-skip-forward-mini-fill',
  'ri-repeat-line',
  'ri-repeat-fill',
  'ri-repeat-2-line',
  'ri-repeat-2-fill',
  'ri-repeat-one-line',
  'ri-repeat-one-fill',
  'ri-order-play-line',
  'ri-order-play-fill',
  'ri-shuffle-line',
  'ri-shuffle-fill',
  'ri-play-list-line',
  'ri-play-list-fill',
  'ri-play-list-add-line',
  'ri-play-list-add-fill',
  'ri-fullscreen-line',
  'ri-fullscreen-fill',
  'ri-fullscreen-exit-line',
  'ri-fullscreen-exit-fill',
  'ri-equalizer-line',
  'ri-equalizer-fill',
  'ri-sound-module-line',
  'ri-sound-module-fill',
  'ri-rhythm-line',
  'ri-rhythm-fill',
  'ri-voiceprint-line',
  'ri-voiceprint-fill',
  'ri-hq-line',
  'ri-hq-fill',
  'ri-hd-line',
  'ri-hd-fill',
  'ri-4k-line',
  'ri-4k-fill',
  'ri-aspect-ratio-line',
  'ri-aspect-ratio-fill',
  'ri-picture-in-picture-line',
  'ri-picture-in-picture-fill',
  'ri-picture-in-picture-2-line',
  'ri-picture-in-picture-2-fill',
  'ri-picture-in-picture-exit-line',
  'ri-picture-in-picture-exit-fill'
];
