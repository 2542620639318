import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';
import UploadButtons from './upload';

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  button: {
    '& > *': {
      margin: theme.spacing(1)
    }
  }
}));

const ButtonComponent = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <form className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader
          title={<Typography variant="h2">Upload Buttons</Typography>}
          subheader=""
        />
        <Divider />
        <CardContent>
          <Box mt={1}>
            <div className={classes.button}>
              <UploadButtons />
            </div>
          </Box>
        </CardContent>
      </Card>
    </form>
  );
};

ButtonComponent.propTypes = {
  className: PropTypes.string
};

export default ButtonComponent;
