import React from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import BasicAlert from './basic';
import TitleWithText from './titleWithText';
import ShowSuccessMsg from './shwSuccessMsg';
import ShowErrorMsgFooter from './shwErrorMsgFooter';
import ImgWithMsg from './imgWithMsg';
import CustomHTMLmsg from './customHTMLmsg';
import CustomPosition from './customPosition';
import AutoCloseTimer from './autoCloseTimer';
import AdvanceAlert from './advanceAlert';
import ToastAlerts from './toastAlerts';
import AlertTypes from './alertTypes';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const SweetAlertIndex = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Sweet Alert">
      <Container maxWidth="lg">
        <Box>
          <BasicAlert />
        </Box>
        <Box mt={3}>
          <TitleWithText />
        </Box>
        <Box mt={3}>
          <ShowSuccessMsg />
        </Box>
        <Box mt={3}>
          <ShowErrorMsgFooter />
        </Box>
        <Box mt={3}>
          <ImgWithMsg />
        </Box>
        <Box mt={3}>
          <CustomHTMLmsg />
        </Box>
        <Box mt={3}>
          <CustomPosition />
        </Box>
        <Box mt={3}>
          <AutoCloseTimer />
        </Box>
        <Box mt={3}>
          <AdvanceAlert />
        </Box>
        <Box mt={3}>
          <ToastAlerts />
        </Box>
        <Box mt={3}>
          <AlertTypes />
        </Box>
      </Container>
    </Page>
  );
};

export default SweetAlertIndex;
