export default [
  'ri-alipay-line',
  'ri-alipay-fill',
  'ri-amazon-line',
  'ri-amazon-fill',
  'ri-android-line',
  'ri-android-fill',
  'ri-angularjs-line',
  'ri-angularjs-fill',
  'ri-app-store-line',
  'ri-app-store-fill',
  'ri-apple-line',
  'ri-apple-fill',
  'ri-baidu-line',
  'ri-baidu-fill',
  'ri-behance-line',
  'ri-behance-fill',
  'ri-bilibili-line',
  'ri-bilibili-fill',
  'ri-centos-line',
  'ri-centos-fill',
  'ri-chrome-line',
  'ri-chrome-fill',
  'ri-codepen-line',
  'ri-codepen-fill',
  'ri-coreos-line',
  'ri-coreos-fill',
  'ri-dingding-line',
  'ri-dingding-fill',
  'ri-discord-line',
  'ri-discord-fill',
  'ri-disqus-line',
  'ri-disqus-fill',
  'ri-douban-line',
  'ri-douban-fill',
  'ri-dribbble-line',
  'ri-dribbble-fill',
  'ri-drive-line',
  'ri-drive-fill',
  'ri-dropbox-line',
  'ri-dropbox-fill',
  'ri-edge-line',
  'ri-edge-fill',
  'ri-evernote-line',
  'ri-evernote-fill',
  'ri-facebook-line',
  'ri-facebook-fill',
  'ri-facebook-circle-line',
  'ri-facebook-circle-fill',
  'ri-facebook-box-line',
  'ri-facebook-box-fill',
  'ri-firefox-line',
  'ri-firefox-fill',
  'ri-flutter-line',
  'ri-flutter-fill',
  'ri-gatsby-line',
  'ri-gatsby-fill',
  'ri-github-line',
  'ri-github-fill',
  'ri-gitlab-line',
  'ri-gitlab-fill',
  'ri-google-line',
  'ri-google-fill',
  'ri-google-play-line',
  'ri-google-play-fill',
  'ri-honor-of-kings-line',
  'ri-honor-of-kings-fill',
  'ri-ie-line',
  'ri-ie-fill',
  'ri-instagram-line',
  'ri-instagram-fill',
  'ri-invision-line',
  'ri-invision-fill',
  'ri-kakao-talk-line',
  'ri-kakao-talk-fill',
  'ri-line-line',
  'ri-line-fill',
  'ri-linkedin-line',
  'ri-linkedin-fill',
  'ri-linkedin-box-line',
  'ri-linkedin-box-fill',
  'ri-mastercard-line',
  'ri-mastercard-fill',
  'ri-mastodon-line',
  'ri-mastodon-fill',
  'ri-medium-line',
  'ri-medium-fill',
  'ri-messenger-line',
  'ri-messenger-fill',
  'ri-mini-program-line',
  'ri-mini-program-fill',
  'ri-netease-cloud-music-line',
  'ri-netease-cloud-music-fill',
  'ri-netflix-line',
  'ri-netflix-fill',
  'ri-npmjs-line',
  'ri-npmjs-fill',
  'ri-open-source-line',
  'ri-open-source-fill',
  'ri-opera-line',
  'ri-opera-fill',
  'ri-patreon-line',
  'ri-patreon-fill',
  'ri-paypal-line',
  'ri-paypal-fill',
  'ri-pinterest-line',
  'ri-pinterest-fill',
  'ri-pixelfed-line',
  'ri-pixelfed-fill',
  'ri-playstation-line',
  'ri-playstation-fill',
  'ri-product-hunt-line',
  'ri-product-hunt-fill',
  'ri-qq-line',
  'ri-qq-fill',
  'ri-reactjs-line',
  'ri-reactjs-fill',
  'ri-reddit-line',
  'ri-reddit-fill',
  'ri-remixicon-line',
  'ri-remixicon-fill',
  'ri-safari-line',
  'ri-safari-fill',
  'ri-skype-line',
  'ri-skype-fill',
  'ri-slack-line',
  'ri-slack-fill',
  'ri-snapchat-line',
  'ri-snapchat-fill',
  'ri-soundcloud-line',
  'ri-soundcloud-fill',
  'ri-spectrum-line',
  'ri-spectrum-fill',
  'ri-spotify-line',
  'ri-spotify-fill',
  'ri-stack-overflow-line',
  'ri-stack-overflow-fill',
  'ri-stackshare-line',
  'ri-stackshare-fill',
  'ri-steam-line',
  'ri-steam-fill',
  'ri-switch-line',
  'ri-switch-fill',
  'ri-taobao-line',
  'ri-taobao-fill',
  'ri-telegram-line',
  'ri-telegram-fill',
  'ri-trello-line',
  'ri-trello-fill',
  'ri-tumblr-line',
  'ri-tumblr-fill',
  'ri-twitch-line',
  'ri-twitch-fill',
  'ri-twitter-line',
  'ri-twitter-fill',
  'ri-ubuntu-line',
  'ri-ubuntu-fill',
  'ri-unsplash-line',
  'ri-unsplash-fill',
  'ri-visa-line',
  'ri-visa-fill',
  'ri-vuejs-line',
  'ri-vuejs-fill',
  'ri-wechat-line',
  'ri-wechat-fill',
  'ri-wechat-2-line',
  'ri-wechat-2-fill',
  'ri-wechat-pay-line',
  'ri-wechat-pay-fill',
  'ri-weibo-line',
  'ri-weibo-fill',
  'ri-whatsapp-line',
  'ri-whatsapp-fill',
  'ri-windows-line',
  'ri-windows-fill',
  'ri-xbox-line',
  'ri-xbox-fill',
  'ri-xing-line',
  'ri-xing-fill',
  'ri-youtube-line',
  'ri-youtube-fill',
  'ri-zcool-line',
  'ri-zcool-fill',
  'ri-zhihu-line',
  'ri-zhihu-fill'
];
