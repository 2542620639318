import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AccountView from 'src/views/account/AccountView';
import ApexCharts from 'src/views/Charts/ApexCharts/index';
import AlertsView from 'src/views/components/Alerts/index';
import BasicTable from 'src/views/Table/BasicTable/index';
import ButtonView from 'src/views/components/buttons/index';
import ButtonGroupView from 'src/views/components/buttonGroup/index';
import Cards from 'src/views/components/Cards/index';
import Calender from 'src/views/Calender/index';
import ChartJs from 'src/views/Charts/chartjs/index';
import ChatComponent from 'src/views/Chat/index';
import Checkout from 'src/views/Checkout/index';
import CustomizedTable from 'src/views/Table/Customized/index';
import CKEditor from 'src/views/Editors/CKEditor/editor';
import DataTable from 'src/views/Table/DataTable/index';
import DateAndTimeView from 'src/views/components/DateAndTime/index';
import DenseTable from 'src/views/Table/DenseTable/index';
import DialogView from 'src/views/components/Dialog/index';
import DripIcons from 'src/views/Icons/DripIcons/index';
import KanbanBoard from 'src/views/KanbanBoard/index';
import GridComponent from 'src/views/components/Grid/index';
import ImagesComponent from 'src/views/components/Images/index';
import SelectView from 'src/views/components/Select/index';
import CustomerListView from 'src/views/customer/CustomerListView';
import CreditCardForm from 'src/views/CreditCard/index';
import DashboardView from 'src/views/reports/DashboardView';
import EcommerceView from 'src/views/Ecommerce/index';
import WYSISWYG from 'src/views/Editors/WYSISWYG/editor';
import TimeLineView from 'src/views/Utility/timeline';
import SliderComponent from 'src/views/components/Slider/index';
import FileUploadForm from 'src/views/Forms/fileUpload/index.js';
import FullScreenMap from 'src/views/Maps/FullScreenMap/index';
import RemixIcons from 'src/views/Icons/Remix/index';
import LoginView from 'src/views/auth/LoginView';
import ForgotPassword from 'src/views/auth/ForgotPassword';
import ForgotPasswordChange from 'src/views/auth/ForgotPasswordChange';
import GoogleMap from 'src/views/Maps/Google/index';
import OtpVerifyView from 'src/views/auth/OtpVerify';
import MaintenanceView from 'src/views/Utility/maintenance';
import FontAwesomeIcons from 'src/views/Icons/FontAwesome/index';
import ComingSoonView from 'src/views/Utility/comingSoon';
import NotFoundView from 'src/views/errors/NotFoundView';
import ProductListView from 'src/views/product/ProductListView';
import SparklineChart from 'src/views/Charts/Sparkline/index';
import RegisterView from 'src/views/auth/RegisterView';
import MailComponent from 'src/views/MailBox/index';
import ProgressBarsView from 'src/views/components/ProgressBars/index';
import SettingsView from 'src/views/settings/SettingsView';
import SortingAndSelecting from 'src/views/Table/Sorting/index';
import PricingView from 'src/views/Utility/pricing';
import QRGenerateComponent from 'src/views/QR/index';
import SweetAlertComponent from 'src/views/SweetAlert/index';
import TextFieldView from 'src/views/components/TextField/index';
import InternalServerError from 'src/views/Utility/500';
import TypographyView from 'src/views/components/Typography/index';
import VectorMap from 'src/views/Maps/VectorMap/index';
import FormValidation from 'src/views/Forms/validation/index';
import Wizard from 'src/views/Forms/Wizard/index';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'account', element: <AccountView /> },
      { path: 'apexcharts', element: <ApexCharts /> },
      { path: 'basic-table', element: <BasicTable /> },
      { path: 'calender', element: <Calender /> },
      { path: 'chartjs2', element: <ChartJs /> },
      { path: 'chat', element: <ChatComponent /> },
      { path: 'customers', element: <CustomerListView /> },
      { path: 'customized-table', element: <CustomizedTable /> },
      { path: 'dashboard', element: <DashboardView /> },
      { path: 'data-table', element: <DataTable /> },
      { path: 'dense-table', element: <DenseTable /> },
      { path: 'products', element: <ProductListView /> },
      { path: 'remix-icons', element: <RemixIcons /> },
      { path: 'mail', element: <MailComponent /> },
      { path: 'ecommerce', element: <EcommerceView /> },
      { path: 'file-upload', element: <FileUploadForm /> },
      { path: 'full-screen-map', element: <FullScreenMap /> },
      { path: 'google-map', element: <GoogleMap /> },
      { path: 'credit-card', element: <CreditCardForm /> },
      { path: 'alerts', element: <AlertsView /> },
      { path: 'buttons', element: <ButtonView /> },
      { path: 'button-group', element: <ButtonGroupView /> },
      { path: 'cards', element: <Cards /> },
      { path: 'ck-editor', element: <CKEditor /> },
      { path: 'checkout', element: <Checkout /> },
      { path: 'date-time', element: <DateAndTimeView /> },
      { path: 'dialog', element: <DialogView /> },
      { path: 'dripicons', element: <DripIcons /> },
      { path: 'timeline', element: <TimeLineView /> },
      { path: 'wysiswyg-editor', element: <WYSISWYG /> },
      { path: 'grid', element: <GridComponent /> },
      { path: 'images', element: <ImagesComponent /> },
      { path: 'kanban-board', element: <KanbanBoard /> },
      { path: 'font-awesome-icons', element: <FontAwesomeIcons /> },
      { path: 'progress-bars', element: <ProgressBarsView /> },
      { path: 'pricing', element: <PricingView /> },
      { path: 'qr-generate', element: <QRGenerateComponent /> },
      { path: 'select', element: <SelectView /> },
      { path: 'settings', element: <SettingsView /> },
      { path: 'slider', element: <SliderComponent /> },
      { path: 'sparkline', element: <SparklineChart /> },
      { path: 'sorting-and-selecting-table', element: <SortingAndSelecting /> },
      { path: 'sweet-alert', element: <SweetAlertComponent /> },
      { path: 'text-field', element: <TextFieldView /> },
      { path: 'typography', element: <TypographyView /> },
      { path: 'validation', element: <FormValidation /> },
      { path: 'vector-map', element: <VectorMap /> },
      { path: 'logout', element: <Navigate to="/" /> },
      { path: 'wizard', element: <Wizard /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'coming-soon', element: <ComingSoonView /> },
      { path: 'login', element: <LoginView /> },
      { path: 'register', element: <RegisterView /> },
      { path: 'forgot-password', element: <ForgotPassword /> },
      { path: 'forgot-password-change', element: <ForgotPasswordChange /> },
      { path: 'otp-verify', element: <OtpVerifyView /> },
      { path: 'maintenance', element: <MaintenanceView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '500', element: <InternalServerError /> },
      { path: '/', element: <Navigate to="/login" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
