import { v4 as uuid } from 'uuid';

function randomDate(start, end) {
  return new Date(
    start.getTime() + Math.random() * (end.getTime() - start.getTime())
  );
}
export default [
  {
    id: uuid(),
    email: 'peter@email.com',
    who: 'Peter',
    to: 'me',
    total: 3,
    subject: 'to know about location',
    about:
      'since you asked... and i am inconceivably bored at the train station',
    date: randomDate(new Date(2021, 0, 1), new Date())
  },
  {
    id: uuid(),
    email: 'Susanna@email.com',
    who: 'me',
    to: 'Susanna',
    total: 7,
    subject: 'to know about location',
    about:
      'since you asked... and i am inconceivably bored at the train station',
    date: randomDate(new Date(2021, 0, 1), new Date())
  },
  {
    id: uuid(),
    email: 'text@email.com',
    who: 'Web Support Dennis',
    to: '',
    total: null,
    subject: 'to know about location',
    about: 'Re: New mail setting  - Will you answer him asap? ',
    date: randomDate(new Date(2021, 0, 1), new Date())
  },
  {
    id: uuid(),
    email: 'me@email.com',
    who: 'me',
    to: 'Peter',
    total: 2,
    subject: 'to know about location',
    about:
      'SupportOff on Thursday -Eff that place, you might as well stay here with us i',
    date: randomDate(new Date(2021, 0, 1), new Date())
  },
  {
    id: uuid(),
    email: 'medium@email.com',
    who: 'Medium',
    to: '',
    total: null,
    subject: 'to know about location',
    about:
      'SocialThis Weeks Top Stories –Our top pick for you on Medium this week The Man Who Destroyed America’s Ego',
    date: randomDate(new Date(2021, 0, 1), new Date())
  },
  {
    id: uuid(),
    email: 'deathtostock@email.com',
    who: 'Death to Stock',
    to: '',
    total: null,
    subject: 'to know about location',
    about:
      'Montly High-Res Photos –To create this months pack we hosted a party with',
    date: randomDate(new Date(2021, 0, 1), new Date())
  },
  {
    id: uuid(),
    email: 'randy@email.com',
    who: 'Randy',
    to: 'me',
    total: 5,
    subject: 'to know about location',
    about:
      'FamilyLast pic over my village –Yeah id like that! Do you remember the video',
    date: randomDate(new Date(2021, 0, 1), new Date())
  },
  {
    id: uuid(),
    email: 'andrew@email.com',
    who: 'Andrew Zimmer',
    to: '',
    total: null,
    subject: 'to know about location',
    about:
      'Mochila Beta: Subscription Confirmed – You have been confirmed! Welcome to t',
    date: randomDate(new Date(2021, 0, 1), new Date())
  },
  {
    id: uuid(),
    email: 'infinityhr@email.com',
    who: 'Infinity HR',
    to: '',
    total: null,
    subject: 'to know about location',
    about:
      'Sveriges Hetaste sommarjobb –Hej Nicklas Sandell! Vi vill bjuda in dig till "First tour 2014", ett rekryteringsevent som erbjuder jobb på 16 semesterorter iSverige.',
    date: randomDate(new Date(2021, 0, 1), new Date())
  },
  {
    id: uuid(),
    email: 'revibe@email.com',
    who: 'Revibe',
    to: '',
    total: null,
    subject: 'to know about location',
    about:
      'Weekend on Revibe –Todays Friday and we thought maybe you want some music inspiration for the weekend. Here are some trending tracks and playlists we think you should give a listen!',
    date: randomDate(new Date(2021, 0, 1), new Date())
  },
  {
    id: uuid(),
    email: 'erik@email.com',
    who: 'Erik',
    to: 'me',
    total: 2,
    subject: 'to know about location',
    about: 'Regarding our meeting –Thats great, see you on Thursday!',
    date: randomDate(new Date(2021, 0, 1), new Date())
  },
  {
    id: uuid(),
    email: 'kanbanflow@email.com',
    who: 'KanbanFlow',
    to: '',
    total: null,
    subject: 'to know about location',
    about:
      'Task assigned: Clone ARPs website – You have been assigned a task by Alex@Work on the board Web.',
    date: randomDate(new Date(2021, 0, 1), new Date())
  },
  {
    id: uuid(),
    email: 'tobiasberggren@email.com',
    who: 'Tobias Berggren',
    to: '',
    total: null,
    subject: 'to know about location',
    about:
      'Lets go fishing! –Hey, You wanna join me and Fred at the lake tomorrow? Itll be awesome.',
    date: randomDate(new Date(2021, 0, 1), new Date())
  },
  {
    id: uuid(),
    email: 'charukaw@email.com',
    who: 'Charukaw',
    to: 'me',
    total: 7,
    subject: 'to know about location',
    about: 'Hey man – Nah man sorry i dont. Should i get it?',
    date: randomDate(new Date(2021, 0, 1), new Date())
  },
  {
    id: uuid(),
    email: 'me@email.com',
    who: 'me',
    to: 'Peter',
    total: 2,
    subject: 'to know about location',
    about: 'Home again! – Thats just perfect! See you tomorrow.',
    date: randomDate(new Date(2021, 0, 1), new Date())
  }
];
