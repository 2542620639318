import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles
} from '@material-ui/core';
import { Polar } from 'react-chartjs-2';

const useStyles = makeStyles({
  root: {
    height: '100%'
  },
  image: {
    height: 48,
    width: 48
  }
});

const LatestProducts = ({ className, ...rest }) => {
  const classes = useStyles();
  const data = {
    datasets: [
      {
        data: [11, 16, 7, 3, 14],
        backgroundColor: [
          '#FF6384',
          '#4BC0C0',
          '#FFCE56',
          '#E7E9ED',
          '#36A2EB'
        ],
        label: 'My dataset' // for legend
      }
    ],
    labels: ['Red', 'Green', 'Yellow', 'Grey', 'Blue']
  };
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title="Orders" />
      <Divider />
      <CardContent>
        <Polar data={data} />
      </CardContent>
    </Card>
  );
};

LatestProducts.propTypes = {
  className: PropTypes.string
};

export default LatestProducts;
