import React from 'react';
import {
  Box,
  Container,
  Typography,
  makeStyles,
  Grid
} from '@material-ui/core';
import Page from 'src/components/Page';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560
  }
}));

const NotFoundView = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Maintenance">
      <Grid display="flex" height="100%">
        <Container maxWidth="md">
          <Typography align="center" color="textPrimary" variant="h1">
            Site is Under Maintenance
          </Typography>
          <Typography align="center" color="textPrimary" variant="subtitle2">
            Please check back in sometime.
          </Typography>
          <Box textAlign="center">
            <img
              alt="Under development"
              className={classes.image}
              src="/static/images/MAINTENANCE.png"
            />
          </Box>
        </Container>
      </Grid>
    </Page>
  );
};

export default NotFoundView;
