import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  makeStyles,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Dialog
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {}
}));

const AlertDialog = ({ className, ...rest }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader title={<Typography variant="h2">Alerts</Typography>} />
        <Divider />
        <CardContent>
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <div>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClickOpen}
              >
                Open alert dialog
              </Button>
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Use Google's location service?"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Let Google help apps determine location. This means sending
                    anonymous location data to Google, even when no apps are
                    running.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Disagree
                  </Button>
                  <Button onClick={handleClose} color="primary" autoFocus>
                    Agree
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </Box>

          <Box mt={1}>
            <Typography variant="subtitle1">
              Alerts are urgent interruptions, requiring acknowledgement, that
              inform the user about a situation.
            </Typography>
            <Typography variant="subtitle1">
              Most alerts don't need titles. They summarize a decision in a
              sentence or two by either:
            </Typography>
            <Typography variant="subtitle1">
              (1) Asking a question (e.g. "Delete this conversation?")
            </Typography>
            <Typography variant="subtitle1">
              (2) Making a statement related to the action buttons
            </Typography>
            <Typography variant="subtitle1">
              Use title bar alerts only for high-risk situations, such as the
              potential loss of connectivity. Users should be able to understand
              the choices based on the title and button text alone.
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

AlertDialog.propTypes = {
  className: PropTypes.string
};

export default AlertDialog;
