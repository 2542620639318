import React from 'react';
import ReactApexChart from 'react-apexcharts';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {}
}));

const BarChart = ({ className, ...rest }) => {
  const classes = useStyles();
  const [state] = React.useState({
    series: [
      {
        data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380]
      }
    ],
    options: {
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: [
          'South Korea',
          'Canada',
          'United Kingdom',
          'Netherlands',
          'Italy',
          'France',
          'Japan',
          'United States',
          'China',
          'Germany'
        ]
      }
    }
  });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader title="Bar Chart" />
        <Divider />
        <CardContent>
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <ReactApexChart
              options={state.options}
              series={state.series}
              type="bar"
              height={350}
              width={500}
            />
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

BarChart.propTypes = {
  className: PropTypes.string
};

export default BarChart;
