import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  button: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  code: {
    width: '100%',
    paddingTop: '15px',
    background: '#272C34',
    color: '#fff',
    marginTop: '10px',
    borderRadius: '4px',
    overflowX: 'scroll'
  }
}));

const ButtonGroupSizeAndColor = ({ className, ...rest }) => {
  const classes = useStyles();
  var code = `<ButtonGroup size="small" aria-label="small outlined button group">
  <Button>One</Button>
  <Button>Two</Button>
  <Button>Three</Button>
</ButtonGroup>
<ButtonGroup color="secondary" aria-label="outlined secondary button group">
  <Button>One</Button>
  <Button>Two</Button>
  <Button>Three</Button>
</ButtonGroup>
<ButtonGroup size="large" color="primary" aria-label="large outlined primary button group">
  <Button>One</Button>
  <Button>Two</Button>
  <Button>Three</Button>
</ButtonGroup>`;
  return (
    <form className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader
          title={<Typography variant="h2">Sizes and colors</Typography>}
        />
        <Divider />
        <CardContent>
          <Box mt={1}>
            <div className={classes.button} style={{ textAlign: 'center' }}>
              <ButtonGroup
                size="small"
                aria-label="small outlined button group"
              >
                <Button>One</Button>
                <Button>Two</Button>
                <Button>Three</Button>
              </ButtonGroup>
              <ButtonGroup
                color="secondary"
                aria-label="outlined secondary button group"
              >
                <Button>One</Button>
                <Button>Two</Button>
                <Button>Three</Button>
              </ButtonGroup>
              <ButtonGroup
                size="large"
                color="primary"
                aria-label="large outlined primary button group"
              >
                <Button>One</Button>
                <Button>Two</Button>
                <Button>Three</Button>
              </ButtonGroup>
            </div>
          </Box>
          <Box className={classes.code}>
            <Container>
              <pre>
                <Typography
                  variant="subtitle2"
                  style={{ fontFamily: 'monospace', fontSize: 14 }}
                >
                  {code}
                </Typography>
              </pre>
            </Container>
          </Box>
        </CardContent>
      </Card>
    </form>
  );
};

ButtonGroupSizeAndColor.propTypes = {
  className: PropTypes.string
};

export default ButtonGroupSizeAndColor;
