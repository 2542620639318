import React from 'react';
import { Container, makeStyles, Grid } from '@material-ui/core';
import Page from 'src/components/Page';
import WorldMaps from './worldMap';
import UsaMap from './usa';
import EuropeMap from './europe';
import Continents from './continents';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const VectorMapIndex = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Vector Map">
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item lg={6} sm={6} xl={6} xs={12}>
            <WorldMaps />
          </Grid>
          <Grid item lg={6} sm={6} xl={6} xs={12}>
            <UsaMap />
          </Grid>
          <Grid item lg={6} sm={6} xl={6} xs={12}>
            <EuropeMap />
          </Grid>
          <Grid item lg={6} sm={6} xl={6} xs={12}>
            <Continents />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default VectorMapIndex;
