import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Container,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';
import SnackbarContent from '@material-ui/core/SnackbarContent';

const useStyles = makeStyles(theme => ({
  root: {},
  messageLength: {
    maxWidth: 600,
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
  code: {
    width: '100%',
    paddingTop: '15px',
    background: '#272C34',
    color: '#fff',
    marginTop: '10px',
    borderRadius: '4px',
    overflowX: 'scroll'
  }
}));

const OutlinedButtons = ({ className, ...rest }) => {
  const classes = useStyles();

  const action = (
    <Button color="secondary" size="small">
      lorem ipsum dolorem
    </Button>
  );

  var code = `<SnackbarContent message="I love snacks." action={action} />
  <SnackbarContent
    message={
      'I love candy. I love cookies. I love cupcakes. \
      I love cheesecake. I love chocolate.'
    }
  />
  <SnackbarContent message="I love candy. I love cookies. I love cupcakes." action={action} />
  <SnackbarContent
    message={
      'I love candy. I love cookies. I love cupcakes. \
      I love cheesecake. I love chocolate.'
    }
    action={action}
  />`;
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader
          title={<Typography variant="h2">Message Length</Typography>}
        />
        <Divider />
        <CardContent>
          <Box
            mt={1}
            mb={3}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <div className={classes.messageLength}>
              <SnackbarContent message="I love snacks." action={action} />
              <SnackbarContent
                message={
                  'I love candy. I love cookies. I love cupcakes. \
          I love cheesecake. I love chocolate.'
                }
              />
              <SnackbarContent
                message="I love candy. I love cookies. I love cupcakes."
                action={action}
              />
              <SnackbarContent
                message={
                  'I love candy. I love cookies. I love cupcakes. \
          I love cheesecake. I love chocolate.'
                }
                action={action}
              />
            </div>
          </Box>
          <Box className={classes.code}>
            <Container>
              <pre>
                <Typography
                  variant="subtitle2"
                  style={{ fontFamily: 'monospace', fontSize: 14 }}
                >
                  {code}
                </Typography>
              </pre>
            </Container>
          </Box>
          <Box mt={1}>
            <Typography variant="subtitle1">
              Some snackbars with varying message length.
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

OutlinedButtons.propTypes = {
  className: PropTypes.string
};

export default OutlinedButtons;
