import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles
} from '@material-ui/core';
import { Sparklines, SparklinesLine } from 'react-sparklines';

const useStyles = makeStyles(theme => ({
  root: {}
}));

const BasicChart = ({ className, ...rest }) => {
  const classes = useStyles();
  function boxMullerRandom() {
    let phase = false,
      x1,
      x2,
      w;

    return (function() {
      if ((phase = !phase)) {
        do {
          x1 = 2.0 * Math.random() - 1.0;
          x2 = 2.0 * Math.random() - 1.0;
          w = x1 * x1 + x2 * x2;
        } while (w >= 1.0);

        w = Math.sqrt((-2.0 * Math.log(w)) / w);
        return x1 * w;
      } else {
        return x2 * w;
      }
    })();
  }
  function randomData(n = 30) {
    return Array.apply(0, Array(n)).map(boxMullerRandom);
  }
  const sampleData = randomData(30);
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader title="Basic Chart" />
        <Divider />
        <CardContent>
          <Box m={2} style={{ display: 'flex', justifyContent: 'center' }}>
            <Sparklines data={sampleData}>
              <SparklinesLine color="blue" />
            </Sparklines>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

BasicChart.propTypes = {
  className: PropTypes.string
};

export default BasicChart;
