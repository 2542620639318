import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Paper,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  makeStyles,
  Grid
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  cardbackground: {
    background: theme.palette.background.dark
  },
  grid: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }
}));

const GridAndBreakPoint = ({ className, ...rest }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card className={classes.cardbackground}>
        <CardHeader
          title={<Typography variant="h2">Grid with breakpoints</Typography>}
        />
        <Divider />
        <CardContent className={classes.cardbackground}>
          <Box mt={1} mb={3}>
            <div className={classes.grid}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Paper className={classes.paper}>xs=12</Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Paper className={classes.paper}>xs=12 sm=6</Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Paper className={classes.paper}>xs=12 sm=6</Paper>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Paper className={classes.paper}>xs=6 sm=3</Paper>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Paper className={classes.paper}>xs=6 sm=3</Paper>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Paper className={classes.paper}>xs=6 sm=3</Paper>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Paper className={classes.paper}>xs=6 sm=3</Paper>
                </Grid>
              </Grid>
            </div>
          </Box>

          <Box mt={1}>
            <Typography variant="subtitle1">
              Some columns have multiple widths defined, causing the layout to
              change at the defined breakpoint.
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

GridAndBreakPoint.propTypes = {
  className: PropTypes.string
};

export default GridAndBreakPoint;
