import React from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import LineWithDataLables from './linewithDataLabel';
import DashedLine from './dashed';
import SplineArea from './splineArea';
import ColumnChart from './column';
import ColumnWithDataLabel from './columnWithDataLabel';
import BarChart from './barChart';
import LineColumnAndArea from './lineColumnAndArea';
import RadialChart from './radial';
import PieChart from './piechart';
import DonutChart from './donut';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ApexChartIndex = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Apex Charts">
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item lg={6} sm={6} xl={6} xs={12}>
            <LineWithDataLables />
          </Grid>
          <Grid item lg={6} sm={6} xl={6} xs={12}>
            <DashedLine />
          </Grid>
          <Grid item lg={6} sm={6} xl={6} xs={12}>
            <SplineArea />
          </Grid>
          <Grid item lg={6} sm={6} xl={6} xs={12}>
            <ColumnChart />
          </Grid>
          <Grid item lg={6} sm={6} xl={6} xs={12}>
            <ColumnWithDataLabel />
          </Grid>
          <Grid item lg={6} sm={6} xl={6} xs={12}>
            <BarChart />
          </Grid>
          <Grid item lg={6} sm={6} xl={6} xs={12}>
            <LineColumnAndArea />
          </Grid>
          <Grid item lg={6} sm={6} xl={6} xs={12}>
            <RadialChart />
          </Grid>
          <Grid item lg={6} sm={6} xl={6} xs={12}>
            <PieChart />
          </Grid>
          <Grid item lg={6} sm={6} xl={6} xs={12}>
            <DonutChart />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default ApexChartIndex;
