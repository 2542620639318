import React from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import SimpleSelect from './simpleSelect';
import NativeSelect from './nativeSelect';
import MultipleSelect from './multiple';
import WithDialog from './withDialog';
import GroupSelect from './grouping';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const SelectIndex = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Select">
      <Container maxWidth="lg">
        <Box>
          <SimpleSelect />
        </Box>
        <Box mt={3}>
          <NativeSelect />
        </Box>
        <Box mt={3}>
          <MultipleSelect />
        </Box>
        <Box mt={3}>
          <WithDialog />
        </Box>
        <Box mt={3}>
          <GroupSelect />
        </Box>
      </Container>
    </Page>
  );
};

export default SelectIndex;
