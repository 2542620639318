export default [
  'ri-home-line',
  'ri-home-fill',
  'ri-home-2-line',
  'ri-home-2-fill',
  'ri-home-3-line',
  'ri-home-3-fill',
  'ri-home-4-line',
  'ri-home-4-fill',
  'ri-home-5-line',
  'ri-home-5-fill',
  'ri-home-6-line',
  'ri-home-6-fill',
  'ri-home-7-line',
  'ri-home-7-fill',
  'ri-home-8-line',
  'ri-home-8-fill',
  'ri-home-gear-line',
  'ri-home-gear-fill',
  'ri-home-wifi-line',
  'ri-home-wifi-fill',
  'ri-home-smile-line',
  'ri-home-smile-fill',
  'ri-home-smile-2-line',
  'ri-home-smile-2-fill',
  'ri-home-heart-line',
  'ri-home-heart-fill',
  'ri-building-line',
  'ri-building-fill',
  'ri-building-2-line',
  'ri-building-2-fill',
  'ri-building-3-line',
  'ri-building-3-fill',
  'ri-building-4-line',
  'ri-building-4-fill',
  'ri-hotel-line',
  'ri-hotel-fill',
  'ri-community-line',
  'ri-community-fill',
  'ri-government-line',
  'ri-government-fill',
  'ri-bank-line',
  'ri-bank-fill',
  'ri-store-line',
  'ri-store-fill',
  'ri-store-2-line',
  'ri-store-2-fill',
  'ri-store-3-line',
  'ri-store-3-fill',
  'ri-hospital-line',
  'ri-hospital-fill',
  'ri-ancient-gate-line',
  'ri-ancient-gate-fill',
  'ri-ancient-pavilion-line',
  'ri-ancient-pavilion-fill'
];
