import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Avatar,
  Container,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';
import { deepOrange, green } from '@material-ui/core/colors';
import AssignmentIcon from '@material-ui/icons/Assignment';

const useStyles = makeStyles(theme => ({
  root: {},
  image: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  square: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500]
  },
  rounded: {
    color: '#fff',
    backgroundColor: green[500]
  },
  code: {
    width: '100%',
    paddingTop: '15px',
    background: '#272C34',
    color: '#fff',
    marginTop: '10px',
    borderRadius: '4px',
    overflowX: 'scroll'
  }
}));

const VariantsAvatar = ({ className, ...rest }) => {
  const classes = useStyles();

  var code = `<Avatar variant="square" className={classes.square}>
  N
</Avatar>
<Avatar variant="rounded" className={classes.rounded}>
  <AssignmentIcon />
</Avatar>`;
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader title={<Typography variant="h2">Variants</Typography>} />
        <Divider />
        <CardContent>
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <div className={classes.image}>
              <Avatar variant="square" className={classes.square}>
                N
              </Avatar>
              <Avatar variant="rounded" className={classes.rounded}>
                <AssignmentIcon />
              </Avatar>
            </div>
          </Box>
          <Box className={classes.code}>
            <Container>
              <pre>
                <Typography
                  variant="subtitle2"
                  style={{ fontFamily: 'monospace', fontSize: 14 }}
                >
                  {code}
                </Typography>
              </pre>
            </Container>
          </Box>
          <Box mt={1}>
            <Typography variant="subtitle1">
              If you need square or rounded avatars, use the <b>variant</b>{' '}
              prop.
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

VariantsAvatar.propTypes = {
  className: PropTypes.string
};

export default VariantsAvatar;
